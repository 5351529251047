//
import { defineStore } from 'pinia'

import server from '@/api/server'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: {},
  }),
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    async login(params) {
			try {
				const response = await server({
					url: `/api/auth/login`,
					method: 'POST',
					data: params,
				})
				.then((response) => response.data.result);

				this.user = response.user;
			} catch(e) {
				throw e;
			}
		},
		async join_sns(url, params) {
			try {
				const response = await server({
					url,
					method: 'POST',
					data: params
				})
				.then(res => res.data.result)

				return response;
			} catch(e) {
				throw e;
			}
		},
		async login_sns(url, params) {
			try {
				const response = await server({
					url,
					method: 'POST',
					data: params
				})
				.then((response) => response.data.result);

				this.setUser(response.user);
			} catch(e) {
				throw e;
			}
		},
		async logout() {
			try {
				const response = await server({
					url: `/api/auth/logout`,
					method: 'POST'
				})
				.then(res => res.data.result);

				console.log('로그아웃 테스트');
			} catch(e) {
				throw e;
			} finally {
				this.user = Object.assign({});
			}
		},
		setUser(user = {}) {
			this.user = Object.assign({}, { ...user });
		}
  },
	persist: {
		storage: localStorage,
		paths: [
			'user',
		]
	}
})