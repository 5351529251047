<template>
	<header class="sub">
		<include-menu />
	</header>
</template>

<script>
import menu from './menu.vue'

export default {
	name: 'App',
	components: {
		'include-menu': menu
	}
}
</script>