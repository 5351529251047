<script setup>
import { computed } from 'vue';
import { useRoute, useRouter } from "vue-router"

import { useAuthStore } from '@/store/auth.pinia';
import { useCommonStore } from '@/store/common.pinia';

import TranslateText from '@/components/common/translate_text.vue';

const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();
const commonStore = useCommonStore();

const is_login = computed(() => !!authStore.user?.name);

const onLocation = (path, check = false) => {
	/**
	 * check = 로그인 검사 여부
	 */
	if(check) {
		if(!is_login.value) return;
	}
	console.log('gh');
	router.push({ path, });
}

</script>
<template>
	<div class="sub_menu_wrap">
		<ul>
			<li
				:class="{
					'on': route.path.indexOf('/board/notice') != -1
				}"
				style="max-width: 33.3%"
			>
				<button
					@click="onLocation('/board/notice')"
				>
					<TranslateText
						source="ko"
						contents="공지사항"
					/>
				</button>
			</li>
			<li
				:class="{
					'on': route.path.indexOf('/board/faq') != -1
				}"
				style="max-width: 33.3%"
			>
				<button
					
					@click="onLocation('/board/faq')"
				>
					<TranslateText
						source="ko"
						contents="자주 묻는 질문"
					/>
				</button>
			</li>
			<li
				:class="{
					'on': route.path.indexOf('/board/qna') != -1,
					'text-secondary': !is_login
				}"
				style="max-width: 33.3%"
			>
				<button
					@click="onLocation('/board/qna', true)"
					:disabled="!is_login"
				>
					<TranslateText
						source="ko"
						contents="1:1 문의"
					/>
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'App',
	components: {
	}
}
</script>