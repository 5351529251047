<template>
  <router-view />
</template>

<script setup>
  import { onMounted, onBeforeMount, onActivated } from 'vue';

  import { useCommonStore } from '@/store/common.pinia';

  const commonStore = useCommonStore();

  (async () => {
    await commonStore.i18nInit();
  })()
  
</script>