<script setup>
import { onMounted, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"

import { useI18n } from 'vue-i18n';

import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, minValue, maxValue, email, sameAs } from "@/components/utils/ko-vuelidate";

import { useCommonStore } from '@/store/common.pinia';

import TranslateText from '@/components/common/translate_text.vue';

import server from '@/api/server';

const route = useRoute()
const router = useRouter();

const { t } = useI18n();

const commonStore = useCommonStore();

const formModel = ref({
	name:"",
	email: "",
});

const rules = {
	name: { required },
	email: { required, email: email },
};
const v$ = useVuelidate(rules, formModel.value)

const formModel2 = ref({
	user_id:"",
	email: "",
});

const rules2 = {
	user_id: { required },
	email: { required, email: email },
};

const v2$ = useVuelidate(rules2, formModel2.value)

const find_id = async () => {
	const result = await v$.value.$validate();
	if( !result ) {
		toast.fire({
			icon: "error",
			title: "입력 양식에 맞지 않습니다.",
		});
		return;
	}

	const params = new URLSearchParams();
	params.append('email', formModel.value.email);

	let response = await server.get(`/api/auth/find/id`, {params})

	console.log(response.data.result);
	if( response.data.result.user_id ) {
		formModel.value.user_id = response.data.result.user_id;
		Swal.fire({
			title: '아이디 찾기',
			text: `문의 하신 아이디는 ${response.data.result.user_id} 입니다.`,
			icon: 'success',

			confirmButtonText: '확인', // confirm 버튼 텍스트 지정
			confirmButtonColor: '#3085d6', // confrim 버튼 색깔 지정

			reverseButtons: false, // 버튼 순서 거꾸로
		});
	} else if ( response.data.result.sns ) {
		formModel.value.user_id = response.data.result.user_id;
		Swal.fire({
			title: '아이디 찾기',
			text: `SNS회원 아이디가 있습니다. SNS로 로그인 해주세요.`,
			icon: 'success',

			confirmButtonText: '확인', // confirm 버튼 텍스트 지정
			confirmButtonColor: '#3085d6', // confrim 버튼 색깔 지정

			reverseButtons: false, // 버튼 순서 거꾸로
		});
	} else {
		Swal.fire({
			title: '아이디 찾기',
			text: "찾으시는 정보가 없습니다.",
			icon: 'error',

			confirmButtonText: '확인', // confirm 버튼 텍스트 지정
			confirmButtonColor: '#3085d6', // confrim 버튼 색깔 지정

			reverseButtons: false, // 버튼 순서 거꾸로
		});
	}
}

const find_pw = async () => {
	const result = await v2$.value.$validate();
	if( !result ) {
		toast.fire({
			icon: "error",
			title: "입력 양식에 맞지 않습니다.",
		});
		return;
	}

	const params = new URLSearchParams();
	params.append('email', formModel2.value.email);

	let response = await server.post(`/api/auth/issue/temp/password`, params)
	console.log(response.data.result);
	if( response.data.result.message == 'OK' ) {
		Swal.fire({
			title: '비밀번호 찾기',
			text: '이메일로 임시비밀번호를 전송했습니다.',
			icon: 'success',

			confirmButtonText: '확인', // confirm 버튼 텍스트 지정
			confirmButtonColor: '#3085d6', // confrim 버튼 색깔 지정

			reverseButtons: false, // 버튼 순서 거꾸로
		});
	} else {
		Swal.fire({
			title: '비밀번호 찾기',
			text: "찾으시는 정보가 없습니다.",
			icon: 'error',

			confirmButtonText: '확인', // confirm 버튼 텍스트 지정
			confirmButtonColor: '#3085d6', // confrim 버튼 색깔 지정

			reverseButtons: false, // 버튼 순서 거꾸로
		});
	}
}

onMounted(async() => {
	lost_tab()
})

function siblings(t) {
	const children = t.parentElement.children;
	const tempArr = [];

	for (let i = 0; i < children.length; i++) {
		tempArr.push(children[i]);
	}
	
	return tempArr.filter(function(e){
		return e != t;
	});
}

const lost_tab = () => {
	let cc = document.querySelectorAll('.tap li');
	if(cc.length > 0) {
		cc.forEach(function (item) {
			item.querySelector('a').addEventListener('click', function(e) {
				e.preventDefault();

				siblings(item).forEach(function(item_li) {
					item_li.querySelector('a').classList.remove('on');
				});

				if(document.querySelectorAll('.find_con').length > 0) {
					document.querySelectorAll('.find_con').forEach(function(item_desc) {
						item_desc.classList.remove('on');
					});
				}
				this.classList.add('on');
				let link = this.getAttribute('href');

				if(document.querySelector(link.substring(link.indexOf('#'))) !== null) {
					document.querySelector(link.substring(link.indexOf('#'))).classList.add('on');
				}
			})
		});
	}
}
</script>

<template>
	<include-header/>

	<div id="member_wrap">
		<div class="join_container">
			<div class="join_wrap find_wrap bg_w mb50">
				<div class="sub_tit">
					<TranslateText
						source="ko"
						contents="아이디/패스워드 찾기"
					/>
				</div>
				<ul class="tap">
					<li>
						<router-link to="#find_id" class="on">
							<TranslateText
								source="ko"
								contents="아이디찾기"
							/>
						</router-link>
					</li>
					<li>
						<router-link to="#find_pw">
							<TranslateText
								source="ko"
								contents="비밀번호찾기"
							/>
						</router-link>
					</li>
				</ul>

				<div id="find_id" class="find_con on">
					<ul>
						<li class="fd_tit">
							<span class="material-symbols-outlined txt_v"></span>
							<i class="fa fa-user-circle fa-2x red mb-3" aria-hidden="true"></i>
							<h5>
								<TranslateText
									source="ko"
									contents="소셜로그인이 아닌"
								/>
								<span class="red va_t">
									<TranslateText
										source="ko"
										contents="일반회원"
									/>
								</span>
								<TranslateText
									source="ko"
									contents="정보 조회만 가능합니다."
								/>
							</h5>
							<p>
								<TranslateText
									source="ko"
									contents="회원가입시 입력하신 이름과 이메일을 입력해주세요."
								/>
							</p>
						</li>
					</ul>
					<ul class="join_info">
						<li>
							<h3>
								<TranslateText
									source="ko"
									contents="이름"
								/>
							</h3>
							<div class="con">
								<input
									type="text"
									:placeholder="t('placeholder:name')"
									v-model="formModel.name" required
									:class="!v$.name.$invalid&&!v$.name.$error ? 'is-valid' : v$.name.$invalid&&v$.name.$error ? 'is-invalid' : ''"
								>
							</div>
							<div class="text-center">
								<span 
									v-for="error in v$.name.$errors"
									:key="error.$uid"
									class="text-danger"
								>
									{{ error.$message }}
								</span>
							</div>
						</li>
						<li>
							<h3>
								<TranslateText
									source="ko"
									contents="이메일"
								/>
							</h3>
							<div class="con">
								<input
									type="email"
									:placeholder="t('placeholder:email')"
									v-model="formModel.email"
									required
									:class="!v$.email.$invalid&&!v$.email.$error ? 'is-valid' : v$.email.$invalid&&v$.email.$error ? 'is-invalid' : ''"
								>
							</div>
							<div class="text-center">
								<span 
									v-for="error in v$.email.$errors"
									:key="error.$uid"
									class="text-danger"
								>
									{{ error.$message }}
								</span>
							</div>
						</li>
					</ul>

					<ul class="join_info">
						<li>
							<button class="btn_v" @click="find_id()">
								<TranslateText
									source="ko"
									contents="확인"
								/>
							</button>
						</li>
					</ul>
				</div>

				<div id="find_pw" class="find_con">
					<ul>
						<li class="fd_tit">
							<span class="material-symbols-outlined txt_v"></span>
							<i class="fa fa-user-circle fa-2x red mb-3" aria-hidden="true"></i>
							<h5>
								<TranslateText
									source="ko"
									contents="소셜로그인이 아닌"
								/>
								<span class="red va_t">
									<TranslateText
										source="ko"
										contents="일반회원"
									/>
								</span>
								<TranslateText
									source="ko"
									contents="정보 조회만 가능합니다."
								/>
							</h5>
							<p>
								<TranslateText
									source="ko"
									contents="회원가입시 입력하신 이름과 이메일을 입력해주세요."
								/>
							</p>
						</li>
					</ul>
					<ul class="join_info">
						<li>
							<h3>
								<TranslateText
									source="ko"
									contents="아이디"
								/>
							</h3>
							<div class="con">
								<input
									type="text"
									:placeholder="t('placeholder:name')"
									v-model="formModel2.user_id"
									required
									:class="!v2$.user_id.$invalid&&!v2$.user_id.$error ? 'is-valid' : v2$.user_id.$invalid&&v2$.user_id.$error ? 'is-invalid' : ''"
								>
							</div>
							<div class="text-center">
								<span 
									v-for="error in v2$.user_id.$errors"
									:key="error.$uid"
									class="text-danger"
								>
									{{ error.$message }}
								</span>
							</div>
						</li>
						<li>
							<h3>
								<TranslateText
									source="ko"
									contents="이메일"
								/>
							</h3>
							<div class="con">
								<input
									type="email"
									:placeholder="t('placeholder:email')"
									v-model="formModel2.email"
									required
									:class="!v2$.email.$invalid&&!v2$.email.$error ? 'is-valid' : v2$.email.$invalid&&v2$.email.$error ? 'is-invalid' : ''"
								>
							</div>
							<div class="text-center">
								<span 
									v-for="error in v2$.email.$errors"
									:key="error.$uid"
									class="text-danger"
								>
									{{ error.$message }}
								</span>
							</div>
						</li>
					</ul>

					<ul class="join_info">
						<li>
							<button class="btn_v" @click="find_pw()">
								<TranslateText
									source="ko"
									contents="확인"
								/>
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<include-footer/>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer
	}
}
</script>