<script setup>
	import { useRouter } from 'vue-router';

	import TranslateText from '@/components/common/translate_text.vue';

	const router = useRouter();
</script>

<template>


<div class="intro_warp">

	<div class="intro_head">
		<div class="red_lable">
			<TranslateText
				source="ko"
				contents="STARTUP CLASS"
			/>
		</div>
		<h2>
			<TranslateText
			source="ko"
			contents="스타트업 비지니스의 시작
			그리고 성공으로향하는 경험  
			"
			/>
			<strong>
				<TranslateText
				source="ko"
				contents="스타트업 클래스와 함께 만들어가요! "
				/>
			</strong>
		</h2>
	</div>

	<div class="sub_txt">
		<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 256 256"><path fill="#e11837" d="M225.86 102.82c-3.77-3.94-7.67-8-9.14-11.57c-1.36-3.27-1.44-8.69-1.52-13.94c-.15-9.76-.31-20.82-8-28.51s-18.75-7.85-28.51-8c-5.25-.08-10.67-.16-13.94-1.52c-3.56-1.47-7.63-5.37-11.57-9.14C146.28 23.51 138.44 16 128 16s-18.27 7.51-25.18 14.14c-3.94 3.77-8 7.67-11.57 9.14c-3.25 1.36-8.69 1.44-13.94 1.52c-9.76.15-20.82.31-28.51 8s-7.8 18.75-8 28.51c-.08 5.25-.16 10.67-1.52 13.94c-1.47 3.56-5.37 7.63-9.14 11.57C23.51 109.72 16 117.56 16 128s7.51 18.27 14.14 25.18c3.77 3.94 7.67 8 9.14 11.57c1.36 3.27 1.44 8.69 1.52 13.94c.15 9.76.31 20.82 8 28.51s18.75 7.85 28.51 8c5.25.08 10.67.16 13.94 1.52c3.56 1.47 7.63 5.37 11.57 9.14c6.9 6.63 14.74 14.14 25.18 14.14s18.27-7.51 25.18-14.14c3.94-3.77 8-7.67 11.57-9.14c3.27-1.36 8.69-1.44 13.94-1.52c9.76-.15 20.82-.31 28.51-8s7.85-18.75 8-28.51c.08-5.25.16-10.67 1.52-13.94c1.47-3.56 5.37-7.63 9.14-11.57c6.63-6.9 14.14-14.74 14.14-25.18s-7.51-18.27-14.14-25.18Zm-52.2 6.84l-56 56a8 8 0 0 1-11.32 0l-24-24a8 8 0 0 1 11.32-11.32L112 148.69l50.34-50.35a8 8 0 0 1 11.32 11.32Z"/></svg>
		<h3>
			<TranslateText
				source="ko"
				contents="스타트업 클래스란?"
			/>
		</h3>
		<p>
			<TranslateText
				source="ko"
				contents="'스타트업클래스(Start up Class) '는 스타트업 창업자에게꼭 필요한 온라인 강의 등을 한곳에서 제공하여  언제, 어디서나 원하는 학습 기회를 누릴 수 있도록 지원하는[스타트업 온라인강좌  사이트]입니다.
			"
			/>
		</p>
	</div>

	<div class="sub_info">
		<h3 class="sub_tit">
			<span class="red_bar"></span>
			<TranslateText
			source="ko"
			contents="스타트업  클래스의 특징"
			/>
		</h3>
		<ul class="info_list">
			<li>
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#222" fill-rule="evenodd" d="M18.03 7.97a.75.75 0 0 1 0 1.06l-7 7a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 1 1 1.06-1.06l3.47 3.47l6.47-6.47a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd"/></svg>
				<TranslateText
				source="ko"
				contents="언제, 어디서나, 누구든지 최고의 강좌를 선택하여 무료로 수강 가능"
				/>
			</li>
			<li>
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#222" fill-rule="evenodd" d="M18.03 7.97a.75.75 0 0 1 0 1.06l-7 7a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 1 1 1.06-1.06l3.47 3.47l6.47-6.47a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd"/></svg>
				<TranslateText
				source="ko"
				contents="4대 핵심 카테고리에 따라  다양한 분야의 강좌 열람가능."
				/>
			</li>
			<li>
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#222" fill-rule="evenodd" d="M18.03 7.97a.75.75 0 0 1 0 1.06l-7 7a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 1 1 1.06-1.06l3.47 3.47l6.47-6.47a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd"/></svg>
				<TranslateText
				source="ko"
				contents="창업의 단계별로 이론 및 실무 중심의 창업 강좌 서비스를 제공 "
				/>
			</li>
		</ul>
	</div>


	<div class="sub_point">
		<ul>
			<li>
				<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" aria-label="체크" viewBox="0 0 24 24"><path fill="#e11837" fill-rule="evenodd" d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18Zm-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774l.701-.84Z" clip-rule="evenodd"/></svg>
				<b>Global</b>
				<TranslateText
				source="ko"
				contents="한국어/ 영어 <br>언어지원"
				/>
			</li>
			<li>
				<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" aria-label="체크" viewBox="0 0 24 24"><path fill="#e11837" fill-rule="evenodd" d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18Zm-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774l.701-.84Z" clip-rule="evenodd"/></svg>
				<b>Online</b>
				<TranslateText
				source="ko"
				contents="웹앱기반 <br>온라인강좌"
				/>
			</li>
			<li>
				<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" aria-label="체크" viewBox="0 0 24 24"><path fill="#e11837" fill-rule="evenodd" d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18Zm-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774l.701-.84Z" clip-rule="evenodd"/></svg>
				<b>A to Z</b>
				<TranslateText
				source="ko"
				contents="기초부터 <br>성공까지 "
				/>
			</li>
			<li>
				<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" aria-label="체크" viewBox="0 0 24 24"><path fill="#e11837" fill-rule="evenodd" d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18Zm-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774l.701-.84Z" clip-rule="evenodd"/></svg>
				<b>Essential</b>
				<TranslateText
				source="ko"
				contents="스타트업에 꼭 필요한  <br>정보만 모아! "
				/>
			</li>
		</ul>
	</div>

	<div class="sub_main">
		<h3 class="title">
			<span class="red_bar" alt=""></span>
			<TranslateText
			source="ko"
			contents="스타트업  클래스의 소개"
			/>
		</h3>

		<ul>
			<li>
				<svg xmlns="http://www.w3.org/2000/svg" aria-label="웹앱" width="40" height="40" viewBox="0 0 24 24"><path fill="#e11837" d="M20 2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6v2H7v2h10v-2h-3v-2h6a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm0 14H4V4h16v12z"/></svg>
				<b>
					<TranslateText
					source="ko"
					contents="웹앱 모바일러닝 "
					/>
				</b>
				<p>
					<TranslateText
				source="ko"
				contents="PC /모바일 언제 어디서나 <br>원하는수업 수강가능! "
					/>
				</p>
			</li>	
			<li>
				<svg xmlns="http://www.w3.org/2000/svg"  aria-label="책갈피" width="40" height="40" viewBox="0 0 48 48"><g fill="none" stroke="#e11837" stroke-linejoin="round" stroke-width="4"><path d="M10 44a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v36a2 2 0 0 1-2 2H10Z"/><path stroke-linecap="round" d="M21 22V4h12v18l-6-6.273L21 22Z" clip-rule="evenodd"/><path stroke-linecap="round" d="M10 4h28"/></g></svg>
				<b>
					<TranslateText
					source="ko"
					contents="책갈피  "
					/> 
				</b>
				<p>
					<TranslateText
					source="ko"
					contents="듣고있던 부분에서 나가도 <br>이어서 듣기가능!"
					/>
				</p>
			</li>	
			<li>
				<svg xmlns="http://www.w3.org/2000/svg"  aria-label="한영지원" width="40" height="40" viewBox="0 0 16 16"><g fill="#e11837"><path d="M4.545 6.714L4.11 8H3l1.862-5h1.284L8 8H6.833l-.435-1.286H4.545zm1.634-.736L5.5 3.956h-.049l-.679 2.022H6.18z"/><path d="M0 2a2 2 0 0 1 2-2h7a2 2 0 0 1 2 2v3h3a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-3H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2zm7.138 9.995c.193.301.402.583.63.846c-.748.575-1.673 1.001-2.768 1.292c.178.217.451.635.555.867c1.125-.359 2.08-.844 2.886-1.494c.777.665 1.739 1.165 2.93 1.472c.133-.254.414-.673.629-.89c-1.125-.253-2.057-.694-2.82-1.284c.681-.747 1.222-1.651 1.621-2.757H14V8h-3v1.047h.765c-.318.844-.74 1.546-1.272 2.13a6.066 6.066 0 0 1-.415-.492a1.988 1.988 0 0 1-.94.31z"/></g></svg>
				<b>
					<TranslateText
					source="ko"
					contents="한국어/영어 지원"
					/> 
				</b>
				<p>
					<TranslateText
					source="ko"
					contents="사이트 자동번역으로 <br>글로벌한 수강체험!"
					/>  
				</p>
			</li>	
		</ul>

		<p class="mt-4">
			<TranslateText
			source="ko"
			contents="스타트업 클래스는 언제, 어디서나, 누구든지 최고의 강좌를 선택하여  온라인과 모바일 상관없이 수강 가능하며,  창업에 꼭 필요한 실무지식들을  4대 핵심 카테고리에 따라 창업의 단계별로 이론 및 실무 중심의 창업 강좌 서비스를 제공하여 수강생의 성공적인 창업을 지원합니다. "
			/>
		</p>
	</div>

	<div class="btn_warp">
		<p>
			<TranslateText
			source="ko"
			contents=
			"성공적인 스타트업 창업을 위한 방식은 저마다 다르기 마련이죠. <br>스타트업클래스는 다양한 강좌를 바탕으로 
			스타트업 창업의 성공을 위한 험난한 여정에 파트너가 되어드립니다.<br>
			나만의 비즈니스를 통해 더 많은 사람과 연결되는 경험,  스타트업 클래스와 함께 시작해볼까요?"
			/>
		</p>

		<button @click="router.push({ path: '/mypage/room' })">
			<TranslateText
			source="ko"
			contents="전체강좌 보러가기 "
			/>
			<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-6 6l6-6m-6-6l6 6"/></svg>
		</button>	
	</div>

</div>


</template>