<script setup>
import { onMounted, ref } from "vue"
import { useRouter } from "vue-router"

import { useI18n } from 'vue-i18n';

import useVuelidate from "@vuelidate/core";

import { useAuthStore } from '@/store/auth.pinia';
import { useCommonStore } from '@/store/common.pinia';

import TranslateText from '@/components/common/translate_text.vue';

import { env as SERVER_URL } from "@/components/utils/env";
import { sns } from "@/components/utils/sns";

import { required, minLength, maxLength, minValue, maxValue, email, sameAs } from "@/components/utils/ko-vuelidate";

const authStore = useAuthStore();
const commonStore = useCommonStore();

const { t } = useI18n();

const formModel = ref({
	user_id: "",
	user_pw: "",
});

const rules = {
	user_id: { required },
	user_pw: { required },
};
let v$ = useVuelidate(rules, formModel.value)

// 네이버 로그인 설정
const naverClientId = sns.naver_auth;
const callbackUrl = sns.callback_url;
const states = sns.naver_states;
const googleClientId = sns.google_auth;
//8bGxdcq6am

// 카카오 로그인 설정
if (!Kakao.isInitialized()) {
	Kakao.init(sns.kakao_auth); // 사용하려는 앱의 JavaScript 키 입력
};

// 네이버 로그인을 위한 url 이동 
const naver_login = () => {
	alert(t('warning:naver'));

	return;

	const url = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${naverClientId}&redirect_uri=${callbackUrl}&state=${states}`;
	window.location.href=url;
};

// 카카오 로그인을 위한 url 이동 
const kakao_login = () => {
	alert(t('warning:kakao'));

	Kakao.Auth.authorize({
		redirectUri: sns.callback_url,
	});
};

// 구글 로그인을 위한 url 이동 
const google_login = () => {
	const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${googleClientId}&redirect_uri=${callbackUrl}&response_type=code&scope=email profile`;
	window.location.href=url;
};

const router = useRouter();

const goto_home  = () => {
	router.push('/');
}

const sample_id = 'wgpn156';
const sample_pwd = '12341234';

const login_submit = async () => {
	try {
		const result = await v$.value.$validate();

		if( !result ) {
			toast.fire({
				icon: "error",
				title: "입력 양식에 맞지 않습니다.",
			});
			return;
		}

		const params = new URLSearchParams();
		params.append("user_id", formModel.value.user_id);
		params.append("user_pw", formModel.value.user_pw);

		params.append('type', 1);
		
		await authStore.login(params);

		toast.fire({
			icon: "success",
			title: "로그인 성공!",
		});

		router.push({ path: '/' });
	} catch(e) {
		console.log(e);
		toast.fire({
			icon: "error",
			title: e.response.data.result?.message,
		});
	}
};

</script>

<template>
	<include-header/>
	<!-- <div class="sub_bg">
		<span>커뮤니티</span>
	</div> -->

	<div id="member_wrap">
		<div class="join_container">
			<div class="join_wrap bg_w">
				<div class="sub_tit">
					<TranslateText
						source="ko"
						contents="로그인"
					/>
				</div>
				<ul class="login_form">
					<li class="inp">
						<p>
							<TranslateText
								source="ko"
								contents="아이디"
							/>
						</p>
						<input
							type="text"
							v-model="formModel.user_id"
							:placeholder="t('placeholder:login_id')"
							required
							:class="!v$.user_id.$invalid&&!v$.user_id.$error ? 'is-valid' : v$.user_id.$invalid&&v$.user_id.$error ? 'is-invalid' : ''"
						>
						<span 
							v-for="error in v$.user_id.$errors"
							:key="error.$uid"
							class="text-danger"
						>
							{{ error.$message }}
						</span>
					</li>
					<li class="inp">
						<p>
							<TranslateText
								source="ko"
								contents="비밀번호"
							/>
						</p>
						<input
							type="password"
							v-model="formModel.user_pw"
							:placeholder="t('placeholder:login_password')"
							required
							:class="!v$.user_pw.$invalid&&!v$.user_pw.$error ? 'is-valid' : v$.user_pw.$invalid&&v$.user_pw.$error ? 'is-invalid' : ''"
						>
						<span 
							v-for="error in v$.user_pw.$errors"
							:key="error.$uid"
							class="text-danger"
						>
							{{ error.$message }}
						</span>
					</li>
					<li class="find ta_r">
						<router-link to="/member/lost">
							<TranslateText
								source="ko"
								contents="아이디/비밀번호 찾기"
							/>
						</router-link>
					</li>
					<li>
						<button
							@click="login_submit();"
							class="bg_red btn w100p white"
						>
							<TranslateText
								source="ko"
								contents="로그인"
							/>
						</button>
					</li>
				</ul>
				<div class="sns_login_wrap">
					<p class="hr_text">
						<span>
							<TranslateText
								source="ko"
								contents="또는 간편 로그인"
							/>
						</span>
					</p>
					<ul>
						<li><router-link to="#" @click="kakao_login()"><img src="@/assets/img/common/icon_kakao.png" alt="카카오"></router-link></li>
						<li><router-link to="#" @click="naver_login()"><img src="@/assets/img/common/icon_naver.png" alt="네이버"></router-link></li>
						<li><router-link to="#" @click="google_login()"><img src="@/assets/img/common/icon_google.png" alt="구글"></router-link></li>
					</ul>
					<p class="join_text">
						<TranslateText
							source="ko"
							contents="아직 회원가입 전이신가요?"
						/>
						<router-link to="/member/agree" class="underline fw-bold">
							<TranslateText
								source="ko"
								contents="회원가입"
							/>
						</router-link>
					</p>
				</div>
			</div>
		</div>
	</div><!-- //#member_wrap -->

	<include-footer/>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer
	}
}
</script>