<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { env as SERVER_URL } from '@/components/utils/env';

import TranslateText from '@/components/common/translate_text.vue';

import server from '@/api/server';

const route = useRoute()
const router = useRouter();

const props = defineProps({
	reference: {
		type: [String, Number],
		default: 0
	}
})

const article = ref([])	

const getSingleArticle = async () => {
	try {
		const response = await server({
			url: `/api/public/notice/${props.reference}`,
			method: 'GET'
		})
		.then(res => res.data.result)

		article.value = response
	} catch(e) {

	}
}

onMounted(async() => {
	getSingleArticle()
})
</script>

<template>
	<div class="board_view_wrap">

		<div class="article_view_body notice">
			<div class="article_head">
				<div class="subject">
					<TranslateText
						source="ko"
						:contents="article.subject"
					/>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" class=" ms-1"><path fill="#e11837 " d="M9 7v10h2v-5l2 5h2V7h-2v5l-2-5H9M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2Z"/></svg>
				</div>
				<div class="info">
					<div class="date">
						<strong>
							<TranslateText
								source="ko"
								contents="등록일"
							/>
						</strong>
						<span>{{ article.create_date?.substring(0,10) }}</span>
					</div>
					<!-- <div class="count">
						조회 5
					</div> -->
				</div>
			</div>
			<div class="article_file">
				<div v-if="article.File1">
					<a :href="`${SERVER_URL}${article.File1?.path}`">
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#666" d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m4 18H6V4h7v5h5v11Z"/></svg>
						{{ article.File1?.origin_name }}
					</a>
				</div>
				<div v-if="article.File2">
					<a :href="`${SERVER_URL}${article.File2?.path}`">
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#666" d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m4 18H6V4h7v5h5v11Z"/></svg>
						{{ article.File2?.origin_name }}
					</a>
				</div>
				<div v-if="article.File3">
					<a :href="`${SERVER_URL}${article.File3?.path}`">
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#666" d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m4 18H6V4h7v5h5v11Z"/></svg>
						{{ article.File3?.origin_name }}
					</a>
				</div>
			</div>
			<div class="article_body">
				<pre>
					<TranslateText
						source="ko"
						:contents="article.content"
					/>
				</pre>
			</div>

		</div>

		<div class="bottom_btns">
			<router-link :to="`/intro/reference`">
				<TranslateText
					source="ko"
					contents="목록으로"
				/>
			</router-link>
		</div>
	</div>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'
import board from '../layout/board.vue'

export default {
	name: 'App',
	components: {
	'include-header': header,
	'include-footer': footer,
	'include-board-header': board,
	}
}
</script>