<script setup>
import { onMounted, watch, ref, computed } from "vue";
import { useRouter } from 'vue-router';

import { useAuthStore } from '@/store/auth.pinia';
import { useCommonStore } from '@/store/common.pinia';

import { env as SERVER_URL } from '@/components/utils/env';

import TranslateText from '@/components/common/translate_text.vue';

import CartBox from '@/partials/cart_box.vue';

import server from '@/api/server';

const props = defineProps({
	category:{
		type:String,
		default:''
	}
})

const router = useRouter();

const authStore = useAuthStore();
const commonStore = useCommonStore();

const is_login = computed(() => !!authStore.user?.name);

const searchModel = ref({
	order: 'newer',
})

const articles = ref({});
const checked = ref([]);
const term = ref(false);

const isChecked = computed(() => articles.value.every(v => checked.value.includes(v.Course.id)));
const totalPrice = computed(() => {
	return articles.value.reduce((acc, cur) => {
		if(!checked.value.includes(cur.Course.id)) return acc;
		acc += cur.is_free == 1 ? 0 : cur.Course.price;

		return acc;
	}, 0)
})

/** 전체선택 */
const checkAllBtn = () => {
	/**
	 * 전체선택 시 처리
	 */
	if(isChecked.value) {
		checked.value = [];
	}
	else {
		checked.value = articles.value.map(v => v.Course.id);
	}
}

const del_checked = async () => {
	try {
		const params = new URLSearchParams();

		params.append('course', JSON.stringify(checked.value));

		await server({
			url: `/api/v1/course/cart`,
			method: 'DELETE',
			data: params
		})
		.then(res => res.data.result);

		toast.fire({
			icon: "success",
			title: "수강바구니 삭제",
		});
		getArticles();
	} catch(e) {
		console.log(e);
		toast.fire({
			icon: "error",
			title: "오류 발생!",
		});
	}
}

const getArticles = async () => {
	try {
		const params = new URLSearchParams();
		if(searchModel.value != undefined) {
			Object.keys(searchModel.value).map(v => {
				params.append(v, searchModel.value[v]);
			});
		}

		const response = await server.get(`/api/v1/course/cart`, { params }).then(res => res.data.result);
		articles.value = response;
	} catch(e) {
		console.log(e);
		toast.fire({
			icon: "error",
			title: "오류 발생!",
		});
	}
}
const payment = async () => {
	try {
		if(checked.value.length === 0) return;
		if(!term.value) {
			toast.fire({
				icon: "error",
				title: "필수항목에 동의하시기 바랍니다.",
			});

			return;
		}

		const params = new URLSearchParams();

		/** 배열로 된 선택항목 전부 넘기기 */
		params.append('course', JSON.stringify(checked.value));
		
		const response = await server({
			url: '/api/v1/payment',
			method: 'POST',
			data: params
		})
		.then(res => res.data.result);

		console.log(response);

		toast.fire({
			icon: "success",
			title: "결제완료",
		});

		/**
		 * response data
		 * payment_code *
		 * price *
		 * product_name
		 * 
		 * 위 데이터를 보내어, 결제모듈 실행
		 */


		/**
		 * 결제모듈 성공 시 webhook 강제실행
		 */



		router.push({ path: '/mypage/room' });
		
		// await onCallWebhook(response.payment_code);
	} catch(e) {
		console.log(e);
	}
}

/** 웹훅 실행 임시 */
const onCallWebhook = async (payment_code) => {
	try {
		const params = new URLSearchParams();

		params.append('payment_code', payment_code);

		const response = await server({
			url: '/api/public/payment/callback',
			method: 'GET',
			params
		})
		.then(res => res.data.result);

		router.push({ path: `/payment/result/${payment_code}` })
	} catch(e) {

	}
}

onMounted(async() => {
	getArticles();
})
</script>

<template>
	<include-header/>

	<div id="sub_wrap">
		<div class="inner_wrap">

			<include-mypage-header/>

			<div class="my_cart_wrap">
				<div v-if="articles.length>0" class="my_cart_body">
					<div class="my_cart_body_top">
						<div class="lgray_box mb-4">
							<div class="flex1">
								<input
									type="checkbox"
									class=""
									id="checkAll"
									:checked="isChecked"
									@click="checkAllBtn()"
								/>
								<label for="checkAll">
									<TranslateText
										source="ko"
										contents="전체선택"
									/>
									{{ checked.length }}/{{ articles.length }}
								</label>
							</div>
							<div class="flex2">
								<button type="button" class="btn bor_gr bg_white" @click="del_checked()">
									<i class="fa fa-times fw-lighter" aria-hidden="true"></i>
									<span>
										<TranslateText
											source="ko"
											contents="선택삭제"
										/>
									</span>
								</button>
							</div>
						</div>
						<ul v-if="articles.length>0" class="cart_list">
							<CartBox
								v-model="checked"
								v-for="(item) in articles"
								:key="`card-box-${item.id}`"
								:item="item"
								@change="getArticles()"
							/>
						</ul>
					</div>
					<div v-if="articles.length > 0" class="my_cart_body_content cart_info">
						<div class="my_cart_body_inner">
							<div class="cart_summary">
								<div class="title">
									<TranslateText
										source="ko"
										contents="총 주문금액"
									/>
								</div>
								<div class="price">
									<span
										v-if="checked.length === 0"
									>
										-
									</span>
									<TranslateText
										v-else-if="totalPrice == 0"
										source="ko"
										contents="무료"
									/>
									<span v-else>
										{{ `￦ ${Number(totalPrice).toLocaleString()}` }}
									</span>
								</div>
								<!-- <div class="origin">198,000</div>
								<div class="discount">10%할인</div> -->
							</div>
							<div class="my_cart_head">
								<i class="fa fa-exclamation-circle" aria-hidden="true"></i>
								<span>
									<TranslateText
										source="ko"
										contents="표기된 금액은 VAT가 포함된 금액입니다."
									/>
								</span>
							</div>
							<div class="cart_agree">
								<input
									type="checkbox"
									aria-label="term"
									id="term"
									name="term"
									v-model="term"
								/>
								<span>
									<TranslateText
										source="ko"
										contents="회원 본인은 주문내용을 확인했으며, 구매조건 및 개인정보처리방침과 결제에 동의합니다."
									/>
								</span>
							</div>
						</div>


						<div
							class="cart_submit"
							:class="{
								'bg-secondary-subtle': checked.length === 0
							}"
						>

							<div class="cart_agree_m">
								<div>
									<input
										type="checkbox"
										aria-label="term"
										id="term"
										name="term"
										v-model="term"
									/>
									<span>
										<TranslateText
											source="ko"
											contents="회원 본인은 주문내용을 확인했으며, 구매조건 및 개인정보처리방침과 결제에 동의합니다."
										/>
									</span>
								</div>
							</div>
							
							<button
								type="button"
								class=""
								@click="payment()"
								:disabled="checked.length === 0"
							>
								<TranslateText
									source="ko"
									contents="수강 신청하기"
								/>
							</button>
						</div>
					</div>
				</div>
				<!--강의없을때-->
				<div v-else class="text-center py-5 my-5 text-secondary fs-5">
					<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" class="inline-block mb-3"><path fill="#666" d="M12 17q.425 0 .713-.288T13 16q0-.425-.288-.713T12 15q-.425 0-.713.288T11 16q0 .425.288.713T12 17Zm-1-4h2V7h-2v6Zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"/></svg><br>
					<TranslateText
						source="ko"
						contents="수강바구니가 비어있습니다."
					/>
				</div>
			</div>

		</div>

	</div><!-- //#sub_wrap -->

	<include-footer/>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'
import mypage from '../layout/mypage.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer,
		'include-mypage-header': mypage
	}
}
</script>