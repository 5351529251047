<script setup>
import { onMounted, ref, computed } from "vue"
import { useRoute, useRouter } from "vue-router"

import axios from 'axios';

import { useAuthStore } from '@/store/auth.pinia';
import { useCommonStore } from '@/store/common.pinia';

import { sns } from "@/components/utils/sns";
import { env as SERVER_URL } from '@/components/utils/env';

import TranslateText from '@/components/common/translate_text.vue';

import server from '@/api/server';

const route = useRoute()
const router = useRouter();

const authStore = useAuthStore();
const commonStore = useCommonStore();

const is_login = computed(() => !!authStore.user?.name);

const callback_url = sns.callback_url;
const callback_google_url = sns.callback_google_url;
const kakao_auth = sns.kakao_auth;
const kakao_client = sns.kakao_client;
const google_auth = sns.google_auth;
const google_secret = sns.google_secret;

/** 카카오 초기화 */
if (!Kakao.isInitialized()) {
	Kakao.init(kakao_auth); // 사용하려는 앱의 JavaScript 키 입력
};

const code = route.query.code;
const states = route.query.state; // csrf 공격을 방지하기 위해 애플리케이션에서 생성한 상태 토큰값으로 URL 인코딩을 적용한 값을 사용

const state = ref({
	access_token : "", // 발급받은 access_token 저장을 위한 변수
	refresh_token : "", // 발급받은 refresh_token 저장을 위한 변수
	sns :'',
	uuid:'',
	nickname:'',
	phone:'',
});

const formModel = ref({
	user_id:"",
	mb_name:"",
	mb_email: "",
	user_pw: "",
	joinable: ""
});

/** 카카오 로그인 처리 */
const getTokenKakao = async() => {
	try {
		const params = new URLSearchParams();
		params.append('grant_type', 'authorization_code');
		params.append('client_id', kakao_client);
		params.append('redirect_uri', callback_url);
		params.append('code', code);

		const info = await axios({
			url: 'https://kauth.kakao.com/oauth/token',
			method: 'POST',
			data: params
		})
		.then(res => res.data);

		Kakao.Auth.setAccessToken(info.access_token);

		const statusInfo = await Kakao.Auth.getStatusInfo();

		if (statusInfo.status === 'connected') {
			state.value.sns = 'Kakao';
			state.value.uuid = statusInfo.user.id; //response.id_token.split('.')[0]; // 고유 아이디 uuid
			state.value.email = statusInfo.user.kakao_account.email;
			state.value.name = statusInfo.user.kakao_account.profile.nickname;
			//state.phone = res.user.kakao_account.mobile;
			snsUseridCheck();
		}

		//await $.ajax({
		// 		type : "POST"
		// 		, url : 'https://kauth.kakao.com/oauth/token'
		// 		, data : {
		// 			grant_type : 'authorization_code',
		// 			client_id : kakao_auth,
		// 			redirect_uri : callback_url,
		// 			code : code
		// 		}
		// 		, contentType:'application/x-www-form-urlencoded;charset=utf-8'
		// 		, dataType: null
		// 		, success : function(response) {
		// console.log(response);
		// 			Kakao.Auth.setAccessToken(response.access_token);

		// 			Kakao.Auth.getStatusInfo()
		// 			.then(function(res) {
		// 				if (res.status === 'connected') {
		// console.log(res.user);
		// 					state.sns = 'Kakao';
		// 					state.uuid = res.user.id; //response.id_token.split('.')[0]; // 고유 아이디 uuid
		// 					state.email = res.user.kakao_account.email;
		// 					state.name = res.user.kakao_account.profile.nickname;
		// 					//state.phone = res.user.kakao_account.mobile;
		// 					snsUseridCheck(state);
		// 				}
		// 			})
		// 			.catch(function(err) {
		// 				Kakao.Auth.setAccessToken(null);
		// 			});

		// 		}
		// 		, error : function(jqXHR, error) {
		// console.log(jqXHR);
		// console.log(error);
		// 		}
		// 	});
	} catch(e) {
		Kakao.Auth.setAccessToken(null);
	}
}

const getTokenGoogle = async() => {
	try {
		const params = new URLSearchParams();
		params.append('grant_type', 'authorization_code');
		params.append('client_id', google_auth);
		params.append('client_secret', google_secret);
		params.append('redirect_uri', callback_google_url);
		params.append('code', code);

		const info = await axios({
			url: 'https://oauth2.googleapis.com/token',
			method: 'POST',
			data: params,
		})
		.then(res => res.data);

		state.value.access_token = info.access_token;
		state.value.id_token = info.id_token;

		googleUserInfo();

	// 	await $.ajax({
	// 		type : "POST"
	// 		, url : 'https://oauth2.googleapis.com/token'
	// 		, data : {
	// 			grant_type : 'authorization_code',
	// 			client_id : sns.google_auth,
	// 			client_secret : sns.google_secret,
	// 			redirect_uri : sns.callback_google_url,//sns.callback_url,
	// 			code : code
	// 		}
	// 		, contentType:'application/x-www-form-urlencoded;charset=utf-8'
	// 		, dataType: null
	// 		, success : function(response) {
	// console.log(response);
	// 			state.access_token = response.access_token;
	// 			state.id_token = response.id_token;

	// 			googleUserInfo();
	// 		}
	// 	});
	} catch(e) {

	}

}

const googleUserInfo = async() => {
	try {
		const info = await axios({
			url: 'https://www.googleapis.com/userinfo/v2/me',
			method: 'GET',
			headers: {
				'Authorization': `Bearer ${state.value.access_token}`
			}
		})
		.then(res => res.data);

		console.log(info);

		// 변수에 값 넣기
		state.value.sns = 'Google';
		state.value.uuid = info.id; // 고유 아이디 uuid
		state.value.email = info.email;
		state.value.name = info.name;

		snsUseridCheck();

		// 	const url = `https://www.googleapis.com/userinfo/v2/me`;
// 	let header = "Bearer " + state.access_token;
// 	const headers = {"Authorization": header};
// console.log("headers => ", headers);
// 	const { data } = await server.get(url, { headers });
// console.log("*****googleUserInfo data***** => ", data);

// 	// 변수에 값 넣기
// 	state.sns = 'Google';
// 	state.uuid = data.id; // 고유 아이디 uuid
// 	state.email = data.email;
// 	state.name = data.name;
// 	snsUseridCheck(state);
	} catch(e) {

	}

}

// callback url
const naverCallback = async() => {
	try {
		console.log('naver callback');
		const params = new URLSearchParams();

		params.append('code', code);
		params.append('state', states);

		const info = await server({
			url: `/api/auth/naver/info`,
			method: 'GET',
			params,
		})
		.then(res => res.data.result);

		console.log(info);
		
		// 변수에 값 넣기
		state.value.sns = 'Naver';
		state.value.uuid = info.response.id; // 고유 아이디 uuid
		state.value.email = info.response.email;
		state.value.name = info.response.name;

		snsUseridCheck();

		// naverUserInfo();

		// console.log('SNS', 'naverCallback');
	// const url = `/oauth2.0/token?grant_type=authorization_code&client_id=${sns.naver_auth}&client_secret=${sns.naver_secret}&code=${code}&state=${states}`;
	// const headers = {"X-Naver-Client-Id": sns.naver_auth, "X-Naver-Client-Secret": sns.naver_secret};
	// const { data } = await server.get(url, {headers});
	
	// console.log("naver data => " , data);
	// state.access_token = data.access_token;
	// state.refresh_token = data.refresh_token;
	
	// naverUserInfo();
	} catch(e) {
		console.log(e);
	}
}

/**
 * 바로 회원가입
 */
const snsUseridCheck = async () => {
	console.log(`${state.value.sns} snsUseridCheck!`)
	// 230821 바로 가입
	snsUserJoin();
	/*
	return false;
		await server
		.get(`/api/app/member/sns_check?sns=${state.sns}&uuid=${state.uuid}`)
		.then((response) => {
	console.log('소셜 uuid중복체크', response.data.result);
			if(response.data.result.isDuplicated) { //DB에 일치하는 아이디가 존재하는 경우
				login_submit(state); // 회원가입 안하고 로그인 진행
			} else { //DB에 일치하는 아이디가 존재하지 않는 경우
				snsUserJoin(state); //회원가입 후 로그인 진행
			}
		})
		.catch((error) => {
			console.log(error);
		});
	*/
};

// 회원가입 
const snsUserJoin = async () => {
	try {
		/**
		 * 231116 로그인된 상태일때 === link로 바로 처리
		 * 
		 */

		if(is_login.value && false) {
			const url = `/api/auth/registration/${state.value.sns.toLowerCase()}/link`; // 230913 이메일중복시 가입
			const params = new URLSearchParams();

			params.append("uuid", state.value.uuid);
			params.append("name", state.value.name);
			params.append("email", state.value.email);

			await server({
				url,
				method: 'POST',
				data: params,
			})
			.then(res => res.data.result);

			authStore.setUser({
				name: state.value.name,
				email: state.value.email,
			})

			router.push('/');
			
			toast.fire({
				icon: "success",
				title: `[${state.value.sns}] 연동 완료`,
			});
		}
		/** 신규가입 */
		else {
			console.log('여기옴');
			// 230321 URL
			const url = `/api/auth/registration/${state.value.sns.toLowerCase()}`;
			const params = new URLSearchParams();

			params.append("uuid", state.value.uuid);
			params.append("name", state.value.name);
			params.append("email", state.value.email);

			const response = await authStore.join_sns(url, params);

			/**
			 * 이미 같은 이메일이 있으면,
			 */
			if(response.message == 1) {
				/**
				 * 해당 SNS로 회원가입이 되어있으면, 로그인처리
				 */
				if(response.user[`${state.value.sns.toLowerCase()}_reg_date`]) {
					login_submit(state.value);
				}
				/**
				 * 다른 SNS로 회원가입이 되어있고, 연동시킬려면
				 */
				else {
					toast.fire({
						icon: "error",
						title: `해당 이메일은 이미 가입되어 있습니다.`,
					});

					router.push('/member/login');

					// Swal.fire({
					// 	title: '동일한 이메일로 가입한 계정이 있습니다.\n추가로 가입하시겠습니까?',
					// 	text: '이미 가입한 이메일',
					// 	icon: 'question',
					// 	showCancelButton: true, // cancel버튼 보이기. 기본은 원래 없음
					// 	cancelButtonText: '취소', // cancel 버튼 텍스트 지정
					// 	cancelButtonColor: '#d33', // cancel 버튼 색깔 지정
					// 	confirmButtonText: '확인', // confirm 버튼 텍스트 지정
					// 	confirmButtonColor: '#3085d6', // confrim 버튼 색깔 지정
					// 	reverseButtons: false, // 버튼 순서 거꾸로

					// }).then(async (result) => {
					// 	// 만약 Promise리턴을 받으면,
					// 	if (result.isConfirmed) { // 만약 모달창에서 confirm 버튼을 눌렀다면
					// 		const url = `/api/auth/registration/${state.value.sns.toLowerCase()}/link`; // 230913 이메일중복시 가입
							
					// 		await server({
					// 			url,
					// 			method: 'POST',
					// 			data: params,
					// 		})
					// 		.then(res => res.data.result);

					// 		authStore.setUser({
					// 			name: state.value.name,
					// 			email: state.value.email,
					// 		})
							
					// 		toast.fire({
					// 			icon: "success",
					// 			title: `[${state.value.sns}] 연동 완료`,
					// 		});
					// 	}
					// })
				}
			}
			/**
			 * 신규 회원가입 처리
			 * 로그인 처리
			 */
			else {
				authStore.setUser({
					name: state.name,
					email: state.email,
				});
				
				// toast.fire({
				// 	icon: "success",
				// 	title: `[${state.value.sns}] 회원가입 완료`,
				// });

				/** 기존이든 신규든 로그인 처리 */
				login_submit();
			}
		}
	} catch(e) {
		console.log('sns login function', e);
	}
	//naverUserJoinImg();
}


/**
 * sns 로그인 요청
 */
const login_submit = async () => {
	try {
		const url = `/api/auth/login/${state.value.sns.toLowerCase()}`;

		const params = new URLSearchParams();
		// 230818 notEmpty() 때문에
		params.append("user_id", state.value.uuid);
		params.append("user_pw", state.value.uuid);

		params.append("sns", state.value.sns);
		params.append("uuid", state.value.uuid);

		params.append('type', 1);
	/*
		Object.keys(state).forEach(v => {
			if(state[v] === undefined || state[v] === null ) {
				return;
			}
			params.append(v, state[v]);
		});
	*/

		await authStore.login_sns(url, params);

		router.push('/');

		toast.fire({
			icon: "success",
			title: "로그인 성공!",
		});

		return;

		await server
		//.post(`/api/auth/login`, params)
		.post(url, params)
		.then((response) => {
			localStorage.setItem('userID', state.uuid);
			localStorage.setItem('userEmail', state.email);
			//localStorage.setItem('userToken', response.data.result.token);
			localStorage.setItem('userName', response.data.result.user.name);
			localStorage.setItem('userNickName', response.data.result.user.nick_name);

			//router.push('/member/join_complete');
			router.push('/');

			toast.fire({
				icon: "success",
				title: "로그인 성공!",
			});
		})
		.catch((error) => {
	console.log(error);
			toast.fire({
				icon: "error",
				title: "로그인 실패!",
			});
		});
	} catch(e) {
		console.log('login_sns', e);
		toast.fire({
			icon: "error",
			title: "로그인 실패!",
		});
	}
};

const goto_home  = () => {
	router.push('/');
}

const id_check = async () => {
	try {
		const info = await server({
			url: `/api/app/member/id-check?user_id=${formModel.value.user_id}`,
			method: 'GET'
		})
		.then(res => res.data.result);

		toast.fire({
			icon: info.isDuplicated ? "error" : "success",
			title: info.isDuplicated ? "이미 사용중입니다." : "사용 가능합니다.",
		});
	} catch(e) {

	}
	// await server
	// .get(`/api/app/member/id-check?user_id=${formModel.value.user_id}`)
	// .then((response) => {
	// 	if(response.data.result.isDuplicated) {
	// 		toast.fire({
	// 			icon: "error",
	// 			title: "이미 사용중입니다.",
	// 		});
	// 	} else {
	// 		toast.fire({
	// 			icon: "success",
	// 			title: "사용 가능합니다.",
	// 		});
	// 	}
	// })
	// .catch((error) => {
	// console.log(error);
	// // toast.fire({
	// //   icon: "error",
	// //   title: error.response.data.result.message,
	// // });
	// });
};

onMounted(() => {
	if(route.query.state) {
		naverCallback();
	}
	else if(route.query.authuser){
		getTokenGoogle();
	}
	else if(route.query.code) {
		getTokenKakao();
	}
	else {
		// toast.fire({
		// 	icon: "error",
		// 	title: "SNS 정보가 없습니다.",
		// });

		router.push('/member/login');
	}
})
</script>

<template>
	<include-header/>

	<div id="member_wrap">
		<div class="join_container">
			<div class="join_wrap bg_w ">
				<div class="sub_tit"></div>
			</div>
		</div>
	</div>

	<include-footer/>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer
	}
}
</script>