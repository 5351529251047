<script setup>
import { useRoute, useRouter } from "vue-router"

import TranslateText from '@/components/common/translate_text.vue';

const route = useRoute();
const router = useRouter();

const onLocation = (path) => {
	router.push({ path });
}
</script>
<template>

	<div class="class_head_top sub_page_top">
		<div class="title">
			<TranslateText
				source="ko"
				contents="마이페이지"
			/>
		</div>
	</div>

	<div class="sub_menu_wrap">
		<ul>
			<li
				:class="{
					'on': route.path=='/mypage/info'
				}"
				style="max-width: 25%"
			>
				<button
					@click="onLocation('/mypage/info')"
				>
					<TranslateText
						source="ko"
						contents="개인정보"
					/>
				</button>
			</li>
			<li
				:class="{
					'on': route.path=='/mypage/room'
				}"
				style="max-width: 25%"
			>
				<button
					@click="onLocation('/mypage/room')"
				>
					<TranslateText
						source="ko"
						contents="내 강의실"
					/>
				</button>
			</li>
			<li
				:class="{
					'on': route.path=='/mypage/like'
				}"
				style="max-width: 25%"
			>
				<button
					@click="onLocation('/mypage/like')"
				>
				<TranslateText
						source="ko"
						contents="좋아요"
					/>
				</button>
			</li>
			<li
				:class="{
					'on': route.path=='/mypage/cart'
				}"
				style="max-width: 25%"
			>
				<button
					@click="onLocation('/mypage/cart')"
				>
					<TranslateText
						source="ko"
						contents="수강바구니"
					/>
				</button>
			</li>
			<!-- <li
				:class="{
					'on': route.path.indexOf('/mypage/buy') > -1
				}"
				style="max-width: 20%"
			>
				<button
					@click="onLocation('/mypage/buy')"
				>
					<TranslateText
						source="ko"
						contents="구매내역"
					/>
				</button>
			</li> -->
			<!-- <li><router-link to="/mypage/buy" :class="route.path=='/mypage/buy' || route.path.indexOf('/mypage/buy') != -1?'on':''">구매내역</router-link></li> -->
		</ul>
	</div>
</template>

<script>
export default {
	name: 'App',
	components: {
	}
}
</script>