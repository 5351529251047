<script setup>
import { onMounted, ref } from "vue";

import TranslateText from '@/components/common/translate_text.vue';

import server from '@/api/server';

let footer = ref({})

onMounted(async() => {
	getFooter();
})

const getFooter = async (tag) => {
	let response = '';
	response = await server.get(`/api/public/footer`)
	footer.value = response.data.result;
}
</script>
<template>
	<div class="footer_link">
		<div class="inner_wrap">
			<router-link to="/privacy">
				<TranslateText
					source="ko"
					contents="개인정보 처리방침"
				/>
			</router-link>
			<router-link to="/terms">
				<TranslateText
					source="ko"
					contents="이용약관"
				/>
			</router-link>
		</div>
	</div>

	<footer>
		<div class="inner_wrap">
			<div class="footer_info">
			<address>
				<span v-if="footer.addr" class="addr">Addr : {{ footer.addr }}</span>
				<span v-if="footer.tel" class="tel">TEL : {{ footer.tel }}</span>
				<span v-if="footer.fax" class="fax">FAX : {{ footer.fax }}</span>
			</address>
			<div class="copyright">
				Copyright &copy;2023 {{ footer.name }}. All rights reserved.
			</div>
			</div>
			<div class="footer_logo">
				<img src="/img/layout/footer_logo.png" alt="동서대학교 로고">
			</div>
		</div>
	</footer>
</template>

<script>
export default {

}
</script>