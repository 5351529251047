// import { required, minLength, email, sameAs, helpers } from "@vuelidate/validators";
import * as validators from '@vuelidate/validators'
import i18n from './i18n';
import { isId } from './common';
// import { i18n } from "@/i18n"

const { createI18nMessage } = validators

const withI18nMessage = createI18nMessage({ t: i18n.global.t.bind(i18n) })

export const required = withI18nMessage(validators.required);
export const minLength = withI18nMessage(validators.minLength, { withArguments: true });
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true });
export const minValue = withI18nMessage(validators.minValue, { withArguments: true });
export const maxValue = withI18nMessage(validators.maxValue, { withArguments: true });
export const sameAs = withI18nMessage(validators.sameAs, { withArguments: true });
export const email = withI18nMessage(validators.email);

// export const required = validators.helpers.withMessage('필수입력 항목입니다.', validators.required);
// export const minLength = (length) => validators.helpers.withMessage(`최소 글자수는 ${length} 입니다.`, validators.minLength(length));
// export const maxLength = (length) => validators.helpers.withMessage(`최대 글자수는 ${length} 입니다.`, validators.maxLength(length));
// export const minValue = (value) => validators.helpers.withMessage(`최소 값은 ${value} 입니다.`, validators.minValue(value));
// export const maxValue = (value) => validators.helpers.withMessage(`최대 값은 ${value} 입니다.`, validators.maxValue(value));
// export const sameAs = (target) => validators.helpers.withMessage(`값이 서로 일치하지 않습니다.`, validators.sameAs(target));
// export const email = validators.helpers.withMessage(`이메일 형식이 아닙니다.`, validators.email);