<script setup>
import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useAuthStore } from '@/store/auth.pinia';
import { useCommonStore } from '@/store/common.pinia';

import { env as SERVER_URL } from '@/components/utils/env';

import TranslateText from '@/components/common/translate_text.vue';

import server from '@/api/server';

const props = defineProps({
	category:{
		type:String,
		default:''
	},
	lecture_id:{
		type:Number,
		default:0
	}
})

const route = useRoute();
const router = useRouter();

const authStore = useAuthStore();
const commonStore = useCommonStore();

const tab = ref(1);

const is_login = computed(() => !!authStore.user?.name);
const language = computed(() => article.value.CourseDetails?.findIndex(v => v.language === commonStore.lang))

const article = ref({})
const likes = ref([])
const carts = ref([])

const getSingleArticle = async () => {
	try {
		const response = await server({
			url: `/api/${is_login.value ? 'v1' : 'public'}/course/${props.lecture_id}`,
			method: 'GET',
		})
		.then(res => res.data.result);

		if(!is_login.value) {
			
		}
		
		article.value = response;
	} catch(e) {

	}
}


const addLike = async () => {
	try {
		if(!is_login.value) return;

		const params = new URLSearchParams();
		params.append("course_id", article.value.id);

		const response = await server({
			url: `/api/v1/course/like`,
			method: 'PATCH',
			data: params,
		})
		.then(res => res.data.result);

		if(response.message == "OK") {
			if(article.value.CourseFav?.createdAt) {
				toast.fire({
					icon: "success",
					title: "좋아요 해제",
				});
			} else {
				toast.fire({
					icon: "success",
					title: "좋아요!",
				});
			}
		}

		getSingleArticle();
	} catch(e) {
		console.log(e);
		toast.fire({
			icon: "error",
			title: "오류 발생!",
		});
	}
}


const addCart = async () => {
	try {
		if(!is_login.value) return;

		const params = new URLSearchParams();
		params.append("course_id", article.value.id);

		const response = await server({
			url: `/api/v1/course/cart`,
			method: 'PATCH',
			data: params
		})
		.then(res => res.data.result);

		if(response.message === "OK") {
			if(article.value.CourseCart?.createdAt) {
				toast.fire({
					icon: "success",
					title: "수강바구니 삭제",
				});
			} else {
				toast.fire({
					icon: "success",
					title: "수강바구니 추가!",
				});
			}
		}

		getSingleArticle();
	} catch(e) {
		console.log(e);
		toast.fire({
			icon: "error",
			title: "오류 발생!",
		});
	}
}

const class_register = async (id) => {
	/**
	 * 로그인 되어있으면 수강등록
	 * 안되어있으면 로그인창
	 */

	try {
		// if(!is_login.value) {
		// 	router.push({ path: '/member/login' });

		// 	return;
		// }		

		if(article.value.CourseRegist?.createdAt) {
			router.push({ path: `/lecture/see/${id}` });

			return;
		}

		const params = new URLSearchParams();
		params.append("course_id", id);

		await server
			.post(`${SERVER_URL}/api/v1/course`, params )
			.then((response) => response.data.result);

		toast.fire({
			icon: "success",
			title: "수강신청완료",
		});

		router.push('/mypage/room');
	} catch(e) {
		console.log(e);
		toast.fire({
			icon: "error",
			title: "오류 발생!",
		});
	}
}

const timeFormat = (seconds) => {
	const date = new Date(null);
	date.setSeconds(seconds);

	return date.toISOString().slice(11, 19);
}

function siblings(t) {
	const children = t.parentElement.children;
	const tempArr = [];

	for (let i = 0; i < children.length; i++) {
		tempArr.push(children[i]);
	}
	
	return tempArr.filter(function(e){
		return e != t;
	});
}

const lecture_view_tab = () => {
	let cc = document.querySelectorAll('.class_view_desc > ul li');
	if(cc.length > 0) {
		cc.forEach(function (item) {
			item.querySelector('a').addEventListener('click', function(e) {
				e.preventDefault();

				siblings(item).forEach(function(item_li) {
					item_li.querySelector('a').classList.remove('on');
				});

				if(document.querySelectorAll('.class_view_desc .desc > *').length > 0) {
					document.querySelectorAll('.class_view_desc .desc > *').forEach(function(item_desc) {
						item_desc.classList.remove('on');
					});
				}
				this.classList.add('on');
				let link = this.getAttribute('href');

				if(document.querySelector(link.substring(link.indexOf('#'))) !== null) {
					document.querySelector(link.substring(link.indexOf('#'))).classList.add('on');
				}
			})
		});
	}
}

const onClicked = () => {

}

onMounted(async() => {
	getSingleArticle()
	// lecture_view_tab()
})

</script>
<template>
	<include-header/>

	<div id="sub_wrap">
		<div class="inner_wrap">
			<div class="class_view_wrap">
				<div class="class_view_head">
					<ul class="breadcrumb">
						<li>
							<span @click="router.push({ path: '/lecture' })">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#666" d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z"/></svg>
							</span>
						</li>
						<!-- <li><router-link to="#" class="fa fa-angle-right">비즈니스&middot;마케팅</router-link></li> -->
						<li>
							<router-link to="#" class="fa fa-angle-right">
								<TranslateText
									source="ko"
									:contents="article.Category?.name"
								/>
							</router-link>
						</li>
					</ul>
					<div class="class_view_top">
						<div
							class="img_wrap"
							:style="{
								'background': `url(${SERVER_URL}${article.Thumbnail?.path})`,
								'background-size': 'cover',
								'background-position': 'center',
								'background-repeat': 'no-repeat',
							}"
						>
							<!-- <img :src="`${SERVER_URL}${article.Thumbnail?.path}`" alt="커리큘럼 섬네일"> -->
						</div>
						<div class="text_wrap">
							<button class="btn_share" aria-hidden="true" title="category_button">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#777" d="m13.12 17.023l-4.199-2.29a4 4 0 1 1 0-5.465l4.2-2.29a4 4 0 1 1 .958 1.755l-4.2 2.29a4.008 4.008 0 0 1 0 1.954l4.2 2.29a4 4 0 1 1-.959 1.755ZM6 14a2 2 0 1 0 0-4a2 2 0 0 0 0 4Zm11-6a2 2 0 1 0 0-4a2 2 0 0 0 0 4Zm0 12a2 2 0 1 0 0-4a2 2 0 0 0 0 4Z"/></svg>
							</button>
							<div class="class_category">
								<TranslateText
									source="ko"
									:contents="article.Category?.name"
								/>
							</div>
							<div class="class_title">
								<span>
									<TranslateText
										source="ko"
										:contents="Array.isArray(article?.CourseDetails) && (article.CourseDetails[language]?.title || '제목을 입력하세요.')"
									/>
								</span>
							</div>
							<!-- <div class="class_teacher">
								<i class="fa fa-user" aria-hidden="true"></i> DSU
							</div> -->
							
							<div class="class_info">
								<h3>
									<span>
										<TranslateText
											source="ko"
											contents="클래스정보"
										/>
									</span>
								</h3>
								<ul >
									<li>
										<div class="title">
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#222" fill-rule="evenodd" d="M20 4H4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM4 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H4Zm2 5h2v2H6V7Zm5 0a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-6Zm-3 4H6v2h2v-2Zm2 1a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1Zm-2 3H6v2h2v-2Zm2 1a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1Z" clip-rule="evenodd"/></svg>	
											<TranslateText
												source="ko"
												contents="강의수"
											/>
										</div>
										<div class="content">
											{{ article.CourseSyllabuses?.length || '-' }}
											<TranslateText
												source="ko"
												contents="강"
											/>
										</div>
									</li>
									<li>
										<div class="title">
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16"><path fill="currentColor" d="M10.561 8.073a6.005 6.005 0 0 1 3.432 5.142a.75.75 0 1 1-1.498.07a4.5 4.5 0 0 0-8.99 0a.75.75 0 0 1-1.498-.07a6.004 6.004 0 0 1 3.431-5.142a3.999 3.999 0 1 1 5.123 0ZM10.5 5a2.5 2.5 0 1 0-5 0a2.5 2.5 0 0 0 5 0Z"/></svg>
											<TranslateText
												source="ko"
												contents="모집인원"
											/>
										</div>
										<div class="content">
											<TranslateText
												v-if="article.is_person_limit == 2"
												source="ko"
												contents="무제한"
											/>
											<span v-else>
												{{ `${Number(article.person_limit).toLocaleString()}` }}
											</span>
										</div>
									</li>
									<li>
										<div class="title">
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4h-2V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3Zm1 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7h16Zm0-9H4V7a1 1 0 0 1 1-1h2v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h2a1 1 0 0 1 1 1Z"/></svg>
											<TranslateText
												source="ko"
												contents="모집종료"
											/>
										</div>
										<div class="content">
											<TranslateText
												v-if="article.is_date_limit == 2"
												source="ko"
												contents="무제한"
											/>
											<span v-else>
												{{ article.end_date }}
											</span>
										</div>
									</li>
									<li>
										<div class="title">
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M19 4h-2V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3Zm1 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7h16Zm0-9H4V7a1 1 0 0 1 1-1h2v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h2a1 1 0 0 1 1 1Z"/></svg>
											<TranslateText
												source="ko"
												contents="수강기간"
											/>
										</div>
										<div class="content">
											<TranslateText
												v-if="article.is_open_limit == 2"
												source="ko"
												contents="무제한"
											/>
											<span v-else>
												{{ `${Number(article.study_day).toLocaleString()}` }}
											</span>
										</div>
									</li>
								</ul>
							</div>
						
							<div class="info-teacher">
								<h6>
									<span>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="#222" d="M19 19H5V5h14m0-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m-2.5 13.25c0-1.5-3-2.25-4.5-2.25s-4.5.75-4.5 2.25V17h9M12 12.25A2.25 2.25 0 0 0 14.25 10A2.25 2.25 0 0 0 12 7.75A2.25 2.25 0 0 0 9.75 10A2.25 2.25 0 0 0 12 12.25Z"/></svg>
									</span>
									<span>
										<TranslateText
											source="ko"
											contents="강사"
										/>:
									</span>
									<b>
										{{ article.teacher_name || '-' }}
									</b>
								</h6>
							</div>

							<div class="price">
								<small>
									<TranslateText
										source="ko"
										contents="판매가"
									/>
								</small>
								<span>
									<TranslateText
										v-if="article.is_free == 1"
										source="ko"
										contents="무료"
									/>
									<span v-else>
										{{ `￦ ${Number(article.price).toLocaleString()}` }}
									</span>
								</span>
							</div>
							<div class="class_tags">
								<b>
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="#222" fill-rule="evenodd" clip-rule="evenodd"><path d="M2 8v8a1 1 0 0 0 1 1h13.62a1 1 0 0 0 .76-.35l3.428-4a1 1 0 0 0 0-1.3l-3.428-4a1 1 0 0 0-.76-.35H3a1 1 0 0 0-1 1ZM0 8v8a3 3 0 0 0 3 3h13.62a3 3 0 0 0 2.278-1.048l3.428-4a3 3 0 0 0 0-3.904l-3.428-4A3 3 0 0 0 16.62 5H3a3 3 0 0 0-3 3Z"/><path d="M15 13a1 1 0 1 0 0-2a1 1 0 0 0 0 2Zm0 2a3 3 0 1 0 0-6a3 3 0 0 0 0 6Z"/></g></svg>
								</b>
								<span
									v-for="tag in article.Category?.Tags"
									:key="tag.name"
									class="tag"
								>
									<TranslateText
										source="ko"
										:contents="tag.name"
									/>
								</span>
							</div>
						</div>
					</div>
				</div>

				<div class="class_view_body">
					<div class="class_view_desc">
						<ul>
							<li
								:class="{
									'on': tab === 1
								}"
							>
								<button
									class="w-100"
									:class="{
										'on': tab === 1
									}"
									title="introduce tab button"
									@click="tab = 1"
								>
									<TranslateText
										source="ko"
										contents="강의 소개"
									/>
								</button>
							</li>
							<li
								:class="{
									'on': tab === 2
								}"
							>
								<button
									class="w-100"
									:class="{
										'on': tab === 2
									}"
									title="curriculum tab button"
									@click="tab = 2"
								>
									<TranslateText
										source="ko"
										contents="커리큘럼"
									/>
								</button>
							</li>
							<!-- <li><router-link to="#desc3">강사 소개</router-link></li> -->
						</ul>
						<div class="desc">
							<div
								id="desc1"
								:class="{
									'on': tab === 1
								}"
							>
								<h3 class="hr_text after">
									<TranslateText
										source="ko"
										contents="강의소개"
									/>
								</h3>
								<div
									class="hr_contnet"
									v-html="Array.isArray(article?.CourseDetails) && (article?.CourseDetails[language]?.content || '요약')"
								>
								</div>
								<h3 class="hr_text after">
									<TranslateText
										source="ko"
										contents="추천대상"
									/>
								</h3>
								<div 
									class="hr_contnet"	
									v-html="Array.isArray(article?.CourseDetails) && (article?.CourseDetails[language]?.content1 || '추천')">
									</div>
								<h3 class="hr_text after">
									<TranslateText
										source="ko"
										contents="강의방법"
									/>
								</h3>
								<div 
									class="hr_contnet"	
									v-html="Array.isArray(article?.CourseDetails) && (article?.CourseDetails[language]?.content2 || '진행도')">
								</div>

								<h3 class="hr_text after">
									<TranslateText
										source="ko"
										contents="교재"
									/>
								</h3>
								<div 
									class="hr_contnet"	
									v-html="Array.isArray(article?.CourseDetails) && (article?.CourseDetails[language]?.content3 || '유의사항')">
								</div>
							</div>
							<div
								id="desc2"
								:class="{
									'on': tab === 2
								}"
							>
								<h3>
									<TranslateText
										source="ko"
										contents="커리큘럼"
									/>
								</h3>
								<div class="curriculum_total">
									<p>
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#222" fill-rule="evenodd" d="M20 4H4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM4 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H4Zm2 5h2v2H6V7Zm5 0a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-6Zm-3 4H6v2h2v-2Zm2 1a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1Zm-2 3H6v2h2v-2Zm2 1a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1Z" clip-rule="evenodd"></path></svg>
											<TranslateText
												source="ko"
												contents="강의수"
											/>
										</span> 
										<b>
											{{ article.CourseSyllabuses?.length || '-' }}
											<TranslateText
												source="ko"
												contents="강"
											/>
										</b> 
									</p>
									<p>
										<span>
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><g fill="none" stroke="#222" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="12" r="9"/><path d="M11 8v5h5"/></g></svg>
											<TranslateText
												source="ko"
												contents="강의시간"
											/>
										</span>
										<b>
											{{
												article.CourseSyllabuses?.reduce((acc, cur) => acc += cur?.CourseSyllabusDetails[cur.CourseSyllabusDetails.findIndex(v => v.language === 'ko')]?.Video?.duration || 0, 0)
											}}
											S
										</b>
									</p>
								</div>
								<ul class="curriculum">
									<li
										v-for="curriculum in article.CourseSyllabuses"
										:key="`curriculum-${curriculum.id}`"
									>
										<div @click="onClicked">
											<div class="title">
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#999" d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm-2 14.5v-9l6 4.5z"/></svg>
												<span>
													{{ Array.isArray(curriculum?.CourseSyllabusDetails) && curriculum?.CourseSyllabusDetails[curriculum?.CourseSyllabusDetails.findIndex(v => v.language == commonStore.lang)]?.title }}
												</span>
											</div>
											<div class="time">
												<span>
													{{ timeFormat(Array.isArray(curriculum?.CourseSyllabusDetails) && curriculum?.CourseSyllabusDetails[curriculum?.CourseSyllabusDetails.findIndex(v => v.language == 'ko')]?.Video?.duration || 0) }}
												</span>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<!-- <div id="desc3">
								강사소개<br>
								DSU의 대표
							</div> -->
						</div>
					</div>

					<div class="class_view_menu">
						<div class="class_view_menu_inner">
							<div class="title">
								<span>
									<TranslateText
										source="ko"
										:contents="Array.isArray(article?.CourseDetails) && (article.CourseDetails[language]?.title || '제목을 입력하세요.')"
									/>
								</span>
							</div>
							<div class="price">
								<TranslateText
									v-if="article.is_free == 1"
									source="ko"
									contents="무료"
								/>
								<span v-else>
									{{ `￦ ${Number(article.price).toLocaleString()}` }}
								</span>
							</div>
							<div v-if="is_login" class="class_view_btns">
								<button
									v-if="!article.CourseRegist?.createdAt"
									type="button"
									@click="addCart()"
									:class="{
										'red': article.CourseCart?.createdAt,
									}"
								>
									<i class="fa fa-cart-plus fw-semibold red" aria-hidden="true"></i>
									<TranslateText
										source="ko"
										contents="수강바구니"
									/>
								</button>
								<button
									type="button"
									@click="addLike()"
									:class="{
										'red': article.CourseFav?.createdAt,
										'w-100': article.CourseRegist?.createdAt
									}"
								>
									<i class="fa fa-heart-o fw-semibold red" aria-hidden="true"></i>
									<TranslateText
										source="ko"
										contents="좋아요"
									/>
								</button>
							</div>

							<div class="class_view_summary">
								<dl>
									<dt>
										<TranslateText
											source="ko"
											contents="강사"
										/>
									</dt>
									<dd>
										{{ article.teacher_name || '-' }}
									</dd>
								</dl>
								<dl>
									<dt>
										<TranslateText
											source="ko"
											contents="강의수"
										/>
									</dt>
									<dd>
										{{ article.CourseSyllabuses?.length || '-' }}
										<TranslateText
											source="ko"
											contents="강"
										/>
									</dd>
								</dl>
								<dl>
									<dt>
										<TranslateText
											source="ko"
											contents="강의시간"
										/>
									</dt>
									<dd>
										{{ Array.isArray(article?.CourseSyllabuses) && article.CourseSyllabuses.length }}
									</dd>
								</dl>
								<dl>
									<dt>
										<TranslateText
											source="ko"
											contents="수강기한"
										/>
									</dt>
									<dd>
										<span v-if="article.study_day">
											{{ Number(article.study_day).toLocaleString() }}
											<TranslateText
												source="ko"
												contents="일"
											/>
										</span>
										<span v-else>
											-
										</span>
									</dd>
								</dl>
								<p
									v-if="article.is_free == 1"
								>
									*
									<TranslateText
										source="ko"
										contents="이 강의는 무료로 제공되는 강의입니다."
									/>
								</p>
								<!-- <dl>
									<dt>난이도</dt>
									<dd>입문</dd>
								</dl> -->
							</div>

						</div>

						<div class="class_view_register">
							<button
								type="button"
								@click="class_register(article.id)"
							>
								<TranslateText
									source="ko"
									:contents="article.CourseRegist?.createdAt ? '강의듣기' : '수강신청하기'"
								/>
							</button>
						</div>
					</div>

				</div>
			</div>
		</div>

	</div><!-- //#sub_wrap -->

	<include-footer/>

</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer
	}
}
</script>