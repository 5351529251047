<!-- 결제 성공여부 -->
<script setup>
  import { useRouter } from 'vue-router';

	import TranslateText from '@/components/common/translate_text.vue';

  const router = useRouter();

  const getPaymentResult = async () => {
    try {
      /**
       * 결제코드를 이용하여, 서버에서 결제결과 가져오기
       * 1. 상품구매는 webhook에서 실질적으로 처리
       */
    } catch(e) {

    }
  }

  onMounted(() => {
    
  })
</script>

<template>
	<include-header/>

  <div id="sub_wrap">
    <div class="inner_wrap">
      <div class="pay_result_warp">
        <div class="result_head">
          <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 48 48"><g fill="none" stroke="#e11837" stroke-linejoin="round" stroke-width="4"><path d="M24 44a19.937 19.937 0 0 0 14.142-5.858A19.937 19.937 0 0 0 44 24a19.938 19.938 0 0 0-5.858-14.142A19.937 19.937 0 0 0 24 4A19.938 19.938 0 0 0 9.858 9.858A19.938 19.938 0 0 0 4 24a19.937 19.937 0 0 0 5.858 14.142A19.938 19.938 0 0 0 24 44Z"/><path stroke-linecap="round" d="m16 24l6 6l12-12"/></g></svg>
          <TranslateText
            source="ko"
            contents="결제가 완료 되었습니다"
          />
          <p>
            <TranslateText
              source="ko"
              contents="결제번호"
            />
            #R1234567819
          </p>
        </div>
        <ul class="result_list">
          <li v-if="false">
            <div class="title">
              <TranslateText
              source="ko"
              contents="주문번호"/>
            </div>
            <div class="con">
              R202311131123446816
            </div> 
          </li>
          <li>
            <div class="title">
              <TranslateText
                source="ko"
                contents="주문내역"
              />
            </div>
            <div class="con">
              <TranslateText
                source="ko"
                contents="스타트업 외 1건"
              />
            </div> 
          </li>
          <li>
            <div class="title">
              <TranslateText
                source="ko"
                contents="결제방법"
              />
            </div>
            <div class="con">
              <TranslateText
                source="ko"
                contents="카드"
              />
            </div> 
          </li>
          <li>
            <div class="title">
              <TranslateText
                source="ko"
                contents="주문금액"
              />
            </div>
            <div class="con">
              351.000원
            </div> 
          </li>
          <li class="total">
            <div class="title">
              <TranslateText
                source="ko"
                contents="결제금액"
              />
            </div>
            <div class="con">
              351.000원
            </div> 
          </li>
        </ul>  
        <div class="btn_warp">
          <button @click="router.push({ path: '/mypage/room' })">
            <span>
              <TranslateText
                source="ko"
                contents="강좌 보러가기"/>
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-6 6l6-6m-6-6l6 6"></path></svg>
          </button>
        </div>

      </div>
    </div>
  </div>
	<include-footer/>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'
import mypage from '../layout/mypage.vue'
import { onMounted } from 'vue';

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer,
		'include-mypage-header': mypage
	}
}
</script>