<script setup>
	import { onMounted, ref, computed } from "vue";
	import { useRouter } from 'vue-router'

	import { useCommonStore } from '@/store/common.pinia';

	import TranslateText from '@/components/common/translate_text.vue';

	import server from '@/api/server';

	const router = useRouter();

	const commonStore = useCommonStore();

	const courseType = computed(() => commonStore.code.filter(v => v.main_code === 'CTGY' && v.sub_code != 0));

	const onGuideOpen = () => {
		window.open('/스타트업클래스_이용가이드.pdf');
	}
</script>

<!-- -->
<template>

<div class="guide_warp">
	<div class="intro_head">
		<div class="red_lable">
			<TranslateText
				source="ko"
				contents="STARTUP CLASS"
			/>
		</div>
		<h2>
			<TranslateText
			source="ko"
			contents="창업에 대한 A부터 Z까지 알려드리는 
			"
			/>
			<strong>
				<TranslateText
				source="ko"
				contents="스타트업 클래스를 만나보세요!"
				/>
			</strong>
		</h2>
	</div>
  <div class="guide_info">
    <div class="red_lable">
			<TranslateText
				source="ko"
				contents="스타트업 클래스 활용가이드 "
			/>
		</div>
    <p>
      <TranslateText
				source="ko"
				contents="스타트업클래스는 창업자에게 필요한 모든 정보를 담았습니다.
      매월 업데이트 되는 신규 강의 부터 창업에관한 A부터 Z까지! 
      지금 바로 확인해보세요."
			/>
    </p>
    <ul>
      <li>
        <b class="red">01</b>
        <TranslateText
				source="ko"
				contents="회원가입후 로그인 "
			/>
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#222" d="M6 22q-.825 0-1.413-.588T4 20V10q0-.825.588-1.413T6 8h1V6q0-2.075 1.463-3.538T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.588 1.413T18 22H6Zm6-5q.825 0 1.413-.588T14 15q0-.825-.588-1.413T12 13q-.825 0-1.413.588T10 15q0 .825.588 1.413T12 17ZM9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6v2Z"/></svg>
      </li>
      <li>
        <b class="red">02</b>
        <TranslateText
				source="ko"
				contents="원하는 강의 탐색 "
			/>
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z"/><path fill="#222" d="M10.5 2a8.5 8.5 0 1 0 5.262 15.176l3.652 3.652a1 1 0 0 0 1.414-1.414l-3.652-3.652A8.5 8.5 0 0 0 10.5 2ZM4 10.5a6.5 6.5 0 1 1 13 0a6.5 6.5 0 0 1-13 0Z"/></g></svg>
      </li>
      <li>
        <b class="red">03</b>
        <TranslateText
				source="ko"
				contents="수강신청 하기"
			/>
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#222" d="M2 5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2h-7v2h3a1 1 0 1 1 0 2H8a1 1 0 1 1 0-2h3v-2H4a2 2 0 0 1-2-2V5zm18 11V5H4v11h16z"/></svg>
      </li>
      <li>
        <b class="red">04</b>
        <TranslateText
				source="ko"
				contents="나의강의실에서 학습"
			  />
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 256 256"><path fill="#222" d="m232.49 55.51l-32-32a12 12 0 0 0-17 0l-96 96A12 12 0 0 0 84 128v32a12 12 0 0 0 12 12h32a12 12 0 0 0 8.49-3.51l96-96a12 12 0 0 0 0-16.98ZM192 49l15 15l-11 11l-15-15Zm-69 99h-15v-15l56-56l15 15Zm105-15.43V208a20 20 0 0 1-20 20H48a20 20 0 0 1-20-20V48a20 20 0 0 1 20-20h75.43a12 12 0 0 1 0 24H52v152h152v-71.43a12 12 0 0 1 24 0Z"/></svg>
      </li>

    </ul>
  </div>
  <div class="class_cate">
    <h3>
      <TranslateText
				source="ko"
				contents="스타트업 클래스 바로가기"
			  />
    </h3>
    <p>
      <TranslateText
				source="ko"
				contents="스타트업 창업자의 희망찬 미래를 위한 첫 걸음
        스타트업 클래스와 함께하세요!"  
			  />
    </p>
    <ul>
			<li
				v-for="(course_type, key) in courseType"
				:key="`course_type-${course_type.id}`"
			>
				<button @click="router.push({ path: `/lecture/${course_type.id}` })">
					<img :src="`/img/main/icon0${(key + 1)}.png`" :alt="`${course_type.name}-${course_type.id}`" />
					<b>
						<TranslateText
							source="ko"
							:contents="course_type.name"
						/>
					</b>
					<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="white" fill-rule="evenodd" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10Zm.47-13.53a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72H8a.75.75 0 0 1 0-1.5h6.19l-1.72-1.72a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd"/></svg>
				</button>
			</li>
    </ul>
  </div>

  <div class="btn_warp">
		<p>
			<TranslateText
			source="ko"
			contents=
			"더 궁금하신 점이 있으신가요? 
      지금 안내가이드를 다운받아보세요"
			/>
		</p>

		<button
			@click="onGuideOpen()"
		>
			<TranslateText
			source="ko"
			contents="이용가이드 보기"
			/>
			<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24"><path fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-6 6l6-6m-6-6l6 6"/></svg>
		</button>	
	</div>
</div>
  
</template>