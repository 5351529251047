<script setup>
import { onMounted, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"

import { useI18n } from 'vue-i18n';

import { helpers } from '@vuelidate/validators'

import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, minValue, maxValue, email, sameAs } from "@/components/utils/ko-vuelidate";

import { useCommonStore } from '@/store/common.pinia';

import TranslateText from '@/components/common/translate_text.vue';

import { env as SERVER_URL } from "@/components/utils/env";

import { isId } from '@/components/utils/common.js';

import server from '@/api/server';

const commonStore = useCommonStore();

const { t } = useI18n();

const formModel = ref({
	user_id:"",
	name:"",
	email: "",
	company: "",
	rank: "",
	tel: "",
	user_pw: "",
	user_pw_confirmation: "",
	id_check: false,
	email_check_id: null,
	email_check: false,
	email_code: '',
	pw_same: false,
	link_lab_yn: 0,
});

const rules = {
	user_id: {
		required,
		isId: helpers.withMessage('소문자 영문, 숫자로만 이루어진 6자리 이상이어야합니다.', isId)
	},
	name: { required },
	email: { required, email: email },
	user_pw: { required },
	user_pw_confirmation: { required, }//, sameAs: sameAs(formModel.value.user_pw) },
};
let v$ = useVuelidate(rules, formModel.value)

const labModel = ref({
	lab_id:"",
	lab_pw: "",
});

const labModal = ref({
	visible: false,
})

const rules2 = {
	lab_id: { required },
	lab_pw: { required },
};
let v2$ = useVuelidate(rules2, labModel.value)

const route = useRoute()
const router = useRouter();

watch(
	() => formModel.value.user_id,
	(nVal, oVal) => {
		// 공백제거
		formModel.value.user_id = formModel.value.user_id.replace(/(\s*)/g, "");

		if(nVal !== oVal) formModel.value.id_check = false;
	}
)

watch(
	() => [formModel.value.user_pw, formModel.value.user_pw_confirmation],([nVal, nVal2], [oVal, oVal2]) => {
		formModel.value.user_pw = formModel.value.user_pw.replace(/(\s*)/g, "");
		formModel.value.user_pw_confirmation = formModel.value.user_pw_confirmation.replace(/(\s*)/g, "");

		if(nVal == nVal2) {
			formModel.value.pw_same = true;
		} else {
			formModel.value.pw_same = false;
		}
	}
);

const openLabPopup = () => {
	labModal.value.visible = !labModal.value.visible;
}

const id_check = async () => {
	try {
		if(formModel.value.id_check) return;

		const id_chk = await v$.value.user_id.$validate();
		if(!id_chk || formModel.value.user_id.length < 6 || formModel.value.user_id == '') {
			toast.fire({
				icon: "error",
				title: "아이디는 소문자 영문, 숫자로만 이루어진 6자리 이상이어야합니다.",
			});
			return false;
		}

		const response = await server
			.get(`/api/public/check/id?user_id=${formModel.value.user_id}`)
			.then((response) => response.data.result);

		if(response.duplicate == 1) {
			toast.fire({
				icon: "error",
				title: "이미 사용중입니다.",
			});

			formModel.value.id_check = false;
		}
		else {
			toast.fire({
				icon: "success",
				title: "사용 가능합니다.",
			});

			formModel.value.id_check = true;
		}
	} catch(e) {
		console.log(e);
		toast.fire({
			icon: "error",
			title: '오류가 발생했습니다.',
		});
	}
};

const email_check = async () => {
	try {
		formModel.value.email_check = false;
		formModel.value.email_check_id = null;

		const email_chk = await v$.value.email.$validate();
		if( !email_chk || formModel.value.email.length < 1 || formModel.value.email == '') {
			toast.fire({
				icon: "error",
				title: "이메일 형식으로 입력해 주세요.",
			});
			return;
		}

		const params = new URLSearchParams();

		params.append('email', formModel.value.email);

		const response = await server({
			url: `/api/auth/email/certification`,
			method: 'POST',
			data: params
		})
		.then((response) => response.data.result);

		console.log(response);

		if(response.status == 400) {
			toast.fire({
				icon: "error",
				title: response.message,
			});
			
			return;
		}

		formModel.value.email_check_id = response.certification;
	} catch(e) {
		console.log(e);
		toast.fire({
			icon: "error",
			title: e.response?.data?.result?.message || '오류가 발생했습니다.',
		});
	}
};

const email_check_confirm = async () => {
	try {
		if(!formModel.value.email_check_id) {
			toast.fire({
				icon: "error",
				title: '오류가 발생했습니다.',
			});

			formModel.value.email_check = false;
			formModel.value.email_check_id = null;

			return;
		}

		const params = new URLSearchParams();
		params.append('certification', formModel.value.email_check_id);
		params.append('code', formModel.value.email_code);

		const response = await server({
			url: `/api/auth/email/certification/result`,
			method: 'POST',
			data: params
		})
		.then((response) => response.data.result);

		formModel.value.email_check_id = null;
		formModel.value.email_check = true;
	} catch(e) {
		toast.fire({
			icon: "error",
			title: e.response?.data?.result?.message || '오류가 발생했습니다.',
		});
	}
}

const join_submit = async () => {
	try {
		await v$.value.user_id.$validate();

		if(!formModel.value.id_check) {
			toast.fire({
				icon: "error",
				title: "아이디 중복확인 해주세요.",
			});
			return;
		}

		await v$.value.email.$validate();

		if(!formModel.value.email_check) {
			toast.fire({
				icon: "error",
				title: "이메일 중복확인 해주세요.",
			});
			return;
		}

		await v$.value.user_pw.$validate();
		await v$.value.user_pw_confirmation.$validate();
		
		if(!formModel.value.pw_same) {
			toast.fire({
				icon: "error",
				title: "비밀번호가 일치하지 않습니다.",
			});
			return;
		}

		const result = await v$.value.$validate();
	//console.log(result);
		if( !result ) {
			toast.fire({
				icon: "error",
				title: "입력 양식에 맞지 않습니다.",
			});
			return;
		}

		const params = new URLSearchParams();
		params.append("user_id", formModel.value.user_id.replace(/(\s*)/g, ""));
		params.append("name", formModel.value.name.replace(/(\s*)/g, ""));
		params.append("email", formModel.value.email.replace(/(\s*)/g, ""));
		params.append("user_pw", formModel.value.user_pw.replace(/(\s*)/g, ""));
		params.append("user_pw_confirmation", formModel.value.user_pw_confirmation.replace(/(\s*)/g, ""));

		await server
			.post(`/api/auth/registration`, params)
			.then((response) => response.data);
			

		router.push({ path: '/member/join_complete', query: {
			name: formModel.value.name.replace(/(\s*)/g, ""),
			user: formModel.value.user_id.replace(/(\s*)/g, ""),
			email: formModel.value.email.replace(/(\s*)/g, ""),
		}})
			
		// login_submit();
	} catch(e) {
		console.log(e);
		toast.fire({
			icon: "error",
			title: '오류가 발생했습니다.',
		});
	}
};

const login_submit = async () => {
	try {
		const params = new URLSearchParams();
		params.append("user_id", formModel.value.user_id);
		params.append("user_pw", formModel.value.user_pw);

		const response = await server
			.post(`/api/auth/login`, params)
			.then((response) => response.data);
			
		

		// toast.fire({
		// 	icon: "success",
		// 	title: "회원가입 완료!",
		// });
	} catch(e) {
		console.log(e);
		toast.fire({
			icon: "error",
			title: '계정 또는 비밀번호가 일치하지 않습니다.',
		});
	}
};

const login_lab = async () => {
	try {
		const result = await v2$.value.$validate();
		//console.log(result);

		if(!result) {
			toast.fire({
				icon: "error",
				title: "입력 양식에 맞지 않습니다.",
			});

			return;
		}

		const params = new URLSearchParams();
		params.append("memuserid", labModel.value.lab_id);
		params.append("mempassword", labModel.value.lab_pw);

		const response = await server
			.post('https://labstartupdsu.kr/api/apiLogin', params)
			.then((response) => response.data);

		console.log(response);
		Swal.fire({
			title: '데이터를 가져오시겠습니까?',
			text: '가져오기',
			icon: 'question',

			showCancelButton: true, // cancel버튼 보이기. 기본은 원래 없음
			cancelButtonText: '취소', // cancel 버튼 텍스트 지정
			cancelButtonColor: '#d33', // cancel 버튼 색깔 지정
			confirmButtonText: '확인', // confirm 버튼 텍스트 지정
			confirmButtonColor: '#3085d6', // confrim 버튼 색깔 지정

			reverseButtons: false, // 버튼 순서 거꾸로

		}).then(async (result) => {
			console.log(result);
		// 만약 Promise리턴을 받으면,
			if (result.isConfirmed) { // 만약 모달창에서 confirm 버튼을 눌렀다면
				formModel.value.user_id = response.message.userid;
				formModel.value.name = response.message.name;
				formModel.value.email = response.message.email;

				toast.fire({
					icon: "success",
					title: "LAB회원정보를 가져왔습니다.",
				});

				// 231011 lab연동 여부 1: 연동완료
				formModel.value.link_lab_yn = 1;
			}
			
			openLabPopup();

		});
	} catch(e) {
		console.log(e);
		toast.fire({
			icon: "error",
			title: '계정 또는 비밀번호가 일치하지 않습니다.',
		});
	}
}

onMounted(async() => {
	
})

</script>
<template>
	<include-header/>

	<div
		class="bg_fix"
		:class="{
			'd-block': labModal.visible,
			'd-none': !labModal.visible
		}"
	></div>
	<div
		class="popup_wrap"
		:class="{
			'd-block': labModal.visible,
			'd-none': !labModal.visible
		}"
	>
		<div class="popup_close">
			<button type="button" @click.stop="openLabPopup">
				<i class="fa fa-times fa-2x" aria-hidden="true"></i>
			</button>
		</div>
		<div class="popup_title">
			<TranslateText
				source="ko"
				contents="LAB 데이터 가져오기"
			/>
		</div>
		<div class="popup_contents_wrap active" id="popup_contents_wrap1">
			<div class="popup_contents_title">
				<img src="https://labstartupdsu.kr/images/logo.png" alt="">
			</div>
			<div class="popup_contents tab_type1">
				<ul class="login_form">
					<li class="inp">
						<input type="text"
							v-model="labModel.lab_id"
							:placeholder="t('placeholder:login_id')"
							required
							:class="!v2$.lab_id.$invalid&&!v2$.lab_id.$error ? 'is-valid' : v2$.lab_id.$invalid&&v2$.lab_id.$error ? 'is-invalid' : ''"
						>
						<span 
							v-for="error in v2$.lab_id.$errors"
							:key="error.$uid"
							class="text-danger"
						>
							{{ error.$message }}
						</span>
					</li>
					<li class="inp">
						<input
							type="password"
							v-model="labModel.lab_pw"
							:placeholder="t('placeholder:login_password')"
							required
							:class="!v2$.lab_pw.$invalid&&!v2$.lab_pw.$error ? 'is-valid' : v2$.lab_pw.$invalid&&v2$.lab_pw.$error ? 'is-invalid' : ''"
						>
						<span 
							v-for="error in v2$.lab_pw.$errors"
							:key="error.$uid"
							class="text-danger"
						>
							{{ error.$message }}
						</span>
					</li>
					<li class="find ta_r">
						<a
							href="https://labstartupdsu.kr/member/find"
							target="_blank"
						>
							<TranslateText
								source="ko"
								contents="아이디/비밀번호 찾기"
							/>
						</a>
					</li>
					<li>
						<router-link
							to="#"
							@click="login_lab();"
							class="bg_red2 btn w100p white"
						>
							<TranslateText
								source="ko"
								contents="로그인"
							/>
						</router-link>
					</li>
				</ul>
			</div>
		</div>
	</div>

	<div id="member_wrap">
		<div class="join_container">
			<div class="join_wrap bg_w ">
				<div class="sub_tit">
					<TranslateText
						source="ko"
						contents="회원가입"
					/>
				</div>
				<ul class="join_info">
					<li>
						<h3>
							<TranslateText
								source="ko"
								contents="아이디"
							/>
							<small class="txt_v">
								*
								<TranslateText
									source="ko"
									contents="필수"
								/>
							</small>
						</h3>
						<div class="con idcon">
							<input
								type="text"
								:placeholder="t('placeholder:form_id')"
								v-model="formModel.user_id"
								required
								@change="formModel.id_check = false"
								:class="!v$.user_id.$invalid&&!v$.user_id.$error ? 'is-valid' : v$.user_id.$invalid&&v$.user_id.$error ? 'is-invalid' : ''"
							/>
							<button
								type="button"
								class="btn_vline"
								style="padding:0;"
								@click="id_check()"
							>
								<TranslateText
									source="ko"
									:contents="formModel.id_check ? '사용가능' : '중복확인'"
								/>
							</button>
						</div>
						<div class="text-left">
							<span 
								v-for="error in v$.user_id.$errors"
								:key="error.$uid"
								class="text-danger"
							>
								{{ error.$message }}
							</span>
						</div>
					</li>
					<li>
						<button
							type="button"
							class="bg_red2 white btn w-100"
							@click="openLabPopup()"
						>
							<TranslateText
								source="ko"
								contents="LAB STARTUP DSU에서 가져오기"
							/>
						</button>
					</li>
				</ul>

				<ul class="join_info">
					<li>
						<h3>
							<TranslateText
								source="ko"
								contents="비밀번호"
							/>
						</h3>
						<div class="con">
							<input
								type="password"
								:placeholder="t('placeholder:form_password')"
								v-model="formModel.user_pw"
								required
								:class="!v$.user_pw.$invalid&&!v$.user_pw.$error ? 'is-valid' : v$.user_pw.$invalid&&v$.user_pw.$error ? 'is-invalid' : ''"
							>
						</div>
						<div class="text-center">
							<span 
								v-for="error in v$.user_pw.$errors"
								:key="error.$uid"
								class="text-danger"
							>
								{{ error.$message }}
							</span>
						</div>
					</li>
					<li>
						<h3>
							<TranslateText
								source="ko"
								contents="비밀번호확인"
							/>
						</h3>
						<div class="con">
							<input
								type="password"
								:placeholder="t('placeholder:form_password_confirm')"
								v-model="formModel.user_pw_confirmation"
								required
								:class="!v$.user_pw_confirmation.$invalid&&!v$.user_pw_confirmation.$error ? 'is-valid' : v$.user_pw_confirmation.$invalid&&v$.user_pw_confirmation.$error ? 'is-invalid' : ''"
							>
						</div>
						<div class="text-center">
							<span 
								v-for="error in v$.user_pw_confirmation.$errors"
								:key="error.$uid"
								class="text-danger"
							>
								{{ error.$message }}
							</span>
						</div>
					</li>
				</ul>

				<ul class="join_info">
					<li>
						<h3>
							<TranslateText
								source="ko"
								contents="이름"
							/>
							<small class="txt_v">
								*
								<TranslateText
									source="ko"
									contents="필수"
								/>
							</small>
						</h3>
						<div class="con">
							<input
								type="text"
								:placeholder="t('placeholder:name')"
								v-model="formModel.name" required
								:class="!v$.name.$invalid&&!v$.name.$error ? 'is-valid' : v$.name.$invalid&&v$.name.$error ? 'is-invalid' : ''"
							>
						</div>
						<div class="text-center">
							<span 
								v-for="error in v$.name.$errors"
								:key="error.$uid"
								class="text-danger"
							>
								{{ error.$message }}
							</span>
						</div>
					</li>
					<li>
						<h3>
							<TranslateText
								source="ko"
								contents="이메일"
							/>
							<small class="txt_v">
								*
								<TranslateText
									source="ko"
									contents="필수"
								/>
							</small>
						</h3>
						<div class="con idcon">
							<input
								type="email"
								:placeholder="t('placeholder:email')"
								v-model="formModel.email"
								required
								@change="formModel.email_check = false"
								:class="!v$.email.$invalid&&!v$.email.$error ? 'is-valid' : v$.email.$invalid&&v$.email.$error ? 'is-invalid' : ''"
							>
							<button
								type="button"
								class="btn_vline"
								style="padding:0;"
								@click="email_check()"
							>
								<TranslateText
									source="ko"
									:contents="formModel.email_check ? '사용가능' : '인증요청'"
								/>
							</button>
						</div>
						<div class="text-center">
							<span 
								v-for="error in v$.email.$errors"
								:key="error.$uid"
								class="text-danger"
							>
								{{ error.$message }}
							</span>
						</div>
					</li>
					<li
						v-if="formModel.email_check_id"
					>
						<h3>
							<TranslateText
								source="ko"
								contents="인증번호"
							/>
						</h3>
						<div class="con idcon">
							<input
								type="text"
								v-model="formModel.email_code"
								:placeholder="t('placeholder:email_confirm')"
							>
							<button
								type="button"
								class="btn_vline"
								style="padding:0;"
								@click="email_check_confirm()"
							>
								<TranslateText
									source="ko"
									contents="확인"
								/>
							</button>
						</div>
					</li>
				</ul>
				<ul class="join_info">
					<li>
						<h3>
							<TranslateText
								source="ko"
								contents="연락처"
							/>
						</h3>
						<div class="con">
							<input
								v-maska data-maska="##0-###0-####"
								data-maska-tokens="0:[0-9]:optional"
								type="text"
								v-model="formModel.tel"
								:placeholder="t('placeholder:form_tel')"
							>
						</div>
					</li>
					<li>
						<h3>
							<TranslateText
								source="ko"
								contents="소속"
							/>
						</h3>
						<div class="con">
							<input
								type="text"
								v-model="formModel.company"
								:placeholder="t('placeholder:company')"
							/>
						</div>
					</li>
					<li>
						<h3>
							<TranslateText
								source="ko"
								contents="직위"
							/>
						</h3>
						<div class="con">
							<input
								type="text"
								v-model="formModel.rank"
								:placeholder="t('placeholder:rank')"
							/>
						</div>
					</li>
				</ul>

				<ul class="join_info">
					<li>
						<button
							class="btn_v"
							@click="join_submit()"
						>
							<TranslateText
								source="ko"
								contents="가입하기"
							/>
						</button>
					</li>
				</ul>
			</div>
		</div>
	</div>

	<include-footer/>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer
	}
}
</script>