<script setup>
	import { ref, computed } from 'vue';
	import { useRouter } from "vue-router"

	import { useAuthStore } from '@/store/auth.pinia';
	import { useCommonStore } from '@/store/common.pinia';

	import { env as SERVER_URL } from '@/components/utils/env';

	import TranslateText from '@/components/common/translate_text.vue';

	import server from '@/api/server';

	const props = defineProps({
		item: Object,
	})

	const router = useRouter();

	const authStore = useAuthStore();
	const commonStore = useCommonStore();

	const language = computed(() => props.item.PaymentDetails[0].Course.CourseDetails?.findIndex(v => v.language === commonStore.lang))

	const onLocation = () => {
		router.push({ path: `/mypage/buy/${props.item.id}` });
	}
</script>

<template>
	<li>
		<button
			class="buy_content"
			title="buy detail location button"
			@click="onLocation"
		>
			<div class="cart_pay_info dp_flex align-center">
				<div class="scround bg_red white">
					<TranslateText
						source="ko"
						contents="결제완료"
					/>
				</div>
				<div class="pay_no">#{{ item.payment_code }}</div>
			</div>
			<div class="cart_detail">
				<div
					class="img_wrap"
					style="height: 100%;"
					:style="{
						'background': `url(${SERVER_URL}${item?.PaymentDetails[0].Course?.Thumbnail?.path})`,
						'background-size': 'cover',
						'background-position': 'center',
						'background-repeat': 'no-repeat',
					}"
				></div>
				<div class="cart_desc">
					<div class="title">
						<TranslateText
							source="ko"
							:contents="item.PaymentDetails[0].Course.CourseDetails[language]?.title || '제목을찾을수없습니다.'"
						/>
					</div>
					<div class="period">
						<TranslateText
							source="ko"
							contents="결제일자"
						/>
							: <span>{{ item.payment_date }}</span>
					</div>
					<div class="price red fw-bold">
						￦ {{ Number(item.price).toLocaleString() }}
					</div>
          
          <span class="arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 12l-6-6m6 6l-6 6m6-6H5"/></svg>
          </span>
				</div>
			</div>
    </button>
	</li>
</template>