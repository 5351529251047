<script setup>
	import { ref, computed } from 'vue';

	const props = defineProps({
		size: {
			type: String,
			default: ''
		},
		pagination: {
			type: Object,
			default: () => Object.assign({
				page: 1,
				next: 0,
				pre: 0,
				pages: []
			})
		},
		rounded: Boolean
	});

	const emit = defineEmits(['click'])
</script>

<template>
	<!--
		페이지네이션
		disabled 앞이나 뒤로가기 안됌
	-->
	<nav aria-label="...">
		<ul class="pagination justify-content-center">
			<li
				class="page-item"
				:class="{
					'disabled': pagination.pre == 0,
				}"
			>
				<button class="page-link" title="pre button">
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m14 7l-5 5l5 5"/></svg>
				</button>
			</li>
			<li
				v-for="(page) in pagination.pages"
				:key="`pagination-${page}`"
				@click="emit('click', page)"
				class="page-item"
				:class="{
					'active': page === pagination.page
				}"
			>
				<button class="page-link">{{ page }}</button>
			</li>
			<li
				class="page-item"
				:class="{
					'disabled': pagination.next == 0,
				}"
			>
				<button class="page-link" title="next button">
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m10 17l5-5l-5-5"/></svg>
				</button>
			</li>
		</ul>
	</nav>
</template>