import axios from 'axios';
import { debounce } from 'lodash';

import { useAuthStore } from '@/store/auth.pinia';

import { env as SERVER_URL } from "@/components/utils/env";


const instance = axios.create({
	baseURL: SERVER_URL,
	timeout: 30000,
	withCredentials: true,
	timeoutErrorMessage: '서버와의 통신이 원할하지 않습니다. 잠시 후 다시 시도해주세요.',
});

instance.testkey = new Date().getTime();
instance.defaults.withCredentials = true;

// 요청 인터셉터
instance.interceptors.request.use(
	(config) => {
		console.log('instance.testkey', instance.testkey);
		loadingOn();

		return config;
	},
	async (error) => {
		console.dir(error);
		loadingOff();

		return Promise.reject(error);
	}
	);

// 응답 인터셉터
instance.interceptors.response.use(
	(response) => {
		loadingOff();

		return response;
	},
	async (error) => {
		const authStore = useAuthStore();
		loadingOff();

		const code = error.response?.status;

		if(code === 401) {
			// await authStore.logout();
			authStore.setUser({});
			location.href = '/member/login';
		}

		return Promise.reject(error);
	}
);

const loadingOn = debounce(() => {
	document.querySelector('.bg_fix').classList.add('d-block');
	document.querySelector('.spinner_wrap').classList.add('on');
}, 100);

const loadingOff = debounce(() => {
	document.querySelector('.bg_fix').classList.remove('d-block');
	document.querySelector('.spinner_wrap').classList.remove('on');
}, 300);

export default instance