//
import { defineStore } from 'pinia'

import server from '@/api/server';

import i18n, {
	setI18nLanguage,
	loadLocaleMessages
} from '@/components/utils/i18n';

export const useCommonStore = defineStore('common', {
  state: () => ({
    code: [],
		lang: 'ko',
  }),
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    async getCode() {
			const response = await server.get(`/api/public/code`)
			this.code = response.data.result;
		},
		async onChangeLang() {
			this.lang = this.lang === 'ko' ? 'en' : 'ko'

			await loadLocaleMessages(this.lang);

			setI18nLanguage(this.lang);
		},
		async i18nInit() {
			const lang = this.lang || 'ko';

			await loadLocaleMessages(lang);

			setI18nLanguage(lang);
		}
  },
	persist: {
		storage: localStorage,
		paths: [
			'code',
			'lang',
		]
	}
})