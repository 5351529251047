<script setup>
	import { ref, computed, onMounted } from 'vue';
	import { useRouter, useRoute } from 'vue-router';	

	import { useAuthStore } from '@/store/auth.pinia';
	import { useCommonStore } from '@/store/common.pinia';

	import { env as SERVER_URL } from '@/components/utils/env';
	import TranslateText from '@/components/common/translate_text.vue';

	import server from '@/api/server';

	const props = defineProps({
		item: Object
	})

	const emit = defineEmits(['change'])

	const router = useRouter();
	const routes = useRoute();

	const authStore = useAuthStore();
	const commonStore = useCommonStore();

	const is_login = computed(() => !!authStore.user?.name);
	const language = computed(() => props.item.CourseDetails?.findIndex(v => v.language === commonStore.lang))

	const watched_time = ref(0);
	const total_time = ref(0);

	const lecture_see = (id) => {
		router.push(`/lecture/see/${id}`)
	}

	onMounted(() => {
		console.log(props.item);
		// CourseProgress
		watched_time.value = props.item.CourseProgress ? props.item.CourseProgress?.progress : 0;
		/**
		 * 영문 동영상은 없으니 무조건 한글 동영상 길이 참조
		 */
		total_time.value = props.item.CourseSyllabuses?.reduce((acc, cur) => acc += Number((cur.CourseSyllabusDetails && cur.CourseSyllabusDetails[0]?.Video?.duration) || 0), 0) || 0;
	})
</script>

<template>
	<li>
		<div class="classes_img">
			<div
				class="img_wrap"
				style="
					height: 100%;
				"
				:style="{
					'background': `url(${SERVER_URL}${item.Thumbnail?.path})`,
					'background-size': 'cover',
					'background-position': 'center',
					'background-repeat': 'no-repeat',
				}"
			>
			</div>

			<!-- <div class="labels">
				NEW
			</div>
			<div class="heart">
				<i 
					class="fa fa-2x" aria-hidden="true"
					:class="likes.includes(item.id)?'fa-heart red':'fa-heart-o'"
				></i>
			</div> -->
		</div>
		<div class="classes_desc">
			<div class="classes_title">
				<router-link :to="`/lecture/view/${item.id}`">
					<TranslateText
						source="ko"
						:contents="Array.isArray(item?.CourseDetails) && (item.CourseDetails[language]?.title || '제목을 입력하세요.')"
					/>
				</router-link>
			</div>
			<div class="classes_myroom">
				<div class="classes_period">
					{{item.complete_syllabus_cnt}} / {{item.total_syllabus_cnt}}
					<TranslateText
						source="ko"
						contents="강의"
					/>
				</div>
				<div class="classes_process">
					<!-- {{ ((watched_time_arr[idx]/total_time_arr[idx])*100).toFixed(1) }} % 완료 -->
					{{ item.progress_ratio || 0 }}%
					<TranslateText
						source="ko"
						contents="완료"
					/>
					
				</div>
			</div>
			<div class="progressbar">
				<span :style="`width:${item.progress_ratio || 0}%`"></span>
			</div>
			<button type="button" class="classes_play" @click="lecture_see(item.id)">
				<i class="fa fa-play-circle" aria-hidden="true"></i>
				<span>
					<TranslateText
						source="ko"
						contents="강의듣기"
					/>
				</span>
			</button>
		</div>
	</li>
</template>