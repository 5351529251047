<script setup>
import { ref, watch, onMounted } from 'vue';
import { createTemplatePromise } from '@vueuse/core'

import { useI18n } from 'vue-i18n';

import { useCommonStore } from '@/store/common.pinia';

import { papagoWorker } from '@/worker/papago';

const props = defineProps({
	contents: String,
	source: String,
	target: String,
})

const { t, locale } = useI18n();

const commonStore = useCommonStore();

const TemplatePromise = createTemplatePromise({
  transition: {
    name: 'fade',
    appear: true,
  },
})

const title = ref(null);
const btn = ref(null);

watch(
	() => props.contents,
	(nVal) => {
		if(!nVal) return;

		init();
	}
)

watch(
	() => locale.value,
	(nVal) => {
		if(!nVal) return;

		init();
	}
)

const asyncFn = async () => {
	if(!props.contents) return;
	if(props.contents.indexOf('NaN') > -1) return;
	if(props.contents === 'undefined') return;

  const result = await papagoWorker(props.source, commonStore.lang, props.contents);

	return result;
}

/** 알아서 돌고있음 */
const init = async () => {
	try {
		if(!props.contents) return;
		if(props.contents.indexOf('NaN') > -1) return;
		if(props.contents === 'undefined') return;

		/**
		 * i18n에서 먼저 검사
		 * 해당하는 문자가 있으면, 그거 처리
		 * 없으면 번역
		 */
		let result = t(props.contents);

		if((result === props.contents) && (commonStore.lang !== props.source)) {
			result = await TemplatePromise.start(`${props.contents}`);
		}

		title.value = ((result === 'undefined' || !result) ? 'Translated...' : result);
	} catch(e) {
		title.value = props.contents || '';
		console.error(e);
	}
	
}

onMounted(() => {
	init();
})
</script>

<template>
	<span v-html="String((title || contents) || '').replaceAll('\n', '<br>').trim()"></span><TemplatePromise v-slot="{ resolve }"><div>{{ resolve(asyncFn()) }}</div></TemplatePromise>
</template>
