<script setup>
import { env as SERVER_URL } from "@/components/utils/env";
import { onMounted, ref, watch, reactive, nextTick } from "vue";
import { useRoute } from "vue-router";

import Artplayer from 'artplayer';
import artplayerPluginControl from 'artplayer-plugin-control'

const route = useRoute()

let container = '';
let art = '';

onMounted(async() => {
	nextTick(() => {
		art = new Artplayer({
		//url: `${SERVER_URL}/files/video/%EA%B8%B0%EC%97%85%EA%B0%80%EC%A0%95%EC%8B%A0%20ASK_4-3_E.mp4`,
		url: `${SERVER_URL}/videos/1693974349834_SoqfygGklj.mp4`,
		//poster: '/assets/sample/poster.jpg',
		hotkey: true,
		volume: 0.5,
		isLive: false,
		muted: false,
		autoplay: false,
		pip: true,
		autoSize: true,
		autoMini: true,
		screenshot: true,
		setting: true,
		loop: true,
		flip: true,
		playbackRate: true,
		aspectRatio: true,
		fullscreen: true,
		fullscreenWeb: true,
		subtitleOffset: true,
		miniProgressBar: true,
		mutex: true,
		backdrop: true,
		playsInline: true,
		autoPlayback: true,
		airplay: true,
		theme: '#E21838',
		lang: navigator.language.toLowerCase(),
		moreVideoAttr: {
			crossOrigin: 'anonymous',
		},
		i18n: {
			'ko-kr': {
				Play: '재생',
				Pause: '일시정지',
			},
		},
		plugins: [
			artplayerPluginControl()
		],
		id: 'artplayer-app',
		container: container,//player.value,
		controls: [
			{
				position: 'right',
				html: '<button type="button">Switch UI</button>',
				click: function () {
					const { enable } = art.plugins.artplayerPluginControl;
					art.plugins.artplayerPluginControl.enable = !enable;
				},
			},
		],
		});

		art.on('ready', () => {
		console.info(art.duration); // 1531.6333초
			//art.seek = 35;
			//console.info(art.currentTime);
			art.notice.show = '영상이 준비가 되었습니다.';
		});

		art.on('pause', () => {
			console.info('pause', art.currentTime);
		});

		art.on('play', () => {
			console.info('play', art.currentTime);
		});

		art.on('seek', (currentTime) => {
			console.info('seek', currentTime);
		});
	})
})
</script>

<template>

	<div class="wrapper">
		<include-header/>

		<div class="page-wrapper">

			<div class="page-body">
				<div class="container-fluid d-flex flex-column justify-content-center">
					<div class="artplayer-app"></div>

				</div>
			</div>

			<include-footer/>
		</div>
	</div>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer,
	}
}
</script>