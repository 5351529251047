<script setup>
import { sns } from "@/components/utils/sns";

import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useI18n } from 'vue-i18n';

import useVuelidate from "@vuelidate/core";

import { useCommonStore } from '@/store/common.pinia';

import {
	required, minLength, maxLength, minValue, maxValue, email, sameAs
} from "@/components/utils/ko-vuelidate";

import TranslateText from '@/components/common/translate_text.vue';

import server from '@/api/server';

const rules = {
	name: { required },
	email: { required, email },
}

const { t } = useI18n();

const route = useRoute();
const router = useRouter();

const commonStore = useCommonStore();

const article = ref({})
const formModel = ref({
	name:"",
	email: "",
	company: "",
	rank: "",
	tel: "",
	user_pw: "",
	user_pw_confirmation: "",
	email_check: true,
});

const v$ = useVuelidate(rules, formModel)

const isSame = computed(() => formModel.value.user_pw === user_pw_confirmation);

// 네이버 로그인 설정
const naverClientId = sns.naver_auth;
const callbackUrl = sns.callback_url;
const states = sns.naver_states;
const googleClientId = sns.google_auth;
//8bGxdcq6am

// 카카오 로그인 설정
if (!Kakao.isInitialized()) {
	Kakao.init(sns.kakao_auth); // 사용하려는 앱의 JavaScript 키 입력
};

const getSingleArticle = async () => {
	let response = await server.get(`/api/v1/student`)

	article.value = response.data.result;
	formModel.value = Object.assign({
		...formModel.value
	}, {
		...response.data.result,
		email_check: !!response.data.result.email
	});
}


const email_check = async () => {
	formModel.value.email_check = false;
	const email_chk = await v$.value.email.$validate();
	
	if( !email_chk || formModel.value.email.length < 1 || formModel.value.email == '') {
		toast.fire({
			icon: "error",
			title: "이메일 형식으로 입력해 주세요.",
		});
		return;
	}

	await server
	.get(`/api/public/check/email?email=${formModel.value.email}`)
	.then((response) => {
		if(response.data.result.duplicate == 1) {
			toast.fire({
				icon: "error",
				title: "이미 사용중입니다.",
			});
			formModel.value.email_check = false;
		} else {
			toast.fire({
				icon: "success",
				title: "사용 가능합니다.",
			});

			formModel.value.email_check = true;
		}
	})
	.catch((error) => {
		console.log(error);
	});
};

const myinfo_submit = async () => {
	await v$.value.email.$validate();
	if(!formModel.value.email_check) {
		toast.fire({
			icon: "error",
			title: "이메일 중복확인 해주세요.",
		});
		return;
	}

	const result = await v$.value.$validate();

	//console.log(result);
	if( !result ) {
		toast.fire({
			icon: "error",
			title: "입력 양식에 맞지 않습니다.",
		});
		return;
	}

	const params = new URLSearchParams();
	params.append("name", formModel.value.name);
	params.append("email", formModel.value.email);
	params.append("tel", formModel.value.tel || '');
	params.append("company", formModel.value.company || '');
	params.append("rank", formModel.value.rank || '');
	
	await server
	.put(`/api/v1/student`, params)
	.then((response) => {
		if(response.data.result.message == 'OK') {
			toast.fire({
				icon: "success",
				title: "회원정보수정 완료!",
			});
		} else {
			toast.fire({
				icon: "error",
				title: "회원가입 실패",
			});
		}

		getSingleArticle();
	})
	.catch((error) => {
		console.log(error);
		toast.fire({
			icon: "error",
			title: '중복된 정보가 있습니다.',
		});
	});
};

// 네이버 로그인을 위한 url 이동 
const naver_login = () => {
	if(article.value?.naver_reg_date) {
		console.log('네이버 가입했네');
	} else {
		const url = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${naverClientId}&redirect_uri=${callbackUrl}&state=${states}`;
		window.location.href=url;
	}
};

// 카카오 로그인을 위한 url 이동 
const kakao_login = () => {
	if(article.value?.kakao_reg_date) {
		console.log('카카오 가입했네');
	} else {
		Kakao.Auth.authorize({
			redirectUri: sns.callback_url,
		});
	}
};

// 구글 로그인을 위한 url 이동 
const google_login = () => {
	if(article.value?.google_reg_date) {
		console.log('구글 가입했네');
	} else {
		const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${googleClientId}&redirect_uri=${callbackUrl}&response_type=code&scope=email profile`;
		window.location.href=url;
	}
};

onMounted(async() => {
	getSingleArticle();
})
</script>
<template>
	<include-header/>

	<div id="sub_wrap">
		<div class="inner_wrap">

			<include-mypage-header/>

			<div class="my_info_wrap">
				<div v-if="false" class="my_info_head">
					<div class="my_info_profile">
						<div class="img_wrap">
							<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 48 48"><path fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M24 20a7 7 0 1 0 0-14a7 7 0 0 0 0 14ZM6 40.8V42h36v-1.2c0-4.48 0-6.72-.872-8.432a8 8 0 0 0-3.496-3.496C35.92 28 33.68 28 29.2 28H18.8c-4.48 0-6.72 0-8.432.872a8 8 0 0 0-3.496 3.496C6 34.08 6 36.32 6 40.8Z"/></svg>
							<button type="button" class="bg-secondary border" aria-hidden="true" title="profile button">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#fff" d="m9.25 22l-.4-3.2q-.325-.125-.613-.3t-.562-.375L4.7 19.375l-2.75-4.75l2.575-1.95Q4.5 12.5 4.5 12.337v-.674q0-.163.025-.338L1.95 9.375l2.75-4.75l2.975 1.25q.275-.2.575-.375t.6-.3l.4-3.2h5.5l.4 3.2q.325.125.613.3t.562.375l2.975-1.25l2.75 4.75l-2.575 1.95q.025.175.025.338v.674q0 .163-.05.338l2.575 1.95l-2.75 4.75l-2.95-1.25q-.275.2-.575.375t-.6.3l-.4 3.2h-5.5Zm2.8-6.5q1.45 0 2.475-1.025T15.55 12q0-1.45-1.025-2.475T12.05 8.5q-1.475 0-2.488 1.025T8.55 12q0 1.45 1.012 2.475T12.05 15.5Z"/></svg>
							</button>
						</div>
					</div>
					<!--
					<div class="my_info_welcome">
						<TranslateText
							source="ko"
							contents="안녕하세요!"
						/>
					</div>
					-->
				</div>
				<div class="my_info_body">
					<div class="title">
						<TranslateText
							source="ko"
							contents="프로필정보"
						/>
					</div>
					<hr>
					<form id="my_info_form" action="#">
						<ul>
							<li v-if="false">
								<div class="field_label">
									<span>
										<TranslateText
											source="ko"
											contents="SNS연동"
										/>
									</span>
								</div>
								<div class="field_input">
									<div class="sns_group m-0">
										<!-- <button v-if="article?.kakao_reg_date" type="button" :class="article?.kakao_reg_date ? 'on' : ''" class="snsbox snsbox_k col me-2">
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16"><path fill="#666" d="M8 1c4.418 0 8 2.91 8 6.5S12.418 14 8 14c-.424 0-.841-.027-1.247-.079c-1.718 1.718-3.77 2.027-5.753 2.072v-.421c1.071-.525 2-1.48 2-2.572a3.01 3.01 0 0 0-.034-.448C1.157 11.36 0 9.54 0 7.5C0 3.91 3.582 1 8 1z"/></svg>
											<span class="border-start ps-2 ms-2 fs-6 fw-normal">
												KAKAO
											</span>
										</button>
										<button v-if="article?.naver_reg_date" type="button" :class="article?.naver_reg_date ? 'on' : ''" class="snsbox snsbox_n col me-2">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="" d="M16.273 12.845L7.376 0H0v24h7.726V11.156L16.624 24H24V0h-7.727v12.845Z"/></svg>
											<span class="border-start ps-2 ms-2   fs-6 fw-normal">
												NAVER
											</span>
										</button>
										<button v-if="article?.google_reg_date" type="button" :class="article?.google_reg_date ? 'on' : ''" class="snsbox snsbox_g col">
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="" d="M6 12a6 6 0 0 0 11.659 2H12v-4h9.805v4H21.8c-.927 4.564-4.962 8-9.8 8c-5.523 0-10-4.477-10-10S6.477 2 12 2a9.99 9.99 0 0 1 8.282 4.393l-3.278 2.295A6 6 0 0 0 6 12Z"/></svg>
											<span class="border-start ps-2 ms-2 fs-6 fw-normal">
												GOOGLE
											</span>
										</button> -->

										<!-- <button type="button" @click="kakao_login()" :class="article?.kakao_reg_date ? 'on' : ''" class="snsbox snsbox_k col me-2">
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16"><path fill="#666" d="M8 1c4.418 0 8 2.91 8 6.5S12.418 14 8 14c-.424 0-.841-.027-1.247-.079c-1.718 1.718-3.77 2.027-5.753 2.072v-.421c1.071-.525 2-1.48 2-2.572a3.01 3.01 0 0 0-.034-.448C1.157 11.36 0 9.54 0 7.5C0 3.91 3.582 1 8 1z"/></svg>
											<span class="border-start ps-2 ms-2 fs-6 fw-normal">
												KAKAO
											</span>
										</button>
										<button type="button" @click="naver_login()" :class="article?.naver_reg_date ? 'on' : ''" class="snsbox snsbox_n col me-2">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="" d="M16.273 12.845L7.376 0H0v24h7.726V11.156L16.624 24H24V0h-7.727v12.845Z"/></svg>
											<span class="border-start ps-2 ms-2   fs-6 fw-normal">
												NAVER
											</span>
										</button>
										<button type="button" @click="google_login()" :class="article?.google_reg_date ? 'on' : ''" class="snsbox snsbox_g col">
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="" d="M6 12a6 6 0 0 0 11.659 2H12v-4h9.805v4H21.8c-.927 4.564-4.962 8-9.8 8c-5.523 0-10-4.477-10-10S6.477 2 12 2a9.99 9.99 0 0 1 8.282 4.393l-3.278 2.295A6 6 0 0 0 6 12Z"/></svg>
											<span class="border-start ps-2 ms-2 fs-6 fw-normal">
												GOOGLE
											</span>
										</button> -->
									</div>
								</div>
							</li>
							<li>
								<div class="field_label">
									<label for="email">
										<TranslateText
											source="ko"
											contents="이메일"
										/>
									</label>
									<span class="red ms-1"><sup>*</sup>
										<TranslateText
											source="ko"
											contents="필수"
										/>
									</span>
								</div>
								<div class="field_input">
									<input
										type="email"
										v-model="formModel.email"
										:readonly="formModel.email_check"
										:placeholder="t('placeholder:email')"
										id="email"
										name="email"
										@change="formModel.email_check = false"
										:class=" {
											'is-valid': !v$.email.$invalid && !v$.email.$error,
											'is-invalid': v$.email.$invalid && v$.email.$error
										}"
									/>
									<!--
									<button type="button" @click="email_check()">중복확인</button>
									-->
									<div class="text-left">
										<span 
											v-for="error in v$.email.$errors"
											:key="error.$uid"
											class="text-danger"
										>
											{{ error.$message }}
										</span>
									</div>
								</div>
							</li>
							<li>
								<div class="field_label">
									<label for="name">
										<TranslateText
											source="ko"
											contents="이름"
										/>
									</label>
									<span class="red ms-1">
										<sup>*</sup>
										<TranslateText
											source="ko"
											contents="필수"
										/>
									</span>
								</div>
								<div class="field_input ">
									<input
										type="text"
										v-model="formModel.name"
										:placeholder="t('placeholder:name')"
										id="name"
										name="name"
										:class=" {
											'is-valid': !v$.name.$invalid && !v$.name.$error,
											'is-invalid': v$.name.$invalid && v$.name.$error
										}"
									>
									<div class="text-left">
										<span 
											v-for="error in v$.name.$errors"
											:key="error.$uid"
											class="text-danger"
										>
											{{ error.$message }}
										</span>
									</div>
								</div>
							</li>
							<li v-if="!article?.google_reg_date && !article?.naver_reg_date && !article?.kakao_reg_date">
								<div class="field_label">
									<span>
										<TranslateText
											source="ko"
											contents="비밀번호"
										/>
									</span>
								</div>
								<div class="field_input">
									<button type="button" class="border-success text-success bg-success text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
										<TranslateText
											source="ko"
											contents="비밀번호 수정하기"
										/>
									</button>
								</div>
								<!--
								<div class="field_input">
									<input type="password" placeholder="영문,숫자,특수문자 8~15자 이내">
								</div>
							</li>
							<li>
								<div class="field_label">
									<label for="text1">비밀번호 확인</label>
								</div>
								<div class="field_input">
									<input type="password" placeholder="확인을 위해 한번 더 입력해주세요">
								</div>
								-->
							</li>
							<li>
								<div class="field_label">
									<label for="tel">
										<TranslateText
											source="ko"
											contents="연락처"
										/>
									</label>
								</div>
								<div class="field_input ">
									<input
										v-model="formModel.tel"
										id="tel"
										name="tel"
										v-maska data-maska="##0-###0-####"
										data-maska-tokens="0:[0-9]:optional"
										type="text"
										:placeholder="t('placeholder:tel')"
									/>
								</div>
							</li>
							<li>
								<div class="field_label">
									<label for="company">
										<TranslateText
											source="ko"
											contents="소속"
										/>
									</label>
								</div>
								<div class="field_input ">
									<input
										v-model="formModel.company"
										id="company"
										name="company"
										type="text"
										:placeholder="t('placeholder:company')"
									/>
								</div>
							</li>
							<li>
								<div class="field_label">
									<label for="rank">
										<TranslateText
											source="ko"
											contents="직위"
										/>
									</label>
								</div>
								<div class="field_input">
									<input
										v-model="formModel.rank"
										id="rank"
										name="rank"
										type="text"
										:placeholder="t('placeholder:rank')"
									/>
								</div>
							</li>
							<!-- <li>
								<div class="field_label">
									<label for="text1">수신동의</label>
								</div>
								<div class="field_input half">
									<input type="checkbox">
									<span>e-mail 수신동의</span>
								</div>
							</li> -->
							<li class="border-bottom pb-3 mb-3">
							
							</li>
							<!-- <li>
								<div class="field_label">
									<TranslateText
										source="ko"
										contents="회원탈퇴"
									/>
								</div>
								<div class="field_input">
									<button type="button" class="">
										<TranslateText
											source="ko"
											contents="회원탈퇴"
										/>
									</button>
								</div>
							</li> -->
						</ul>
					</form>
				</div>

				<div class="bottom_btns">
					<router-link to="#">
						<TranslateText
							source="ko"
							contents="취소"
						/>
					</router-link>
					<router-link to="#" class="submit" @click="myinfo_submit()">
						<TranslateText
							source="ko"
							contents="확인"
						/>
					</router-link>
				</div>
			</div>

		</div>

	<!--비밀번호 변경-->
	<div class="modal fade" id="exampleModal" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						<TranslateText
							source="ko"
							contents="비밀번호 변경"
						/>
					</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body py-2  py-lg-3 px-lg-5 ">
					<h6 class="my-3 text-center">
						<p class="py-2">
							<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#666" d="M9 8h6V6q0-1.25-.875-2.125T12 3q-1.25 0-2.125.875T9 6v2Zm9 15q-2.075 0-3.538-1.463T13 18q0-2.075 1.463-3.538T18 13q2.075 0 3.538 1.463T23 18q0 2.075-1.463 3.538T18 23Zm-5.75-1H6q-.825 0-1.413-.588T4 20V10q0-.825.588-1.413T6 8h1V6q0-2.075 1.463-3.538T12 1q2.075 0 3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v1.3q-.5-.175-1-.238T18 11q-2.925 0-4.963 2.038T11 18q0 1.075.338 2.087T12.25 22ZM18 18q.625 0 1.063-.438T19.5 16.5q0-.625-.438-1.063T18 15q-.625 0-1.063.438T16.5 16.5q0 .625.438 1.063T18 18Zm0 3q.75 0 1.4-.35t1.075-.975q-.575-.35-1.2-.513T18 19q-.65 0-1.275.162t-1.2.513q.425.625 1.075.975T18 21Z"/></svg>
						</p>
						<TranslateText
							source="ko"
							contents="비밀번호는 영문,숫자,특수문자 8~15자 이내로 등록해주세요."
						/>
					</h6>
					<hr class="my-lg-4  my-3 ">
					<ul>
						<li class="mb-4">
							<div class="field_label">
								<label for="password">
									<TranslateText
										source="ko"
										contents="현재 비밀번호"
									/>
								</label>
							</div>
							<div class="field_input ">
								<input
									type="password"
									id="password"
									name="password"
									:placeholder="t('placeholder:password')"
									class="w-100"
								/>
							</div>
						</li>
						<li class="mb-2">
							<div class="field_label">
								<label for="new_password">
									<TranslateText
										source="ko"
										contents="신규 비밀번호"
									/>
								</label>
							</div>
							<div class="field_input ">
								<input
									type="password"
									id="new_password"
									name="new_password"
									:placeholder="t('placeholder:new_password')"
									class="w-100"	
								/>
							</div>
						</li>
						<li class="mb-4">
							<div class="field_label">
								<label for="new_password_confirm">
									<TranslateText
										source="ko"
										contents="신규 비밀번호 확인"
									/>
								</label>
							</div>
							<div class="field_input ">
									<input
										type="password"
										id="new_password_confirm"
										name="new_password_confirm"
										:placeholder="t('placeholder:new_password_confirm')"
										class="w-100"
									/>
							</div>
						</li>
					</ul>
				</div>
				<div class="modal-footer d-flex justify-content-center py-4">
					<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
						<TranslateText
							source="ko"
							contents="취소"
						/>
					</button>
					<button type="button" class="btn bg_red white">
						<TranslateText
							source="ko"
							contents="저장"
						/>
					</button>
				</div>
			</div>
		</div>
	</div>

	</div><!-- //#sub_wrap -->

	<include-footer />
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'
import mypage from '../layout/mypage.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer,
		'include-mypage-header': mypage
	}
}
</script>