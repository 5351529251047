<script setup>
import { onMounted, ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Icon } from '@iconify/vue';

import { useI18n } from 'vue-i18n';

import { cloneDeep } from 'lodash';

import { useAuthStore } from '@/store/auth.pinia';
import { useCommonStore } from '@/store/common.pinia';

import Pagination from '@/components/common/pagination';

import { env as SERVER_URL } from '@/components/utils/env';
import TranslateText from '@/components/common/translate_text.vue';

import server from '@/api/server';

const route = useRoute()

const { t } = useI18n();

const authStore = useAuthStore();
const commonStore = useCommonStore();

const is_login = computed(() => !!authStore.user?.name);

let searchModel = ref({
	type_id: ''
})

const fileItem = ref({});

const props = defineProps({
	category:{
		type:String,
		default:''
	}
})

const tabNum = ref(1)

const faqId = ref(0)

if(route.query.id) {
	faqId.value = route.query.id;
}

const articles = ref([])
const pagination = ref({
	limit: 20,
	next: 0,
	pre: 0,
	page: 1,
	pages: [],
})

const qatys = computed(() => commonStore.code.filter(v => v.main_code === 'QATY' && v.sub_code != 0));

watch(
	() => [route.path],
	(nVal, oVal) => {
		console.log('watch!');
		getBoard()
		// list_script();
	}
);

const getBoard = async (page = 1) => {
	try {
		pagination.value.page = page;
		
		const params = new URLSearchParams();
		if(searchModel.value != undefined) {
			Object.keys(searchModel.value).map(v => {
				params.append(v, searchModel.value[v]);
			});
		}

		params.append('limit', pagination.value.limit);
		params.append('page', pagination.value.page);

		let url = '';
		
		if(props.category=='notice'||route.path=='/board/notice') {
			tabNum.value = 1;

			url = `/api/public/notice`;
			
		} else if(props.category=='faq'||route.path=='/board/faq'){
			tabNum.value = 2;

			url = `/api/public/faq`;
		} else if(props.category=='qna'||route.path.indexOf('/board/qna') != -1) {
			tabNum.value = 3;

			url = `/api/v1/board/qa`;
		}

		const response = await server({
			url,
			method: 'GET',
			params
		})
		.then(res => res.data.result);

		articles.value = response;
		pagination.value = Object.assign({}, {
			...pagination.value,
			...response.pages
		});
	} catch(e) {
		console.log(e);
	}
}

const showThumb = (file) => {
	fileItem.value = cloneDeep(file);
}

const list_script = (item) => {
	const idx = articles.value.rows.findIndex(v => v.id == item.id);

	console.log(idx);

	articles.value.rows[idx].open = !item.open;
	
	// let aa = document.querySelectorAll('.article_list_body.faq li > a');
	// if(aa.length > 0) {
	// 	aa.forEach(function (item) {
	// 		item.classList.toggle('on');
			
	// 		// item.addEventListener('click', function(e) {
	// 		// 	e.preventDefault();
	// 		// 	item.classList.toggle('on');
	// 		// })
	// 	});
	// }

	// let bb = document.querySelectorAll('.article_list_body.req11 li > a');
	// if(bb.length > 0) {
	// 	bb.forEach(function (item) {
	// 		item.addEventListener('click', function(e) {
	// 			e.preventDefault();
	// 			item.classList.toggle('on');
	// 		})
	// 	});
	// }

	if( route.query.id ) {
		console.log( document.querySelector(`.article_list_body.faq li > a.faq${route.query.id}`).click() );
	}
}


onMounted(async() => {
	getBoard();
	// list_script();
})
</script>
<template>
	<include-header/>

	<div id="sub_wrap">
		<div class="inner_wrap">
			<div class="class_head_top sub_page_top">
				<div class="title">
					<TranslateText
						source="ko"
						contents="커뮤니티"
					/>
				</div>
			</div>
			<include-board-header/>

			<p class="sub_menu_text">
				<span v-if="tabNum=='1'">
					<TranslateText
						source="ko"
						contents="DSU 동서대 창업 온라인 강좌와 관련된 공지사항을 알려드립니다."
					/>
				</span>
				<span v-if="tabNum=='2'">
					<TranslateText
						source="ko"
						contents="궁금한 점이 있으신가요? 아래의 자주묻는 질문 목록을 확인 해주세요!"
					/>
				</span>
				<span v-if="tabNum=='3'">
					<TranslateText
						source="ko"
						contents="DSU 동서대 창업 온라인 강좌서비스 내 문의나 요청사항을 보내주시면 처리 후 안내해드리겠습니다."
					/>
				</span>
			</p>

<!-- //공지사항 -->
			<div v-if="tabNum=='1'" class="board_list_wrap">
				<div class="board_list_top has_float">
					<form class="search_form search_wrap has_float m_fl_1f1" action="#" @submit.prevent="getBoard()">
						<!-- <select v-model="searchModel.search_subject">
							<option value="">전체</option>
						</select> -->
						<input
							type="text"
							aria-label="search_word"
							id="search_word"
							name="search_word"
							v-model="searchModel.search_subject"
							:placeholder="t('placeholder:search')"
						/>
						<button
							type="button"
							@click="getBoard()"
							class="btn bg_red white"
						>
							<TranslateText
								source="ko"
								contents="검색"
							/>
						</button>
					</form>
					<div class="board_count">
						<i class="fa fa-list-ul" aria-hidden="true"></i>
						Total
						<span class="red">
							{{ articles.count }}
						</span>
						<span> / </span>
						{{ articles?.pages?.pages }}Page
					</div>
					
				</div>

				<div class="article_list_body" :class="`${props.category}`">
					<ul class="article_head articles">
						<li>
							<div class="no">NO</div>
							<div class="subject">
								<TranslateText
									source="ko"
									contents="제목"
								/>
							</div>
							<div class="attach_file">
								<TranslateText
									source="ko"
									contents="파일"
								/>
							</div>
							<div class="date">
								<TranslateText
									source="ko"
									contents="등록일"
								/>
							</div>
							<div class="count">
								<TranslateText
									source="ko"
									contents="조회"
								/>
							</div>
						</li>
					</ul>
					<ul v-if="articles.count>0" class="article_body articles mb-lg-5 mb-3">
						<li v-for="(item, key) in articles.rows" :key="item.id">
							<router-link :to="`/board/${props.category}/${item.id}`">
								<div class="no">{{ key + 1 }}</div>
								<div class="subject ta_l">
									<TranslateText
										source="ko"
										:contents="item.subject"
									/>
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" class=" ms-1"><path fill="#e11837 " d="M9 7v10h2v-5l2 5h2V7h-2v5l-2-5H9M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2Z"/></svg>
								</div>
								<div class="attach_file">
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#666" d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m4 18H6V4h7v5h5v11Z"/></svg>
								</div>
								<div class="date">{{ item.createdAt.substr(0,10) }}</div>
								<div class="count">5</div>
							</router-link>
						</li>
					</ul>
					<div v-else class="ta_c">
						<p class="pt-5 pb-2">
						<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#ccc" d="M12 17q.425 0 .713-.288T13 16q0-.425-.288-.713T12 15q-.425 0-.713.288T11 16q0 .425.288.713T12 17Zm-1-4h2V7h-2v6Zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"/></svg>
						</p>
						<TranslateText
							source="ko"
							contents="등록된 데이터가 없습니다."
						/>
					</div>

					<!-- // pagination -->
				</div>

			</div>

<!-- //자주 묻는 질문-->
			<div v-if="tabNum=='2'" class="board_list_wrap">
				<div class="board_list_top has_float">
					<div v-if="is_login" class="board_btn">
						<router-link to="/board/qna/form">
							<TranslateText
								source="ko"
								contents="1:1 문의 등록"
							/>
						</router-link>
					</div>
					<form class="search_form search_wrap only1" action="#" @submit.prevent="getBoard()">
						<select
							v-model="searchModel.type_id"
							id="type_id"
							name="type_id"
							aria-label="type_id"
							@change="getBoard()"
						>
							<option value="">
								<TranslateText
									source="ko"
									contents="전체"
								/>
							</option>
							<option
								v-for="item in qatys"
								:key="`qaty-${item.id}`"
								:value="item.id"
							>
								<TranslateText
									source="ko"
									:contents="item.name"
								/>
							</option>
						</select>
					</form>
				</div>

				<div class="article_list_body faq">
					<ul class="article_head articles">
						<li>
							<div class="qna_label"></div>
							<div class="type">
								<TranslateText
									source="ko"
									contents="유형"
								/>
							</div>
							<div class="subject_faq">
								<TranslateText
									source="ko"
									contents="제목"
								/>
							</div>
						</li>
					</ul>
					<ul v-if="articles.count > 0" class="article_body articles mb-lg-5 mb-3">
						<li
							v-for="item in articles.rows"
							:key="item.id"
							style="cursor: pointer;"
							@click.prevent="list_script(item)"
						>
							<a
								style="text-decoration: none;"
								:class="[
									`faq${item.id}`,
									{
										'on': item.open,
									}
								]"
							>
								<div class="qna_label"></div>
								<div class="type">
									<TranslateText
										source="ko"
										:contents="item.Type?.name"
									/>
								</div>
								<div class="subject_faq ta_l">
									<TranslateText
										source="ko"
										:contents="item.subject"
									/>
								</div>
							</a>
							<div class="reply" v-html="item.content">
							</div>
						</li>
					</ul>
					<div v-else class="ta_c">
						<p class="pt-5 pb-2">
						<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#ccc" d="M12 17q.425 0 .713-.288T13 16q0-.425-.288-.713T12 15q-.425 0-.713.288T11 16q0 .425.288.713T12 17Zm-1-4h2V7h-2v6Zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"/></svg>
						</p>
						<TranslateText
							source="ko"
							contents="등록된 데이터가 없습니다."
						/>
					</div>

					<!-- // pagination -->
				</div>
			</div>

<!-- //1:1 문의하기-->
			<div v-if="tabNum=='3'" class="board_list_wrap qna">
				<div class="board_list_top has_float">
					<div v-if="is_login" class="board_btn">
						<router-link to="/board/qna/form">
							<TranslateText
								source="ko"
								contents="1:1 문의 등록"
							/>
						</router-link>
					</div>
					<div class="board_count">
						<i class="fa fa-list-ul" aria-hidden="true"></i>
						Total
						<span class="red">
							{{ articles.count }}
						</span>
						<span> / </span>
						{{ articles?.pages?.pages }}Page
					</div>
				</div>

				<div class="article_list_body req11">
					<ul class="article_head articles">
						<li>
							<div class="no">NO</div>
							<div class="type">
								<TranslateText
									source="ko"
									contents="유형"
								/>
							</div>
							<div class="subject11">
								<TranslateText
									source="ko"
									contents="제목"
								/>
							</div>
							<div class="attach_file">
								<TranslateText
									source="ko"
									contents="파일"
								/>
							</div>
							<div class="date">
								<TranslateText
									source="ko"
									contents="등록일"
								/>
							</div>
							<div class="status">
								<TranslateText
									source="ko"
									contents="상태"
								/>
							</div>
						</li>
					</ul>
					<ul v-if="articles.count>0" class="article_body articles mb-lg-5 mb-3">
						<li
							v-for="item in articles.rows" :key="item.id"
							style="cursor: pointer;"
							@click.prevent="list_script(item)"
						>
							<a
								style="text-decoration: none;"
								:class="{
									'on': item.open
								}"
							>
								<div class="no">{{ item.id }}</div>
								<div class="type">
									<TranslateText
										source="ko"
										:contents="commonStore.code.find(v => v.id == item.type_id)?.name || '기타'"
									/>
								</div>
								<div class="subject11 ta_l">
									{{ item.subject }}
								</div>
								<div class="attach_file d-flex justify-content-center">
									<button
										v-if="item.File?.id"
										type="button"
										data-bs-toggle="modal"
										data-bs-target="#exampleModal"
										@click.stop="showThumb(item.File)"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="20"
											height="20"
											viewBox="0 0 24 24"
										>
											<path fill="#666" d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m4 18H6V4h7v5h5v11Z" />
										</svg>
									</button>
									
								</div>
								<div class="date">{{ item.createdAt?.substring(0,10) }}</div>
								<div v-if="item.status==2" class="status black">
									<TranslateText
										source="ko"
										contents="답변완료"
									/>
									
								</div>
								<div v-else class="status">
									<TranslateText
										source="ko"
										contents="답변대기"
									/>
								</div>
							</a>
							<div
								class="reply"
							>
								[<TranslateText
										source="ko"
										contents="문의내용"
									/>]<br><br>
								<div>{{ item.content }}</div>
								<br><br>
								[<TranslateText
										source="ko"
										contents="답변"
									/>]<br><br>
								<div v-html="item.reply"></div>
							</div>
						</li>
					</ul>
					<div v-else class="ta_c text-secondary py-5">
						<p class="pt-5 pb-2">
						<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#ccc" d="M12 17q.425 0 .713-.288T13 16q0-.425-.288-.713T12 15q-.425 0-.713.288T11 16q0 .425.288.713T12 17Zm-1-4h2V7h-2v6Zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"/></svg>
						</p>
						<TranslateText
							source="ko"
							contents="등록된 1:1문의가 없습니다."
						/>
					</div>

					<!-- // pagination -->
				</div>				
			</div>

			<Pagination
				:pagination="pagination"
				@click="getBoard"
			/>
		</div>

	</div><!-- //#sub_wrap -->

	<include-footer/>

	<div class="modal fade" id="exampleModal" tabindex="-1" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<div class="ml-auto"></div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body py-2  py-lg-3 px-lg-5 ">
					<img
						class="object-cover w-100 h-100"
						:src="`${SERVER_URL}${fileItem.path}`"
						alt="미리보기"
					/>
				</div>				
			</div>
		</div>
	</div>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'
import board from '../layout/board.vue'

export default {
	name: 'App',
	components: {
	'include-header': header,
	'include-footer': footer,
	'include-board-header': board,
	}
}
</script>