import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'

import VueCookies from 'vue3-cookies'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import LoadScript from 'vue-plugin-load-script';
import VueYtframe from "vue3-ytframe";

import { vMaska } from 'maska';

import axios from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

import App from './App.vue'
import router from './router'
import i18n from './components/utils/i18n';

// import commonCss from './assets/css/common.css'
// import andCss from './assets/css/and.css'
// import commons from './assets/common'

/**
 * 전역변수
 */
const pinia = createPinia();
pinia.use(({ store }) => {
	store.router = markRaw(router);
})

pinia.use(piniaPluginPersistedstate)

window.Swal = Swal;
const toast = Swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton:false,
	timer: 3000,
	timerProgressbar: true
});

window.toast = toast;

createApp(App)
	.use(router)
	.use(pinia)
	.use(i18n)
	// .use(commons.install)
	.use(VueCookies, {
		expireTimes: "30d",
		path: "/member/join",
		domain: "https://labstartupdsu.kr",
		secure: true,
		sameSite: "none"
	})
	.use(LoadScript)
	.use(VueYtframe)
	.directive('maska', vMaska)
	.mount('#app')
