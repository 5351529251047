<script setup>
import { onMounted, ref, computed } from "vue";

import { useAuthStore } from '@/store/auth.pinia';
import { useCommonStore } from '@/store/common.pinia';

import server from '@/api/server';

import { env as SERVER_URL } from '@/components/utils/env';
import TranslateText from '@/components/common/translate_text.vue';

import LectureBox from '@/partials/lecture_box.vue';

import 'vue3-carousel/dist/carousel.css'
//import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

//import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

//import { Icon } from '@iconify/vue';

const authStore = useAuthStore();
const commonStore = useCommonStore();

let searchModel = ref({})

let banners = ref({})
let categories = ref({})
let articles = ref({})
let notice = ref({})
let faq = ref({})

let settings = ref({
	arrows: false,
	dots: true,
})

const is_login = computed(() => !!authStore.user?.name);

const courseType = computed(() => commonStore.code.filter(v => v.main_code === 'CTGY' && v.sub_code != 0));

const getBanners = async () => {
	let response = await server.get(`/api/public/banner`)
	banners.value = response.data.result;
}

const getCategories = async () => {
	let response = await server.get(`/api/public/category`)
	categories.value = response.data.result;
}

const getNotice = async () => {
	let response = await server.get(`/api/public/notice?limit=5&page=1`)
	notice.value = response.data.result;
}

const getFaq = async () => {
	let response = await server.get(`/api/public/faq?limit=5&page=1`)
	faq.value = response.data.result;
}

const getArticles = async (tag) => {
	const params = new URLSearchParams();

	if(searchModel.value != undefined) {
		Object.keys(searchModel.value).map(v => {
			params.append(v, searchModel.value[v]);
		});
	}

	params.append('limit', 8);
	params.append('page', 1);

	// 231010 공개로 변경
	const response = await server({
		url: `/api/public/course`,
		method: 'GET',
		params
	})
	.then(res => res.data.result);

	articles.value = response;
}

const onGuideOpen = () => {
		window.open('/스타트업클래스_이용가이드.pdf');
	}

onMounted(() => {
	commonStore.getCode();

	getBanners();
	getCategories();
	getArticles();
	getNotice();
	getFaq();	
});

</script>

<template>
	<include-header-main/>

	<div id="main_wrap">
		<div class="main_vis position-relative">
			<div id="carouselExampleInterval" class="carousel slide carousel-fade" data-bs-ride="carousel">
				<div class="carousel-inner">
					<div
						v-for="(item, index) in banners"
						:key="item.position"
						class="carousel-item"
						data-bs-interval="3000"
						:class="{ 'active': index === 0 }"
					>
						<div class="img_wrap">
							<img :src="`${SERVER_URL}${item.File?.path}`" class="d-block w-100" :alt="`제품설명-${index}`">
						</div>
					</div>
					<div class="inner_wrap absolute">
						<div class="text_wrap">
							<span class="label">
								NEW CLASS
							</span>
							<div class="fs-1">
								<TranslateText
									source="ko"
									contents="스타트업 클래스"
								/>
								<br>
								NEW OPEN!! 
							</div>
							<div class="fs-3">
								<TranslateText
									source="ko"
									contents="스타트업클래스의 신규 강좌를 만나보세요!"
								/>
							</div>
						</div>
					</div>
				</div>
				<button class="carousel-control-prev position-absolute top-50 start-0 translate-middle-y" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
					<span class="carousel-control-prev-icon" aria-hidden="true"></span>
					<span class="visually-hidden">Previous</span>
				</button>
				<button class="carousel-control-next osition-absolute top-50 end-0 translate-middle-y" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
					<span class="carousel-control-next-icon" aria-hidden="true"></span>
					<span class="visually-hidden">Next</span>
				</button>
			</div>

			<!-- <carousel :items-to-show="1" :wrap-around="true">
				<slide v-for="item in banners" :key="item.position">
					<div class="img_wrap">
						<img :src="`${SERVER_URL}${item.File?.path}`" alt="">
					</div>
					<div class="inner_wrap absolute">
						<div class="text_wrap">
							<img src="@/assets/img/main/main_vis_text.png" alt="">
						</div>
					</div>
				</slide>
			</carousel> -->

			<!-- <ul>
				<li v-for="item in banners" :key="item.position" class="">
					<div class="img_wrap">
						<img :src="`${SERVER_URL}${item.File?.path}`" alt="">
					</div>
					<div class="inner_wrap absolute">
						<div class="text_wrap">
							<img src="@/assets/img/main/main_vis_text.png" alt="">
						</div>
					</div>
				</li>
			</ul> -->

		</div><!-- //.main_vis -->

		<div class="main_category">
			<div class="inner_wrap">
				<ul class="cate_list">
					<li
						v-for="(course_type, key) in courseType"
						:key="`course_type-${course_type.id}`"
					>
						<router-link
							:to="`/lecture/${course_type.id}`"
						>
							<div class="img_wrap">
								<img :src="`/img/main/icon0${(key + 1)}.png`" :alt="`${course_type.name}-${course_type.id}`" />
							</div>
							<span>
								<TranslateText
									source="ko"
									:contents="course_type.name"
								/>
							</span>
						</router-link>
					</li>
				</ul>
			</div>
		</div>

		<div class="main_keyword">
			<div class="inner_wrap">
				<div class="title">
					<TranslateText
						source="ko"
						contents="이런 키워드는 어때요?"
					/>
				</div>
				<div class="main_keyword_tag">
					<div class="main_keyword_tag_list">
						<router-link
							:to="`/lecture/${item.Code?.id}/${item.id}`"
							v-for="item in categories"
							:key="`lecture-link-${item.id}`"
							class="tag"
						>
							<TranslateText
								source="ko"
								:contents="item.name"
							/>
						</router-link>
					</div>
					<!-- 키워드 길어지면 화살표 생김 -->
					<div v-if="false" class="arrow">
						<router-link to="">
							<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/></svg>
						</router-link>
						<router-link to="">
							<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/></svg>
						</router-link>
					</div>
				</div>
			</div>
		</div>

		<div class="main_classes_list new">
			<div class="inner_wrap">
				<div class="title_wrap">
					<div class="title_text">
						<div class="subtitle">
							<TranslateText
								source="ko"
								contents="매월 업데이트 되는"
							/>
							
						</div>
						<div class="title">
							<TranslateText
								source="ko"
								contents="트렌디한 CLASS"
							/>
						</div>
					</div>
					<router-link to="/lecture" class="more">
						<TranslateText
							source="ko"
							contents="전체보기"
						/>
						
						<i class="fa fa-angle-right fw-semibold" aria-hidden="true"></i>
					</router-link>
				</div>

				<ul v-if="articles.count > 0" class="classes_list new">
					<LectureBox
						v-for="item in articles.rows"
						:key="`trend-${item.id}`"
						:item="item"
						@change="getArticles"
					/>
				</ul>

			</div>
		</div><!-- //.classes_list -->

		<div class="main_classes_list hot">
			<div class="inner_wrap">
				<div class="title_wrap">
					<div class="title_text">
						<div class="subtitle">
							<TranslateText
								source="ko"
								contents="스타트업 클래스가 만든 "
							/>
						</div>
						<div class="title">
							<TranslateText
								source="ko"
								contents="ONLY 스타트업클래스"
							/>
						</div>
					</div>
					<router-link to="/lecture" class="more">
						<TranslateText
							source="ko"
							contents="전체보기"
						/>
						<i class="fa fa-angle-right fw-semibold" aria-hidden="true"></i>
					</router-link>
				</div>

				<ul v-if="articles.count>0" class="classes_list hot">
					<LectureBox
						v-for="item in articles.rows"
						:key="`box-${item.id}`"
						:item="item"
						@change="getArticles"
					/>
				</ul>
			</div>
		</div><!-- //.classes_list -->

		<div class="main_board_container">
			<div class="inner_wrap">
				<div class="main_board_inner">
					<div class="main_board_wrap">
						<div class="board_title_wrap">
							<span>
								<TranslateText
									source="ko"
									contents="공지사항"
								/>
							</span>
							<div>NOTICE</div>
							<router-link to="/board/notice" class="more">
								<TranslateText
									source="ko"
									contents="더보기"
								/>
								<i class="fa fa-angle-right fw-semibold" aria-hidden="true"></i>
							</router-link>
						</div>
						
						<div class="article_list">
							<ul v-if="notice.count>0">
								<li v-for="item in notice.rows" :key="item.id">
									<router-link :to="`/board/notice/${item.id}`" class="article_link">
										<TranslateText
											source="ko"
											:contents="item.subject"
										/>
									</router-link>
									<span class="article_date">
										{{ item.createdAt.substr(0,10) }}
									</span>
								</li>
							</ul>
						</div>
					</div>

					<div class="main_board_wrap">
						<div class="board_title_wrap">
							<span>
								<TranslateText
									source="ko"
									contents="자주묻는질문"
								/>
							</span>
							<div>FAQ</div>
							<router-link to="/board/faq" class="more">
								<TranslateText
									source="ko"
									contents="더보기"
								/>
								<i class="fa fa-angle-right fw-semibold" aria-hidden="true"></i>
							</router-link>
						</div>
						<div class="article_list">
							<ul v-if="faq.count>0">
								<li v-for="item in faq.rows" :key="item.id">
									<router-link :to="`/board/faq?id=${item.id}`" class="article_link">
										<TranslateText
											source="ko"
											:contents="item.subject"
										/>
									</router-link>
									<span class="article_date">
										{{ item.createdAt.substr(0,10) }}
									</span>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div><!-- //.main_board_container -->

		<div class="main_guide">
			<div class="inner_wrap">
				<div class="title">
					<TranslateText
						source="ko"
						contents="지금 스타트업 클래스를 시작하세요."
					/>
					
				</div>
				<button class="btn_down" @click="onGuideOpen()">
					<!-- <Icon icon="icon-park-outline:hair-dryer" class="ml-2 cursor-pointer" width="48" height="48" /> -->
					<iconify-icon icon="material-symbols:download-sharp" style="color: white; font-size: 2rem;
					vertical-align: middle;"></iconify-icon>
					<TranslateText
						source="ko"
						contents="이용가이드 보기"
					/>
				</button>
			</div>
		</div>

	</div><!-- //#main_wrap -->
	
	<include-footer/>
</template>

<script>
import header_main from '../components/layout/header_main.vue'
import footer from '../components/layout/footer.vue'

export default {
	name: 'App',
	components: {
		'include-header-main': header_main,
		'include-footer': footer
	}
}
</script>