<script setup>
import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useI18n } from 'vue-i18n';

import useVuelidate from "@vuelidate/core";

import { useAuthStore } from '@/store/auth.pinia';
import { useCommonStore } from '@/store/common.pinia';

import { required, minLength, maxLength, minValue, maxValue, email, sameAs } from "@/components/utils/ko-vuelidate";

import TranslateText from '@/components/common/translate_text.vue';

import server from '@/api/server';

const route = useRoute()
const router = useRouter();

const authStore = useAuthStore();
const commonStore = useCommonStore();

const { t } = useI18n();

const props = defineProps({
	category:{
		type:String,
		default:''
	}
})

let formModel = ref({
	name:"",
	email: "",
	subject:"",
	content: "",
	type_id: "",
	Photo_File: "",
	preview: "",
	bd_id: 0,
});

const rules = {
	subject: { required },
	type_id: { required },
	content: { required },
	//photo: { required },
};

let v$ = useVuelidate(rules, formModel.value)

const typeList = computed(() => {
	return commonStore.code.filter(v => v.main_code == 'QATY' && v.sub_code != 0);
})

const showPreview = () => {
	let preview = "/upload/image.png";
	if (formModel.value.preview) {
		if (formModel.value.preview.indexOf("base64") != -1) {
			preview = formModel.value.preview;
		} else {
			preview = "/upload/" + formModel.value.preview;
		}
	}
	return preview;
};

const updatePhoto = (e) => {
	let file = e.target.files[0];
	let reader = new FileReader();
	let limit = 1024 * 1024 * 2;
	if (file["size"] > limit) {
		return false;
	}

	formModel.value.Photo_File = file;
	reader.onloadend = (file) => {
		formModel.value.preview = reader.result;
	};
	reader.readAsDataURL(file);
};

const updateFile = (e) => {
	let file = e.target.files[0];
	let reader = new FileReader();
	let limit = 1024 * 1024 * 2;
	if (file["size"] > limit) {
		return false;
	}

	formModel.value.Attach_File = file;
};

let tabNum = ref(1)
let tabName = ref('공지사항')
let call_url = ref(`/api/v1/course`)

if(props.category=='notice') {
	tabNum = 1;
	tabName = '공지사항';
	call_url = `/api/v1/course?limit=20&page=1`;
} else if(props.category=='faq'){
	tabNum = 2;
	tabName = '자주 묻는 질문';
	call_url = `/api/v1/course?limit=20&page=1`;
} else if(props.category=='qna') {
	tabNum = 3;
	tabName = '1:1 문의';
	call_url = `/api/v1/board/qa`;
}

const formSubmit = async () => {
	const result = await v$.value.$validate();
console.log(result);
	if( !result ) {
		toast.fire({
			icon: "error",
			title: "입력 양식에 맞지 않습니다.",
		});
		return;
	}

	const formData = new FormData();

	Object.keys(formModel.value).forEach(v => {
		if(formModel.value[v] === undefined || formModel.value[v] === null ) {
			return;
		}
		if(v == 'Photo_File') {
			formData.append('files', formModel.value[v]);
		} else {
			formData.append(v, formModel.value[v]);
		}
	});

	// 2021-05-07 minhyuk
	var object = {};
	formData.forEach(function(value, key){
		object[key] = value;
	});
	var jsonData = JSON.stringify(object);
console.log(jsonData);

	const config = {
		headers: {
				"Content-Type": "multipart/form-data",
				"Access-Control-Allow-Origin": "*",
			},
		}
/*
	await server({
		headers: {
			"Content-Type": "multipart/form-data",
			"Access-Control-Allow-Origin": "*",
		},
		url: `/api/app/board`,
		method: "POST",
		data: formData
	})
*/
	
	await server
	.post(`/api/v1/board/qa`, formData, config )
	.then((response) => {
console.log(response);
		if(response.data.result.message == "OK") {
			//formModel.value.bd_id = response.data.result.bd_id;
			//article_upload()
			router.push(`/board/${props.category}`)

			toast.fire({
				icon: "success",
				title: "글 작성 완료!",
			});
		}
	})
	.catch((error) => {
		console.log(error);
		toast.fire({
			icon: "error",
			title: "오류 발생!",
		});
	});
};
</script>
<template>
	<include-header/>

	<div id="sub_wrap">
		<div class="inner_wrap">

			<include-board-header/>

			<p class="sub_menu_text">
				<span v-if="tabNum=='3'">
					<TranslateText
						source="ko"
						contents="DSU 동서대 창업 온라인 강좌서비스에 대해  
					문의하신 사항에 대한 답변은 입력해주신 이메일과 내 문의내역에서 확인하실 수 있습니다."
					/>
				</span>
			</p>

			<div v-if="tabNum=='3'" class="board_write_wrap mt-lg-5 mt-3 ">
				<div class="article_write_body req11">
					<div class="article_head">
						<TranslateText
							source="ko"
							contents="1:1 문의"
						/>
					</div>
					<form id="aritlce_write_form" action="#">
						<ul>
							<li>
								<div class="field_label">
									<label for="type">
										<TranslateText
											source="ko"
											contents="문의유형"
										/>
									</label>
								</div>
								<div class="field_input half">
									<select
										v-model="formModel.type_id"
										id="type"
										name="type"
									>
										<option value="">
											<TranslateText
												source="ko"
												contents="선택"
											/>
										</option>
										<option
											v-for="category_type in typeList"
											:key="`category-${category_type.id}`"
											:value="category_type.id"
										>
											<TranslateText
												source="ko"
												:contents="category_type.name"
											/>
										</option>
									</select>
								</div>
							</li>
							<li>
								<div class="field_label">
									<label for="name">
										<TranslateText
											source="ko"
											contents="이름"
										/>
									</label>
								</div>
								<div class="field_input half">
									<input
										type="text"
										v-model="formModel.name"
										id="name"
										name="name"
										:placeholder="t('placeholder:name')"
									/>
								</div>
							</li>
							<li>
								<div class="field_label">
									<label for="email">
										<TranslateText
											source="ko"
											contents="이메일"
										/>
									</label>
								</div>
								<div class="field_input half">
									<input
										type="email"
										v-model="formModel.email"
										id="email"
										name="email"
										:placeholder="t('placeholder:email')"
									/>
								</div>
							</li>
							<li>
								<div class="field_label">
									<label for="subject">
										<TranslateText
											source="ko"
											contents="제목"
										/>
									</label>
									<span class="red">
										<sup>*</sup>
										<TranslateText
											source="ko"
											contents="필수"
										/>
									</span>
								</div>
								<div class="field_input half">
									<input
										type="text"
										v-model="formModel.subject"
										id="subject"
										name="subject"
										:placeholder="t('placeholder:subject')"
									/>
								</div>
							</li>
							<li>
								<div class="field_label">
									<label for="content">
										<TranslateText
											source="ko"
											contents="내용"
										/>
									</label>
									<span class="red">
										<sup>*</sup>
										<TranslateText
											source="ko"
											contents="필수"
										/>
									</span>
								</div>
								<div class="field_input half">
									<textarea
										v-model="formModel.content"
										id="content"
										name="content"
										:placeholder="t('placeholder:content')"
									>
									</textarea>
								</div>
							</li>
							<li>
								<div class="field_label">
									<label for="file">
										<TranslateText
											source="ko"
											contents="사진선택"
										/>
									</label>
								</div>
								<div class="field_input">
									<img :src="showPreview()" alt="" style="max-width:100%;" />
									<input
										type="file"
										id="file"
										name="file"
										@change="updatePhoto"
										:placeholder="t('placeholder:file')"
									/>
								</div>
							</li>
						</ul>
					</form>
				</div>

				<div class="bottom_btns">
					<button type="button" @click="router.go(-1)">
						<TranslateText
							source="ko"
							contents="목록으로"
						/>
						
					</button>
					<button type="button" class="submit" @click="formSubmit()">
						<TranslateText
							source="ko"
							contents="확인"
						/>
					</button>
				</div>
			</div>

		</div>

	</div><!-- //#sub_wrap -->

	<include-footer/>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'
import board from '../layout/board.vue'

export default {
	name: 'App',
	components: {
	'include-header': header,
	'include-footer': footer,
	'include-board-header': board,
	}
}
</script>