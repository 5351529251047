import { env, env_nip, env_sns } from "./env";

const sns = {
	// naver_auth: 'ANXWFHiDMkzJnl7IbVRe',
	// naver_secret: '8bGxdcq6am',
	naver_auth: 'ZY2hN2eqHTMErnrpM0pB',
	naver_secret: '6fnxMKpnPi',
	naver_states: '1234',
	callback_url: `${env_sns}/member/sns`,
	callback_google_url: `${env_nip}/member/sns`,
	kakao_auth: 'd60c4b89ee4f19d74656ecca30966288',
	kakao_client: '76abffa47965d34168d1fc30a02e5f00',
	google_auth: '634582538960-oc5f1a569j3c7hm7l4rj26d21mocma3d.apps.googleusercontent.com',
	google_secret: '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',
}

export { sns };