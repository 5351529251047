<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import server from '@/api/server';

const route = useRoute()
const router = useRouter();

let formModel = ref({
	bd_category: 'contact',
	bd_title:"",
	bd_content: "",
	bd_industry: "",
	bd_name: "",
	bd_phone: "",
	bd_addr: "",
	agree: "",
});

const formSubmit = async () => {
	const formData = new FormData();
	formData.append("bd_title", formModel.value.bd_title);
	formData.append("bd_content", formModel.value.bd_content);
	formData.append("bd_category", formModel.value.bd_category );
	formData.append("bd_industry", formModel.value.bd_industry );
	formData.append("bd_name", formModel.value.bd_name );
	formData.append("bd_phone", formModel.value.bd_phone );
	formData.append("bd_addr", formModel.value.bd_addr );
	
	formData.append("mb_id", localStorage.getItem('userID') );

	// 2021-05-07 minhyuk
	var object = {};
	formData.forEach(function(value, key){
		object[key] = value;
	});
	var jsonData = JSON.stringify(object);
console.log(jsonData);

	await server
	.post(`/api/app/board`, formData)
	.then((response) => {
		router.push(`/`)

		toast.fire({
			icon: "success",
			title: "글 작성 완료!",
		});
	})
	.catch((error) => {
		console.log(error);
	// toast.fire({
	//   icon: "error",
	//   title: error.response.data.result.message,
	// });
	});
};

</script>
<template>
	<include-header/>

		<div class="container">
			<div class="board_wrap mb50 ">
				<div class="fl">
					<ul class="board_menu bg_w">
						<li class="on">
							<a href="/contact/form">제휴신청</a>
						</li>
					</ul>
					<div class="board_menu_m">
						<select>
							<option value="/contact/form">제휴신청</option>
						</select>
					</div>
				</div>
				<div class="fr">
					<div class="board_con bg_w">
						<h3 class="bd_tit">제휴신청</h3>
						
						<ul class="bd_write">
							<li>
								<h3>업체명<small class="txt_v">*필수</small></h3>
								<div class="con">
									<input type="text" v-model="formModel.bd_title" placeholder="업체명을 입력해주세요">
								</div>
							</li>
							<li>
								<h3>업종<small class="txt_v">*필수</small></h3>
								<div class="con">
									<input type="text" v-model="formModel.bd_industry" placeholder="업종을 입력해주세요">
								</div>
							</li>
							<li>
								<h3>담당자명</h3>
								<div class="con">
									<input type="text" v-model="formModel.bd_name" placeholder="담당자명을 입력해주세요">
								</div>
							</li>
							<li>
								<h3>대표전화<small class="txt_v">*필수</small></h3>
								<div class="con">
									<input type="tel" v-model="formModel.bd_phone" placeholder="대표전화를 입력해주세요">
								</div>
							</li>
							<li>
								<h3>주소</h3>
								<div class="con">
									<input type="text" v-model="formModel.bd_addr" placeholder="주소를 입력해주세요">
								</div>
							</li>
							<li>
								<h3>제휴문의<small class="txt_v">*필수</small></h3>
								<div class="con">
									<textarea v-model="formModel.bd_content" placeholder="내용을 입력해주세요"></textarea>
								</div>
							</li>
							<li>
								<div class="notice">
								<b>[가맹점 제휴조건]</b>
								<p>가맹점으로 제휴된 업체는 사용자에게 할인 또는 서비스를 필수 제공해야 합니다. 제휴에 따른 가맹비는 별도로 부과되지 않습니다.</p>
									<span class="txt_v">※ 고객센터 : 051-313-2001~4</span>
								</div>
							</li>
						</ul>

						<div class="check">
							<input type="checkbox" v-model="formModel.agree" id="agree" value="Y">
							<label for="agree">개인정보수집 및 이용에 동의합니다.</label>
						</div>

						<!--버튼-->
						<div class="btn_wrap">
							<!-- <button class="btn_gline">취소하기</button> -->
							<button class="btn_v" @click="formSubmit()">신청하기</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<include-footer />
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer
	}
}
</script>