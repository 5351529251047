<script setup>
  import { useRoute, useRouter } from "vue-router";

  import TranslateText from '@/components/common/translate_text.vue';

  import server from '@/api/server';

  const routes = useRoute();
  const router = useRouter();

  const goto_home = () => {
    router.push('/');
  }
  const goto_login = () => {
    router.push('/member/login');
  }

</script>
<template>
	<include-header/>

  <div class="join_container" style="min-height: 70vh">
    <div class="join_wrap bg_w">
      <div class="join_info">
        <div 
          style="text-align: center;"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 48 48" style="margin-bottom: 10px;">
            <defs>
              <mask id="ipSCheckOne0">
                <g fill="none" stroke-linejoin="round" stroke-width="4">
                <path fill="#fff" stroke="#fff" d="M24 44a19.937 19.937 0 0 0 14.142-5.858A19.937 19.937 0 0 0 44 24a19.938 19.938 0 0 0-5.858-14.142A19.937 19.937 0 0 0 24 4A19.938 19.938 0 0 0 9.858 9.858A19.938 19.938 0 0 0 4 24a19.937 19.937 0 0 0 5.858 14.142A19.938 19.938 0 0 0 24 44Z"/>
              <path stroke="#000" stroke-linecap="round" d="m16 24l6 6l12-12"/></g>
              </mask>
            </defs>
            <path fill="#e11837" d="M0 0h48v48H0z" mask="url(#ipSCheckOne0)"/>
          </svg>
          <h6 
            style="font-size: 1.6rem; font-weight: bold;"
          >
            <TranslateText
              source="ko"
              contents="회원가입완료"
            />
          </h6>
          <p>
            <TranslateText
              source="ko"
              :contents="`지금 스타트업 클래스의 모든 콘텐츠를\n지금 바로 이용해보세요!`"
            />
          </p>
        </div>
        <div
          style="background: #eee; padding: 20px; color: #222; margin: 30px 0; border-radius: 5px;"
        >
          <ul 
            style="margin: 0;"
          >
            <li style="margin-bottom: 5px;">
              <span style="display: inline-block; width: 80px;" > · 
                <TranslateText
                  source="ko"
                  contents="아이디"
                />
              </span>
              <b>{{ routes.query.user }}</b>
            </li>
            <li style="margin-bottom: 5px;">
              <span style="display: inline-block; width: 80px;" > · 
                <TranslateText
                  source="ko"
                  contents="이름"
                />
              </span>
              <b>{{ routes.query.name }}</b>
            </li>
            <li>
              <span style="display: inline-block; width: 80px;" > · 
                <TranslateText
                  source="ko"
                  contents="이메일"
                />
              </span>
              <b>{{ routes.query.email }}</b>
            </li>
          </ul>
        </div>

        <div
        >
          <button
            style="border:1px solid #e11837; padding: 20px; color: #e11837; width:48%; background: #fff; display: inline-block;margin-right: 3%;" 
            @click="goto_home()"
          >
            메인으로가기
          </button>
          <button 
            style="background: #e11837; color: #fff; padding: 20px; width:48%; display: inline-block;"
            @click="goto_login()"
          >
            로그인하기
          </button>
        </div>
      </div>
    </div>
  </div>
  <include-footer/>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'
  
export default {
  name: 'App',
  components: {
    'include-header': header,
    'include-footer': footer
  }
}
</script>