<script setup>
import { onMounted, ref, reactive, nextTick, computed, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useI18n } from 'vue-i18n';

import { cloneDeep } from 'lodash';

import Artplayer from 'artplayer';
import artplayerPluginControl from 'artplayer-plugin-control'

import { useCommonStore } from '@/store/common.pinia';

import { env as SERVER_URL } from "@/components/utils/env";
import TranslateText from '@/components/common/translate_text.vue';

import server from '@/api/server';

const props = defineProps({
	lecture_id:{
		type: [Number, String],
		default: 0
	}
})

const routes = useRoute();
const router = useRouter();

const commonStore = useCommonStore();

const { t } = useI18n();

const article = ref({});
const syllabusItem = ref({});	// 수강 별 정보

const art = ref(null);
const player = ref(null);

const options1 = reactive({
	//url: `${SERVER_URL}/videos/1693974349834_SoqfygGklj.mp4`,
	url: '/blank.mp4',
	poster: '/img/layout/black.jpg',
	hotkey: true,
	volume: 0.5,
	isLive: false,
	muted: false,
	autoplay: false,
	pip: true,
	autoSize: true,
	autoMini: true,
	screenshot: true,
	setting: true,
	loop: false,
	flip: true,
	playbackRate: true,
	aspectRatio: true,
	fullscreen: true,
	fullscreenWeb: true,
	subtitleOffset: true,
	miniProgressBar: true,
	mutex: true,
	backdrop: true,
	playsInline: true,
	autoPlayback: true,
	airplay: true,
	theme: '#E21838',
	lang: navigator.language.toLowerCase(),
	moreVideoAttr: {
		crossOrigin: 'anonymous',
	},
	i18n: {
		'ko-kr': {
			Play: '재생',
			Pause: '일시정지',
		},
	},
	plugins: [
		artplayerPluginControl()
	],
})

const is_login = computed(() => !!authStore.user?.name);
const language = computed(() => article.value.CourseDetails?.findIndex(v => v.language === commonStore.lang))

const watched_time = computed(() => {
	return article.value?.CourseSyllabuses?.reduce((acc, cur) => {
			acc += Number(cur.CourseProgress?.progress || 0)

			return acc;
		}, 0);
	
});
const total_time = computed(() => {
	return article.value?.CourseSyllabuses?.reduce((acc, cur) => {
		acc += cur.CourseSyllabusDetails[cur?.CourseSyllabusDetails?.findIndex(v => v.language == 'ko')].Video.duration || 0;

		return acc;
	}, 0);
});

const playing_url = computed(() => {
	return video.value?.path && new URL(video.value.path).searchParams.get('v');
})

const init_sec = computed(() => {
	return syllabusItem.value.CourseProgress?.status === 2 ? 0 : syllabusItem.value.CourseProgress?.progress
});

const controls = computed(() => {
	return syllabusItem.value.CourseProgress?.status === 2 ? 1 : 0
})

const title = computed(() => {
	if(!Array.isArray(article.value?.CourseDetails)) return '...';
	return article.value?.CourseDetails[language.value]?.title || 'Not Found Title';
})

const video = computed(() => {
	const idx = syllabusItem.value?.CourseSyllabusDetails?.findIndex(v => v.language === 'ko');

	if(idx === -1) return {};
	if(idx === undefined || idx === null) return {};

	return syllabusItem.value?.CourseSyllabusDetails[idx]?.Video;
})

/** lecture 정보 가져오기 */
const getSingleArticle = async () => {
	try {
		const response = await server({
			url: `/api/v1/course/${props.lecture_id}`,
			method: 'GET',
		})
		.then(res => res.data.result);

		article.value = response;

		article.value.CourseSyllabuses.forEach(v => {

		})
	} catch(e) {

	}
}
/** 일시정지 이벤트 */
const onPaused = (event) => {
	if(event.getCurrentTime() < 1) return;
	console.log('onPaused', event.getCurrentTime());

	setProgress(Math.floor(event.getCurrentTime()));
}

/** 시작 이벤트 */
const onPlaying = (event) => {
	console.log('onPlaying', event);
}

/** 플레이어 상태변경 감지 이벤트 */
const onStateChange = (event) => {
	console.log('onStateChange', event);
}

/** 끝정지 이벤트 */
const onEnded = (event) => {
	console.log('onEnded', event);

	/**
	 * 뭐든간에 최대재생 넘어서 보냄
	 */
	setProgress(Math.ceil(event.getDuration()));
}

/** 진척도 저장 */
const setProgress = async (time) => {
	try {
		//	완료된 수강은 진척도 저장이제안함
		if(syllabusItem.value.CourseProgress?.status === 2) return false;
		if(time < 1) return false;	// 1초 미만은 취급안함
		if(time <= init_sec.value) return false;	// 초기값보다 이전으로 감;

		const params = new URLSearchParams();
		params.append('course_syllabus_id', syllabusItem.value.id);
		params.append('progress', time);

		await server({
			url: `/api/v1/course/progress`,
			method: 'POST',
			data: params
		})
		.then(res => res.data);

		toast.fire({
			icon: "success",
			title: "강의진척도 저장 완료!",
		});

		getSingleArticle();
	} catch(e) {
		console.log(e);
		toast.fire({
			icon: "error",
			title: "오류 발생!",
		});
	}
}

const timeFormat = (seconds) => {
	console.log(seconds);
	if(seconds === null || seconds === undefined) return '00:00:00';

	const date = new Date(null);
	date.setSeconds(seconds);

	return date.toISOString().slice(11, 19);
}

/**
 * 동영상 재생
 */
const playing = async (curriculum) => {
	// 재생중인 영상을 클릭하면 유지
	if(syllabusItem.value.id === curriculum.id) return;
	
	/**
	 * 다른 강의를 재생할때
	 * 
	 * 현재 플레이어의 상태가
	 * 1: playing
	 * 2: pause
	 * 5: cued
	 */

	console.log(syllabusItem.value, curriculum);

	if(syllabusItem.value.id) {
		const state = player.value.getPlayerState();
		const time = player.value.getCurrentTime();

		switch(state) {
			case 0:
				break;
			case 1:
				const result = await player.value?.stopVideo();

				// 저장할거면 저장하고, 다른 강의 킴
				if(confirm(t('confirm:save'))) {
					await setProgress(time);
				}
				else return;
				
				break;
			case 2: 
				break;
			case 3:
				break;
			case 4:
				break;
			case 5:
				break;
			default:
		}
	}

	syllabusItem.value = cloneDeep({ ...curriculum });

	if(video.value?.type == 2) { // youtube
		// document.querySelector('#artplayer_div').classList.remove('on');
		// document.querySelector('#youtube_div').classList.add('on');

		// initYoutube();
	} else {
		// document.querySelector('#youtube_div').classList.remove('on');
		// document.querySelector('#artplayer_div').classList.add('on');

		var container = document.querySelector('#artplayer_div');
		art.value = new Artplayer({
			...options1,
			id: 'artplayer-app',
			container: container,//player.value,
			controls: [
				{
					position: 'right',
					html: '<button type="button">Switch UI</button>',
					click: function () {
						const { enable } = art.value.plugins.artplayerPluginControl;
						art.value.plugins.artplayerPluginControl.enable = !enable;
					},
				},
			],
		});

		art.value.on('ready', () => {
			art.value.notice.show = '영상이 준비가 되었습니다.';
		});

		art.value.on('pause', () => {
			console.info('pause', art.value.url == playing_url.value);
			if(art.value.url == playing_url.value) {
				setProgress(Math.floor(art.value.currentTime));
			}
		});

		art.value.on('video:ended', () => {
			console.log(art.value.currentTime);
			setProgress(Math.floor(art.value.duration));
		});

		if(art.value) {
			art.value.switch = `${SERVER_URL}/${playing_url.value}`;

			setTimeout(function () {
				art.value.seek = init_sec;
				art.value.play();
			}, 500)
		}
	}
}

const unLoadEvent = async (time) => {
	if(!syllabusItem.value.id) return;

	const state = player.value?.getPlayerState();
	
	switch(state) {
		case 0:
			break;
		case 1:
			const result = await player.value?.stopVideo();

			console.log(result, time);

			// 저장할거면 저장하고, 다른 강의 킴
			if(confirm(t('confirm:save'))) {
				await setProgress(time);
			}
			break;
		case 2:
			break;
		case 3:
			break;
		case 4:
			break;
		case 5:
			break;
		default:
	}
}


// const initYoutube = () => {
// 	player.value = new YT.Player('youtube_div', {
// 		// width: '320',
// 		// height: '240',
// 		videoId: playing_url.value,
// 		playerVars: {
// 			'start': init_sec.value,
// 			'enablejsapi': 1,
// 			'modestbranding': 1,
// 			'rel': 0,
// 		}, // 시작지점
// 		events: {
// 			'onReady': onPlayerReady,
// 			'onStateChange': onPlayerStateChange
// 		}
// 	});
// }

// const onPlayerReady = (event) => {
// 	console.log(parseInt(player.value.getDuration()));
// 	videolength.value = parseInt(player.value.getDuration())

// 	function updateTime() {
// 		oldTime.value = videotime.value;
// 		if(player.value && player.value.getCurrentTime) {
// 			videotime = parseInt(player.value.getCurrentTime());
// 		}
// 		if(videotime.value !== oldTime.value) {
// 			onProgress(videotime.value);
// 		}
// 	}

// 	timeupdater.value = setInterval(updateTime, 100);
// }

// const onProgress = (currentTime) => {
// 	if(videolength.value == currentTime) {
// 		done = true;
// 	} else if (videolength.value > currentTime) {
// 		done = false;
// 		console.log('아직 덜 봤다.')
// 	} else if (videolength.value < currentTime) {
// 		console.log('초과해서?!')
// 	}
// }

const onPlayerStateChange = (event) => {
	/*
		UNSTARTED -1
		ENDED 0
		PLAYING 1
		BUFFERING 3
		CUED 5
		PAUSED 2
	*/
	if (event.data == YT.PlayerState.PAUSED) { // 2
		// playing_url.value = new URL(player.value.getVideoUrl()).searchParams.get('v');
		// console.info('pause', videoid.value == playing_url.value);
		// if(videoid.value == playing_url.value) {
		setProgress(parseInt(player.value.getCurrentTime()));
	} else if (event.data == YT.PlayerState.ENDED) { // 0
		setProgress(parseInt(player.value.getDuration()));
	}
}

// $(window).bind("beforeunload", function (e){
// 	e.preventDefault();
// 	e.returnValue = "창을 닫으실래요?";
// 	return "창을 닫으실래요?";
// });

// window.addEventListener('load', function() {
// 	function getChildIndex(node) {
// 		return Array.prototype.indexOf.call(node.parentNode.childNodes, node);
// 	}
// });

// var tag = document.createElement('script');
// tag.src = "https://www.youtube.com/iframe_api";
// var firstScriptTag = document.getElementsByTagName('script')[0];
// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
// var done = false;

// window.onYouTubeIframeAPIReady = () => {
// 	console.log('onYouTubeIframeAPIReady');
// };

defineExpose({
	options1
})

onMounted(async () => {
	await getSingleArticle();

	// document.querySelector('#youtube_div').classList.add('on');

	// onYouTubeIframeAPIReady();
});

onBeforeUnmount(async () => {
	const time = player.value?.getCurrentTime();

	await unLoadEvent(time);
})

</script>
<template>
	<include-header/>

	<div class="class_see_wrap">
		<div class="class_see_head">
			<div class="title">
				<button
					class="title_btn" aria-hidden="true" title="title button"
					@click="router.push({ path: `/lecture/view/${lecture_id}` })"
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="white" fill-rule="evenodd" d="M20 4H4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1ZM4 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3H4Zm2 5h2v2H6V7Zm5 0a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-6Zm-3 4H6v2h2v-2Zm2 1a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1Zm-2 3H6v2h2v-2Zm2 1a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1Z" clip-rule="evenodd"/></svg>
				</button>
				<!-- <router-link to="#"><i class="fa fa-home" aria-hidden="true"></i></router-link> -->
				<span>
					{{ title }}
				</span>
			</div>
			<!-- <button @click="play()">재생</button>
			<button @click="pause()">정지</button> -->
			<router-link to="/mypage/room">
				<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#666" d="M10 19v-5h4v5c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-7h1.7c.46 0 .68-.57.33-.87L12.67 3.6c-.38-.34-.96-.34-1.34 0l-8.36 7.53c-.34.3-.13.87.33.87H5v7c0 .55.45 1 1 1h3c.55 0 1-.45 1-1z"/></svg>
				<span>
					<TranslateText
						source="ko"
						contents="나의 강의실"
					/>
				</span>
			</router-link>
		</div>
		<div class="class_see_body">
			<div class="movie_section">
				<div
					class="movie_none"
					:class="{
						'd-none': video.type !== null && video.type !== undefined
					}"
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="100" height="78.58" viewBox="0 0 1792 1408"><path fill="#666" d="M384 1056v64q0 13-9.5 22.5T352 1152h-64q-13 0-22.5-9.5T256 1120v-64q0-13 9.5-22.5t22.5-9.5h64q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5T352 896h-64q-13 0-22.5-9.5T256 864v-64q0-13 9.5-22.5T288 768h64q13 0 22.5 9.5T384 800zm0-256v64q0 13-9.5 22.5T352 640h-64q-13 0-22.5-9.5T256 608v-64q0-13 9.5-22.5T288 512h64q13 0 22.5 9.5T384 544zm1152 512v64q0 13-9.5 22.5t-22.5 9.5H544q-13 0-22.5-9.5T512 1120v-64q0-13 9.5-22.5t22.5-9.5h960q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5T1504 896H544q-13 0-22.5-9.5T512 864v-64q0-13 9.5-22.5T544 768h960q13 0 22.5 9.5t9.5 22.5zm0-256v64q0 13-9.5 22.5T1504 640H544q-13 0-22.5-9.5T512 608v-64q0-13 9.5-22.5T544 512h960q13 0 22.5 9.5t9.5 22.5zm128 704V416q0-13-9.5-22.5T1632 384H160q-13 0-22.5 9.5T128 416v832q0 13 9.5 22.5t22.5 9.5h1472q13 0 22.5-9.5t9.5-22.5zm128-1088v1088q0 66-47 113t-113 47H160q-66 0-113-47T0 1248V160Q0 94 47 47T160 0h1472q66 0 113 47t47 113z"/></svg>
					<span tabindex="0">
						<TranslateText
							source="ko"
							contents="강의목록을 클릭하여
							플레이 해주세요"
						/>
					</span>
				</div>
				<div
					id="artplayer_div"
					class="movie_player"
					:class="{
						'on': video.type == 1
					}"
				>
				</div>
				<div
					id="youtube_div"
					class="movie_player"
					:class="{
						'on': video.type == 2
					}"
				>
					<VueYtframe
						ref="player"
						:video-id="playing_url"
						:player-vars="{
							autoplay: 0,
							controls: controls,
							enablejsapi: 1,
							rel: 0,
							showinfo: 0,
							start: init_sec
						}"
						@stateChange="onStateChange"
						@playing="onPlaying"
						@paused="onPaused"
						@ended="onEnded"
					/>
				</div>
				<div class="movie_desc">
					<div v-html="syllabusItem[language]?.contents"></div>
					<!-- <h3 class="hr_text after">요약</h3>
					<div v-html="Array.isArray(article?.CourseDetails) && article?.CourseDetails[0]?.content"></div>

					<h3 class="hr_text after">추천</h3>
					<div v-html="Array.isArray(article?.CourseDetails) && article?.CourseDetails[0]?.content1"></div>

					<h3 class="hr_text after">진행도</h3>
					<div v-html="Array.isArray(article?.CourseDetails) && article?.CourseDetails[0]?.content2"></div>

					<h3 class="hr_text after">유의사항</h3>
					<div v-html="Array.isArray(article?.CourseDetails) && article?.CourseDetails[0]?.content3"></div> -->
				</div>
			</div>
			<div class="curriculum_section">
				<div class="desc">
					<div class="title">
						<TranslateText
							source="ko"
							contents="커리큘럼"
						/>
					</div>
					<dl>
						<dt>
							<TranslateText
								source="ko"
								contents="강사"
							/>
						</dt>
						<dd>
							<TranslateText
								source="ko"
								:contents="article.teacher_name || '-'"
							/>
						</dd>
					</dl>
					<dl>
						<dt>
							<TranslateText
								source="ko"
								contents="수강종료"
							/>
						</dt>
						<dd>
							{{ article.study_end_date || '-' }}
						</dd>
					</dl>

					<!-- 전체 진척도 progressbar -->
					<div class="progressbar">
						<span :style="`width:${((watched_time/total_time)*100).toFixed(1)}%`"></span>
					</div>

					<!-- 전체 진척도 percent -->
					<div class="progress_wrap">
						<div class="timeline">
							<i class="fa fa-clock-o fw-semibold" aria-hidden="true"></i>
							&nbsp;
							<span>
								{{ timeFormat(watched_time) }}
								/
								{{ timeFormat(total_time) }}
							</span>
						</div>
						<div class="progress_per">
							{{ isNaN(( watched_time / total_time ) * 100) ? '0' : `${(( watched_time / total_time ) * 100).toFixed(1)}` }}% Complete
						</div>
					</div>
				</div>

				<!-- 개별 강좌 -->
				<ul class="curriculum">
					<li
						class="curr_list"
						v-for="curriculum in article.CourseSyllabuses"
						:key="`curriculum-${curriculum.id}`"
						style="cursor: ponter;"
						tabindex="0"
						@click="playing(curriculum)"
						@keyup.enter="playing(curriculum)"
						:class="{
							'complete': curriculum?.CourseProgress?.status == 2,
							'ing': video.path === (Array.isArray(curriculum?.CourseSyllabusDetails) && curriculum?.CourseSyllabusDetails[curriculum?.CourseSyllabusDetails.findIndex(v => v.language === 'ko')]?.Video.path),
						}"
					>
						<div class="title">
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#999" d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm-2 14.5v-9l6 4.5z"></path></svg>
							<span>{{ Array.isArray(curriculum?.CourseSyllabusDetails) && curriculum?.CourseSyllabusDetails[curriculum?.CourseSyllabusDetails.findIndex(v => v.language === commonStore.lang)]?.title }}</span>
						</div>
							
						<div class="progress_wrap">
							<div class="time">
								<svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 256 256"><path fill="#666" d="M128 20a108 108 0 1 0 108 108A108.12 108.12 0 0 0 128 20Zm0 192a84 84 0 1 1 84-84a84.09 84.09 0 0 1-84 84Zm68-84a12 12 0 0 1-12 12h-56a12 12 0 0 1-12-12V72a12 12 0 0 1 24 0v44h44a12 12 0 0 1 12 12Z"/></svg>
								&nbsp;<span>{{ timeFormat(curriculum?.CourseProgress && curriculum?.CourseProgress?.progress) }}</span>
								&nbsp;/
								&nbsp;
								<span>
									{{ timeFormat(Array.isArray(curriculum?.CourseSyllabusDetails) && curriculum?.CourseSyllabusDetails[curriculum?.CourseSyllabusDetails.findIndex(v => v.language === 'ko')]?.Video?.duration) }}
								</span>
							</div>
							<div class="progress_per">
								{{ (((curriculum?.CourseProgress?.progress || 0) / curriculum?.CourseSyllabusDetails[curriculum?.CourseSyllabusDetails?.findIndex(v => v.language == 'ko')]?.Video?.duration || 0) * 100).toFixed(1) }}% Complete
							</div>
						</div>
						
						<div class="progressbar">
							<span :style="`width:${(((curriculum?.CourseProgress?.progress || 0) / curriculum?.CourseSyllabusDetails[curriculum?.CourseSyllabusDetails?.findIndex(v => v.language == 'ko')]?.Video?.duration || 0) * 100).toFixed(1)}%`"></span>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>

	<include-footer/>

</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'
import { useI18n } from 'vue-i18n';

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer
	}
}
</script>