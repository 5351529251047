<script setup>
	import { onMounted, ref } from "vue"
	import { useRouter } from "vue-router"
	import Pagination from '@/components/common/pagination';

	import TranslateText from '@/components/common/translate_text.vue';

	import server from '@/api/server';

	import PurchaseBox from '@/partials/purchase_box.vue';

	const router = useRouter()

	const paymentList = ref([]);
	
	const pagination = ref({
		limit: 20,
		next: 0,
		pre: 0,
		page: 1,
		pages: [],
	})

	const getPayment = async (page = 1) => {
		try {
			pagination.value.page = page;

			const params = new URLSearchParams();

			params.append('limit', pagination.value.limit);
			params.append('page', pagination.value.page);

			const response = await server({
				url: `/api/v1/payment`,
				method: 'GET',
				params
			})
			.then(res => res.data.result);

			paymentList.value = response.rows;
			pagination.value = Object.assign({}, {
				...pagination.value,
				...response.pages
			});
		} catch(e) {

		}
	}

	onMounted(async() => {
		getPayment();
	})
</script>

<template>
	<include-header/>

	<div id="sub_wrap">
		<div class="inner_wrap">

			<include-mypage-header/>

			<div class="my_buy_wrap">
				<div class="my_buy_head">
					<div class="lgray_box">
						<select name="" id="">
							<option value="">
								<TranslateText
									source="ko"
									contents="전체상태"
								/>
							</option>
						</select>
						<div class="search_wrap">
							<input type="text" aria-label="search_word" name="search_word" placeholder="코스, 교육명 등으로 검색할 수 있습니다.">
							<button type="button" class="bg_red white">
								<TranslateText
									source="ko"
									contents="검색"
								/>
								</button>
						</div>
					</div>
				</div>
				
				<div class="my_buy_body">
					<ul v-if="paymentList.length > 0" class="cart_list type2">
						
						<PurchaseBox
							v-for="payment in paymentList"
							:key="`payment-${payment.id}`"
							:item="payment"
						/>						
					</ul>

					<!--강의없을때-->
					<div v-else class="text-center py-5 my-5 text-secondary fs-5">
						<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" class="inline-block mb-3"><path fill="#666" d="M12 17q.425 0 .713-.288T13 16q0-.425-.288-.713T12 15q-.425 0-.713.288T11 16q0 .425.288.713T12 17Zm-1-4h2V7h-2v6Zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"/></svg><br>
						<TranslateText
							source="ko"
							contents="구매내역이 없습니다."
						/>
					</div>
					
					<!-- 페이지네이션 -->
					<Pagination
						:pagination="pagination"
						@click="getPayment"
					/>
				</div>
			</div>
		</div>

	</div><!-- //#sub_wrap -->

	<include-footer/>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'
import mypage from '../layout/mypage.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer,
		'include-mypage-header': mypage
	}
}
</script>