<script setup>
	import { onMounted, ref, watch, computed } from "vue";
	import { useRoute, useRouter } from "vue-router";
	import { Icon } from '@iconify/vue';

	import { useI18n } from 'vue-i18n';

	import { useAuthStore } from '@/store/auth.pinia';
	import { useCommonStore } from '@/store/common.pinia';

	import Pagination from '@/components/common/pagination';

	import TranslateText from '@/components/common/translate_text.vue';

	import server from '@/api/server';

	const route = useRoute()
	const router = useRouter();

	const { t } = useI18n();

	const authStore = useAuthStore();
	const commonStore = useCommonStore();

	const articles = ref([])
	const pagination = ref({
		limit: 20,
		next: 0,
		pre: 0,
		page: 1,
		pages: [],
	})

	const getBoard = async (page = 1) => {
		try {
			pagination.value.page = page;

			const params = new URLSearchParams();
		
			params.append('limit', pagination.value.limit);
			params.append('page', pagination.value.page);

			const response = await server({
				url: `/api/public/reference`,
				method: 'GET',
				params
			})
			.then(res => res.data.result);

			articles.value = response.rows;
			pagination.value = Object.assign({}, {
			...pagination.value,
			...response.pages
		});
		} catch(e) {
			console.log(e);
		}
	}

	onMounted(() => {
		getBoard();
	})
</script>

<template>
	<div class="article_list_body faq">
		<ul class="article_head articles">
			<li>
				<div class="no">NO</div>
				<div class="subject">
					<TranslateText
						source="ko"
						contents="제목"
					/>
				</div>
				<div class="attach_file">
					<TranslateText
						source="ko"
						contents="파일1"
					/>
				</div>
				<div class="attach_file">
					<TranslateText
						source="ko"
						contents="파일2"
					/>
				</div>
				<div class="attach_file">
					<TranslateText
						source="ko"
						contents="파일3"
					/>
				</div>
				<div class="date">
					<TranslateText
						source="ko"
						contents="등록일"
					/>
				</div>
			</li>
		</ul>
		<ul v-if="articles.length > 0" class="article_body articles mb-lg-5 mb-3">
			<li v-for="(item, key) in articles" :key="item.id">
				<router-link
					:to="`/intro/reference/${item.id}`"
					:class="`faq${item.id}`"
				>
					<div class="no">{{ key + 1 }}</div>
					<div class="subject ta_l">
						<TranslateText
							source="ko"
							:contents="item.subject"
						/>
						<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" class=" ms-1"><path fill="#e11837 " d="M9 7v10h2v-5l2 5h2V7h-2v5l-2-5H9M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2Z"/></svg>
					</div>
					<div class="attach_file">
						<svg v-if="item.file1_id" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#666" d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m4 18H6V4h7v5h5v11Z"/></svg>
					</div>
					<div class="attach_file">
						<svg v-if="item.file2_id" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#666" d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m4 18H6V4h7v5h5v11Z"/></svg>
					</div>
					<div class="attach_file">
						<svg v-if="item.file3_id" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#666" d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m4 18H6V4h7v5h5v11Z"/></svg>
					</div>
					<div class="date">{{ item.createdAt.substr(0,10) }}</div>
				</router-link>
			</li>
		</ul>
		<div v-else class="ta_c">
			<p class="pt-5 pb-2">
			<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="#ccc" d="M12 17q.425 0 .713-.288T13 16q0-.425-.288-.713T12 15q-.425 0-.713.288T11 16q0 .425.288.713T12 17Zm-1-4h2V7h-2v6Zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"/></svg>
			</p>
			<TranslateText
				source="ko"
				contents="등록된 데이터가 없습니다."
			/>
		</div>

		<!-- // pagination -->
	</div>

	<Pagination
		:pagination="pagination"
		@click="getBoard"
	/>
</template>