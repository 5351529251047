let env = 'http://192.168.219.136:3002';
let env_nip = 'https://192.168.219.136.nip.io';
let env_domain = 'https://dsu_class.test.nip.io:8080';
let env_sns = 'https://192.168.219.136:8080';

if( process.env.NODE_ENV == 'production' ) {
console.log("Production Mode");
	env = 'https://startupclass.kr';
	env_nip = 'https://startupclass.kr';
	env_sns = 'https://startupclass.kr';

} else if( process.env.NODE_ENV == 'development' ) {
console.log("Development Mode");
	env = 'http://192.168.219.136:3002';
	env_nip = 'http://192.168.219.136.nip.io:8081';
	env_sns = 'http://192.168.219.136:8080';

} else if( process.env.NODE_ENV == 'local' ) {
console.log("Localhost Mode");
	env = 'http://192.168.219.136:3002';
	env_nip = 'http://192.168.219.136.nip.io:8080';
	env_sns = 'http://192.168.219.136:8080';
}

export { env, env_nip, env_domain, env_sns };