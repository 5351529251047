<script setup>
	import { ref, computed } from 'vue';

	import { useCommonStore } from '@/store/common.pinia';

	import { env as SERVER_URL } from '@/components/utils/env';

	import TranslateText from '@/components/common/translate_text.vue';

	import server from '@/api/server';

	const props = defineProps({
		modelValue: {
			type: [String, Number, Boolean, Array, Object],
			default: "",
		},
		item: Object,
	})

	const emit = defineEmits(["update:modelValue", "change"]);

	const computedValue = computed({
		get: () => props.modelValue,
		set: (value) => {
			emit("update:modelValue", value);
		},
	});

	const commonStore = useCommonStore();

	const language = computed(() => props.item.Course?.CourseDetails?.findIndex(v => v.language === commonStore.lang))

	const del_item = async () => {
		try {
			const params = new URLSearchParams();
			params.append("course_id", props.item.Course.id);

			await server
				.patch(`/api/v1/course/cart`, params)
				.then((response) => response.data.result);

				toast.fire({
					icon: "success",
					title: "수강바구니 삭제",
				});

				emit('change');
		} catch(e) {
			console.log(e);
			toast.fire({
				icon: "error",
				title: "오류 발생!",
			});
		}
	}
</script>

<template>
	<li>
		<div class="cart_detail">
			<div class="cart_check">
				<input
					type="checkbox"
					aria-label="checked"
					v-model="computedValue"
					:value="item.Course?.id"
				>
			</div>
			<!-- idx, item?.Course?.id -->
			<div
				class="img_wrap"
				style="
					height: 100%;
				"
				:style="{
					'background': `url(${SERVER_URL}${item.Course?.Thumbnail?.path})`,
					'background-size': 'cover',
					'background-position': 'center',
					'background-repeat': 'no-repeat',
				}"
			>
			</div>

			<div class="cart_desc">
				<div class="title">
					<TranslateText
						source="ko"
						:contents="Array.isArray(item?.CourseDetails) && (item.CourseDetails[language]?.title || '제목을 입력하세요.')"
					/>
					<TranslateText
						source="ko"
						:contents="item?.Course?.CourseDetails[language]?.title || '제목을 입력하세요.'"
					/>
				</div>
				<div class="teacher">
					{{ item?.Course?.teacher_name || '-' }}
				</div>
				<div class="period">
					<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="#444" d="M19 4h-2V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3Zm1 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7h16Zm0-9H4V7a1 1 0 0 1 1-1h2v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h2a1 1 0 0 1 1 1Z"/></svg>

					<TranslateText
						source="ko"
						contents="수강기간"
					/>
					<span>:</span>
					<span v-if="item?.Course?.study_day">
						{{ Number(item?.Course?.study_day).toLocaleString() }}
						<TranslateText
							source="ko"
							contents="일"
						/>
					</span>
					<span v-else>
						-
					</span>
				</div>
			</div>
			<div class="cart_uncheck">
				<button type="button" aria-hidden="true" :title="`check-${item.Course.id}`" @click="del_item()">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" d="m7 7l10 10M7 17L17 7"/></svg>
				</button>
			</div>
			<div class="cart_price">
				<div class="price">
					<TranslateText
						v-if="item.Course?.is_free == 1"
						source="ko"
						contents="무료"
					/>
					<span v-else>
						{{ `￦ ${Number(item.Course?.price).toLocaleString()}` }}
					</span>
				</div>
				<!-- <div class="origin">
					69,000
				</div> -->
			</div>
		</div>
		
	</li>
</template>