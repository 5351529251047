<script setup>

import { onMounted, ref, computed } from "vue"
import { useRoute, useRouter } from "vue-router"

import { useI18n } from 'vue-i18n';

import { useAuthStore } from "@/store/auth.pinia";
import { useCommonStore } from "@/store/common.pinia";

import { env as SERVER_URL } from "@/components/utils/env";

import TranslateText from '@/components/common/translate_text.vue';

const { t } = useI18n();

const routes = useRoute();
const router = useRouter();

const authStore = useAuthStore();
const commonStore = useCommonStore();

const is_login = computed(() => !!authStore.user?.name);

console.log('menu.vue is_login', is_login);

const logout = () => {
	Swal.fire({
		title: t('confirm:logout'),
		text: 'Logout',
		icon: 'question',

		showCancelButton: true, // cancel버튼 보이기. 기본은 원래 없음
		cancelButtonText: t('cancel'), // cancel 버튼 텍스트 지정
		cancelButtonColor: '#d33', // cancel 버튼 색깔 지정
		confirmButtonText: t('confirm'), // confirm 버튼 텍스트 지정
		confirmButtonColor: '#3085d6', // confrim 버튼 색깔 지정

		reverseButtons: false, // 버튼 순서 거꾸로

	}).then(async (result) => {
	// 만약 Promise리턴을 받으면,
		if (result.isConfirmed) { // 만약 모달창에서 confirm 버튼을 눌렀다면
			await post_logout();
		}
	});
}

const post_logout = async () => {
	try {
		await authStore.logout();

		toast.fire({
			icon: "success",
			title: "로그아웃!",
		});
	} catch(e) {
		if(e.response.data.result.code == 401) {
			toast.fire({
				icon: "success",
				title: "로그아웃!",
			});
		}
	} finally {
		router.push("/member/login");
	}
	
}

const common_script = () => {
	if(document.querySelector('.menu_member > button') !== null) {
		document.querySelector('.menu_member > button').addEventListener('click', function() {
			document.querySelector('.member_pop').classList.toggle('on');
		});
	}

	if(document.querySelector('.head_m .menu') !== null) {
		document.querySelector('.head_m .menu').addEventListener('click', function() {
			document.querySelector('.nav_m').classList.toggle('on');
		});
	}
	if(document.querySelector('.nav_m_close') !== null) {
		document.querySelector('.nav_m_close').addEventListener('click', function() {
			document.querySelector('.nav_m').classList.toggle('on');
		});
	}
	if(document.querySelector('.nav_m > ul > li > a.hasChild') !== null) {
		document.querySelector('.nav_m > ul > li > a.hasChild').addEventListener('click', function(e) {
			e.preventDefault();
			this.classList.toggle('on');
			//nextElementSibling
		});
	}

	if(document.querySelector('.popup_close') !== null) {
		document.querySelector('.popup_close').addEventListener('click', function() {
			document.querySelector('.bg_fix').classList.toggle('d-block');
			document.querySelector('.popup_wrap').classList.toggle('d-block');
		});
	}


	if(routes.path.indexOf('/member') != -1) {
		// document.querySelector('body').classList.add('bg-body-secondary');
	}
}

onMounted(async() => {
	if(localStorage.getItem('lang')) {
		lang.value = localStorage.getItem('lang');
	}
	common_script();
})
</script>

<template>
	<div class="inner_wrap">
		<div class="head">
			<div class="header_logo">
				<router-link to="/" class="">
					1
				</router-link>
			</div>

			<ul class="header_menu ms-5">
				<li>
					<router-link to="/lecture">
						<TranslateText
							source="ko"
							contents="전체 강좌"
						/>
					</router-link>
				</li>
				<li>
					<router-link to="/intro">
						<TranslateText
							source="ko"
							contents="시스템 소개"
						/>
					</router-link>
				</li>
				<li>
					<router-link to="/board/notice">
						<TranslateText
							source="ko"
							contents="커뮤니티"
						/>
					</router-link>
				</li>
			</ul>

			<div class="right_menu">
				<div class="user_menu me-2">
					<div v-if="!is_login" class="menu_guest">
						<router-link to="/member/login" class="hd_login_btn">
							<TranslateText
								source="ko"
								contents="로그인"
							/>
							<span>/</span>
							<TranslateText
								source="ko"
								contents="회원가입"
							/>
						</router-link>
					</div>
					<div v-else class="menu_member">
						<button aria-label="account_menu_button" title="account_menu_button" type="button">
							<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16"><g fill="white"><path d="M11 6a3 3 0 1 1-6 0a3 3 0 0 1 6 0z"/><path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/></g></svg>
						</button>

						<div class="member_pop">
							<ul>
								<li class="member_myid">
									<router-link to="/mypage/info">
										<i class="fa fa-user-circle-o fw-semibold" aria-hidden="true"></i>
										{{ authStore.user?.name || '' }}
									</router-link>
								</li>
								<li>
									<router-link to="/mypage/info">
										<i class="fa fa-id-card-o fw-semibold" aria-hidden="true"></i>
										<TranslateText
											source="ko"
											contents="개인정보 수정"
										/>
									</router-link>
								</li>
								<li>
									<router-link to="/mypage/room">
										<i class="fa fa-graduation-cap fw-semibold" aria-hidden="true"></i>
										<TranslateText
											source="ko"
											contents="내 강의실"
										/>
									</router-link>
								</li>
								<li>
									<router-link to="/mypage/cart">
										<i class="fa fa-shopping-cart fw-semibold" aria-hidden="true"></i>
										<TranslateText
											source="ko"
											contents="수강바구니"
										/>
									</router-link>
								</li>
								<li>
									<router-link to="/mypage/like">
										<i class="fa fa-heart-o fw-semibold" aria-hidden="true"></i>
										<TranslateText
											source="ko"
											contents="좋아요"
										/>
									</router-link>
								</li>
								<li class="member_logout">
									<router-link to="#" @click="logout()">
										<i class="fa fa-sign-out fw-semibold" aria-hidden="true"></i>
										<TranslateText
											source="ko"
											contents="로그아웃"
										/>
									</router-link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<ul class="global_menu">
					<li v-if="commonStore.lang=='ko'">
						<div tabindex="0" class="hd_lang_btn" @click="commonStore.onChangeLang()">
							<span>KR</span>
							<img src="@/assets/img/layout/kr_flag.svg" alt="KR" width="20" height="20" class="ms-1">
						</div>
					</li>
					<li v-else>
						<div tabindex="0" class="hd_lang_btn" @click="commonStore.onChangeLang()">
							<span>EN</span>
							<img src="@/assets/img/layout/us_flag.svg" alt="EN" width="20" height="20">
						</div>
					</li>
				</ul>
			</div>
		</div>

		<h1 class="head_m">
			<router-link to="/" class="logo">
				<img src="@/assets/img/layout/header_logo.png" alt="동서대학교 로고">
			</router-link>
			<button class="menu" aria-label="mobile_logo_button" title="mobile_logo_button">
				<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 256 256"><path fill="white" d="M228 128a12 12 0 0 1-12 12H40a12 12 0 0 1 0-24h176a12 12 0 0 1 12 12ZM40 76h176a12 12 0 0 0 0-24H40a12 12 0 0 0 0 24Zm176 104H40a12 12 0 0 0 0 24h176a12 12 0 0 0 0-24Z"/></svg>
			</button>
		</h1>

		<nav class="nav_m shadow-lg">
			<div class="nav_m_head">
				<h1 class="nav_m_logo">
					<router-link to="/" class="logo">
						<img src="@/assets/img/layout/header_logo.png" alt="동서대학교 로고">
					</router-link>
				</h1>
				<ul class="m_global_menu">
					<li v-if="commonStore.lang=='ko'">
						<div tabindex="0" @click="commonStore.onChangeLang()">
							<span>KR</span>
							<img src="@/assets/img/layout/kr_flag.svg" alt="KR" width="20" height="20" class="ms-1">
						</div>
					</li>
					<li v-else>
						<div tabindex="0" @click="commonStore.onChangeLang()">
							<span>EN</span>
							<img src="@/assets/img/layout/us_flag.svg" alt="EN" width="20" height="20">
						</div>
					</li>						
				</ul>
			</div>
			<div class="m_user_menu">
				<div v-if="!is_login" class="m_member_info" @click="router.push('/member/login')">
					<i class="fa fa-user fw-semibold" aria-hidden="true"></i>
					<TranslateText
						source="ko"
						contents="로그인해주세요."
					/>
				</div>
				<div v-else class="m_member_btns">
					<router-link to="/mypage/room" class=""> 
						<TranslateText
							source="ko"
							contents="내 강의실"
						/>
					</router-link>
					<router-link to="/mypage/info" class="">
						<TranslateText
							source="ko"
							contents="개인정보 수정"
						/>
					</router-link>
				</div>
			</div>
			<button type="button" class="nav_m_close" aria-hidden="true" title="mobile_navi_close_button">
				<i class="fa fa-times" aria-hidden="true"></i>
			</button>
			<ul>
				<li>
					<router-link to="/lecture" class="">
						<TranslateText
							source="ko"
							contents="전체 강좌"
						/>
					</router-link>
				</li>
				<li>
					<router-link to="/intro" class="">
						<TranslateText
							source="ko"
							contents="시스템소개"
						/>
					</router-link>
				</li>
				<li>
					<router-link to="/board/notice" class="hasChild">
						<TranslateText
							source="ko"
							contents="커뮤니티"
						/>
					</router-link>
					<!-- <ul>
						<li>
							<router-link to="/board/notice">
								<TranslateText
									source="ko"
									contents="공지사항"
								/>
							</router-link>
						</li>
						<li>
							<router-link to="/board/faq">
								<TranslateText
									source="ko"
									contents="자주묻는질문"
								/>
							</router-link>
						</li>
						<li>
							<router-link to="/board/qna">
								<TranslateText
									source="ko"
									contents="1:1 문의"
								/>
							</router-link>
						</li>
					</ul> -->
				</li>
				<li v-if="is_login" class="member_logout">
					<router-link to="#" @click="logout()">
						<TranslateText
							source="ko"
							contents="로그아웃"
						/>
					</router-link>
				</li>
			</ul>
		</nav>
	</div>
</template>