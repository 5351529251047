<script setup>

import { onMounted, ref, computed, watch } from "vue";
import { useRoute, useRouter } from 'vue-router';

import { useI18n } from 'vue-i18n';

import { useAuthStore } from '@/store/auth.pinia'
import { useCommonStore } from '@/store/common.pinia';

import { env as SERVER_URL } from '@/components/utils/env';
import Pagination from '@/components/common/pagination';

import TranslateText from '@/components/common/translate_text.vue';

import LectureBox from '@/partials/lecture_box';

import server from '@/api/server';

const routes = useRoute();
const router = useRouter();

const { t } = useI18n();

const authStore = useAuthStore();
const commonStore = useCommonStore();

const is_login = computed(() => !!authStore.user?.name);
const courseType = computed(() => commonStore.code.filter(v => v.main_code === 'CTGY' && v.sub_code != 0));

const searchModel = ref({
	order: 'newer'
})

const categories = ref([])
const articles = ref({})
const likes = ref([])
const carts = ref([])

const pagination = ref({
	limit: 20,
	next: 0,
	pre: 0,
	page: 1,
	pages: [],
})

watch(
	() => routes.params?.type,
	(nVal) => {
		getArticles();

		if(!nVal) return;
		getCategories();
	}
)

watch(
	() => routes.params?.category,
	(nVal) => {
		getArticles();
	}
)

const getCategories = async () => {
	const params = new URLSearchParams();
	params.append('type_id', routes.params?.type || '');

	let response = await server.get(`/api/public/category`, { params })
	categories.value = response.data.result;
}

/**
 * 강의 가져오기
 * 20개까지
 */
const getArticles = async (page = 1) => {
	try {
		pagination.value.page = page;

		const params = new URLSearchParams();
		if(searchModel.value != undefined) {
			Object.keys(searchModel.value).map(v => {
				params.append(v, searchModel.value[v]);
			});
		}

		params.append('limit', pagination.value.limit);
		params.append('page', pagination.value.page);
		
		params.append('category_id', routes.params?.category || '');
		params.append('type_id', routes.params?.type || '');

		// 231010 공개로 변경
		const response = await server({
			url: `/api/public/course`,
			method: 'GET',
			params
		})
		.then(res => res.data.result);

		articles.value = response;
		pagination.value = Object.assign({}, {
			...pagination.value,
			...response.pages
		});

		console.log(response);
	} catch(e) {

	}
	
/*	
	if(is_login == true) {
		response = await server.get(`/api/v1/course`, { params })
		if( response.data.result.rows.length > 0 ) {
		response.data.result.rows.forEach(function(value, key){
			if(value.CourseFav) {
					likes.value.push(value.id);
				}
				if(value.CourseCart) {
					carts.value.push(value.id);
				}
			});
		}
		articles.value = response.data.result;
	} else {
		response = await server.get(`/api/public/course`, { params })
		articles.value = response.data.result;
	}
*/	
// console.log( response.data.result);
}

onMounted(async() => {
	getArticles();
	getCategories();
})
</script>

<template>
	<include-header/>

	<div id="sub_wrap">
		<div class="inner_wrap">
			<div class="class_list_wrap">
				<div class="class_head">
					<div class="class_head_top sub_page_top">
						<div class="title">
							<TranslateText
								source="ko"
								contents="프로그램"
							/>
						</div>
						<form class="search_form search_wrap has_float m_fl_f1" action="#" @submit.prevent="getArticles()">
							<input
								type="text"
								aria-label="search_word"
								v-model="searchModel.title"
								:placeholder="t('placeholder:search')"
							>
							<button
								type="button"
								@click="getArticles()"
								class="btn bg_red white"
							>
								<TranslateText
									source="ko"
									contents="검색"
								/>
							</button>
						</form>
					</div>
					<div class="class_category">
						<!-- <router-link to="/lecture"
							v-if="categories.length>0" type="button" 
							class="btn"
							:class="{
								'on': props.category < 1
							}">
							전체
						</router-link>
						<router-link
							:to="`/lecture/${item.id}`"
							v-for="item in categories" type="button"
							:key="`link-${item.id}`"
							class="btn"
							:class="{
								//'on': choiced_index === item.id,
								'on': props.category == item.id
							}">
							{{ item.name }}
						</router-link> -->

						<ul class="row m-0 text-center fs-5 fw-bold bg-white">
							<li
								class="col ma-0 py-3 border-start border-top border-bottom"
								:class="{
									'active': !routes.params.type
								}"
							>
								<button
									class="btn_reset"
									@click="router.push({ path: `/lecture` })"
								>
									<TranslateText
										source="ko"
										contents="전체"
									/>
								</button>
							</li>
							<li
								v-for="(course_type, key) in courseType"
								:key="`course_type-${course_type.id}`"
								class="col py-3 bg-white border-start border-top border-bottom"
								:class="{
									'border-end': key === (courseType.length - 1),
									'active': course_type.id == routes.params.type
								}"
							>
								<button
									class="btn_reset"
									@click="router.push({ path: `/lecture/${course_type.id}` })"
								>
									<TranslateText
										source="ko"
										:contents="course_type.name"
									/>
								</button>
							</li>
						</ul>
						
						<!-- 강의 밑 태그 -->
						<div
							v-if="routes.params?.type"
							class="main_keyword_tag border border-top-0"
						>
							<ul class="main_keyword_tag_list pb-0 mb-0"  style="background: #edf1f3;">
								<button
									class="tag"
									:class="{
										'active': !routes.params?.category
									}"
									@click="router.push({ path: `/lecture/${routes.params.type}` })"
								>
									<TranslateText
										source="ko"
										contents="전체"
									/>
								</button>
								<button
									v-for="category in categories"
									:key="`category-${category.id}`"
									class="tag"
									:class="{
										'active': routes.params?.category == category.id
									}"
									@click="router.push({ path: `/lecture/${routes.params?.type || category.Code.id}/${category.id}` })"
								>
									<TranslateText
										source="ko"
										:contents="category.name"
									/>
								</button>
							</ul>
						</div>
					</div>
				</div>
				<div class="class_body">
					<div class="class_body_top">
						<div class="class_count">
							<i class="fa fa-list-ul" aria-hidden="true"></i>
							Total 
							<span class="red">{{ articles.count }}</span>
							<span> / </span>
							{{ articles?.pages?.pages }} Page
						</div>
						<div class="class_order">
							<select v-model="searchModel.order" @change="getArticles()">
								<option value="newer">
									<TranslateText
										source="ko"
										contents="최신순"
									/>
								</option>
								<!-- <option value="trend">인기순</option> -->
								<option value="older">
									<TranslateText
										source="ko"
										contents="오래된 순"
									/>
									
								</option>
								<option value="lower">
									<TranslateText
										source="ko"
										contents="가격 낮은순"
									/>
								</option>
								<option value="higher">
									<TranslateText
										source="ko"
										contents="가격 높은순"
									/>
								</option>
							</select>
						</div>
					</div>
					<div class="class_body_content">
						<ul v-if="articles.count > 0" class="classes_list new">
							<LectureBox
								v-for="item in articles.rows"
								:key="`lecture-${item.id}`"
								:item="item"
								@change="getArticles(pagination.page)"
							/>
						</ul>

						<!--강의없을때-->
						<div v-else class="text-center py-5 my-5 text-secondary fs-5">
							<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" class="inline-block mb-3"><path fill="#666" d="M12 17q.425 0 .713-.288T13 16q0-.425-.288-.713T12 15q-.425 0-.713.288T11 16q0 .425.288.713T12 17Zm-1-4h2V7h-2v6Zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"/></svg><br>
							<TranslateText
								source="ko"
								contents="등록된 강의가 없습니다."
							/>
							<br>
							<TranslateText
								source="ko"
								contents="곧 강의를 준비할게요!"
							/>
						</div>
					</div>
					

					<!-- 페이지네이션 -->
					<Pagination
						:pagination="pagination"
						@click="getArticles"
					/>
				</div>
					
			</div>
		</div>

	</div><!-- //#sub_wrap -->

	<include-footer/>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer
	}
}
</script>