<script setup>
import { env as SERVER_URL } from "@/components/utils/env";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router"

import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, minValue, maxValue, email, sameAs } from "@/components/utils/ko-vuelidate";

import TranslateText from '@/components/common/translate_text.vue';

const formModel = ref({
	checkAll:false,
	check1:false,
	check2:false,
});

const rules = {
	checkAll: { required },
	check1: { required },
	check2: { required },
};
const v$ = useVuelidate(rules, formModel.value)

const router = useRouter()

const goto_join = async () => {
	const result = formModel.value.check1 && formModel.value.check2;//await v$.value.$validate();

	if( !result ) {
		toast.fire({
			icon: "error",
			title: "동의가 필요합니다.",
		});
		return;
	}
	router.push('/member/join');
}

const checkAllBtn = () => {
console.log(formModel.value.checkAll);
	formModel.value.check1 = !formModel.value.checkAll;
	formModel.value.check2 = !formModel.value.checkAll;
}

const onChange = () => {
	if(formModel.value.check1&&formModel.value.check2) {
		formModel.value.checkAll = true;
	} else {
		formModel.value.checkAll = false;
	}
}
</script>
<template>
	<include-header/>
	
	<div id="member_wrap">
		<div class="join_container">
			<div class="join_wrap bg_w ">
				<div class="sub_tit">
					<TranslateText
						source="ko"
						contents="약관 동의"
					/>
				</div>
				<ul class="join_rule">
					<li class="chk_all">
						<input type="checkbox" id="checkAll" v-model="formModel.checkAll" @click="checkAllBtn()">
						<label for="checkAll" class="txt_v">
							<TranslateText
								source="ko"
								contents="약관에 모두 동의합니다."
							/>
						</label>
					</li>
					<li class="chk">
						<input type="checkbox" id="check1" v-model="formModel.check1" @change="onChange()">
						<label for="check1">
							[<TranslateText
								source="ko"
								contents="필수"
							/>]
							<TranslateText
								source="ko"
								contents="개인정보 수집 및 이용약관 동의"
							/>
						</label>
					</li>
					<li class="rule_detail">
						<textarea style="height: 500px;" readonly>
		DSU 창업온라인강좌('https://dsuonline.kr'이하 '창업온라인')은(는) 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.
		'창업온라인'은(는) 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
		○ 본 방침은부터 2023년 10월 1일부터 시행됩니다.

		1. 개인정보의 처리 목적 'DSU 창업온라인강좌'(https://dsuonline.kr, 이하 '창업온라인')은(는) 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.

		가. 홈페이지 회원가입 및 관리
		회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.

		나. 민원사무 처리
		민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등을 목적으로 개인정보를 처리합니다.

		다. 재화 또는 서비스 제공
		서비스 제공, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산, 채권추심 등을 목적으로 개인정보를 처리합니다.

		라. 마케팅 및 광고에의 활용
		신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.

		마. 개인영상정보
		범죄의 예방 및 수사, 시설안전 및 화재예방 등을 목적으로 개인정보를 처리합니다.

		2. 개인정보 파일 현황
		개인정보 파일명 : privacy
		- 개인정보 항목 : 이메일, 휴대전화번호, 자택주소, 자택전화번호, 비밀번호 질문과 답, 비밀번호, 로그인ID, 성별, 생년월일, 이름, 기념일, 결혼여부, 취미, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록
		- 수집방법 : 홈페이지, 서면양식, 전화/팩스, 경품행사, 배송요청
		- 보유근거 : 홈페이지이용방침
		- 보유기간 : 5년
		- 관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년, 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년, 대금결제 및 재화 등의 공급에 관한 기록 : 5년, 계약 또는 청약철회 등에 관한 기록 : 5년, 표시/광고에 관한 기록 : 6개월

		3. 개인정보의 처리 및 보유 기간
		① 'DSU 창업온라인강좌'(이하 '창업온라인')은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.

		② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
		1.홈페이지 회원가입 및 관리
		홈페이지 회원가입 및 관리와 관련한 개인정보는 수집.이용에 관한 동의일로부터 지체없이 파기까지 위 이용목적을 위하여 보유.이용됩니다.
		-보유근거 : 홈페이지 이용방침
		-관련법령 :
		1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
		2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
		3) 대금결제 및 재화 등의 공급에 관한 기록 : 5년
		4) 계약 또는 청약철회 등에 관한 기록 : 5년
		5) 표시/광고에 관한 기록 : 6개월

		-예외사유 : 없음

		4. 개인정보의 제3자 제공에 관한 사항
		① 'DSU 창업온라인강좌'(https://dsuonline.kr, 이하 '창업온라인')은(는) 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
		② '창업온라인'은(는) 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.

		5. 개인정보처리 위탁
		① 'DSU 창업온라인강좌'(이하 '창업온라인')은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
		② '창업온라인'은(는) 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
		③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.

		6. 정보주체와 법정대리인의 권리·의무 및 그 행사방법 이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.
		① 정보주체는 창업온라인에 대해 언제든지 개인정보 열람,정정,삭제,처리정지 요구 등의 권리를 행사할 수 있습니다.
		② 제1항에 따른 권리 행사는 창업온라인에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 '창업온라인'은(는) 이에 대해 지체 없이 조치하겠습니다.
		③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
		④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
		⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
		⑥ '창업온라인'은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.

		7. 처리하는 개인정보의 항목 작성
		① 'DSU 창업온라인강좌'(https://dsuonline.kr, 이하 '창업온라인')은(는) 다음의 개인정보 항목을 처리하고 있습니다.
		1)홈페이지 회원가입 및 관리
		- 필수항목 : 로그인ID, 비밀번호, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록
		- 선택항목 : 이름, 이메일, 휴대전화번호, 소속, 직위

		8. 개인정보의 파기'DSU 창업온라인강좌'(이하 '창업온라인')은(는) 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
		-파기절차
		이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.

		-파기기한
		이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.

		-파기방법
		전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.
		종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.

		9. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항
		DSU 창업온라인강좌은 정보주체의 이용정보를 저장하고 수시로 불러오는 '쿠키'를 사용하지 않습니다.

		10. 개인정보 보호책임자 작성
		① DSU 창업온라인강좌('https://dsuonline.kr' 이하 '창업온라인')은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
		② 정보주체께서는 DSU 창업온라인강좌('https://dsuonline.kr'이하 '창업온라인') 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. '창업온라인'은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.

		11. 개인정보 처리방침 변경
		①이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
						</textarea>
					</li>
					<li class="chk">
						<input type="checkbox" id="check2" v-model="formModel.check2" @change="onChange()">
						<label for="check2">
							[<TranslateText
								source="ko"
								contents="필수"
							/>]
							<TranslateText
								source="ko"
								contents="개인정보 취급방침 동의"
							/>
							
						</label>
					</li>
					<li class="table">
						<table>
						<colgroup>
							<col width="30%">
							<col width="30%">
							<col width="30%">
						</colgroup>
						<thead>
							<tr>
							<th>
								<TranslateText
									source="ko"
									contents="목적"
								/>
							</th>
							<th>
								<TranslateText
									source="ko"
									contents="항목"
								/>
							</th>
							<th>
								<TranslateText
									source="ko"
									contents="보유기간"
								/>
							</th>
							</tr>
						</thead>
						<tbody>
							<tr>
							<td>
								<TranslateText
									source="ko"
									contents="이용자 식별 및 본인여부 확인"
								/>
							</td>
							<td>
								<TranslateText
									source="ko"
									contents="아이디"
								/>,
								<TranslateText
									source="ko"
									contents="이름"
								/>,
								<TranslateText
									source="ko"
									contents="비밀번호"
								/>
							</td>
							<td>
								<TranslateText
									source="ko"
									contents="회원탈퇴시까지"
								/>
							</td>
							</tr>
							<tr>
							<td>
								<TranslateText
									source="ko"
									contents="고객서비스 이용에 관한 통지, CS 대응을 위한 이용자 식별"
								/>
							</td>
							<td>
								<TranslateText
									source="ko"
									contents="연락처"
								/>,
								<TranslateText
									source="ko"
									contents="이메일"
								/>,
								<TranslateText
									source="ko"
									contents="휴대전화번호"
								/>
							</td>
							<td>
								<TranslateText
									source="ko"
									contents="회원탈퇴시까지"
								/>
							</td>
							</tr>
						</tbody>
						</table>
					</li>
					<li>
						<button class="btn_v" @click="goto_join()">
							<TranslateText
								source="ko"
								contents="동의하고 가입하기"
							/>
						</button>
					</li>
				</ul>
			</div>
		</div>
	</div>
	
	<include-footer/>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer
	}
}
</script>