<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { env as SERVER_URL } from '@/components/utils/env';
import TranslateText from '@/components/common/translate_text.vue';

import server from '@/api/server';

const route = useRoute()
const router = useRouter();

const props = defineProps({
	category:{
		type:String,
		default:''
	},
	bd_id:{
		type:Number,
		default:0
	}
})

let tabNum = ref(1)
let tabName = ref('공지사항')

let article = ref([])

console.log(props.bd_id);

	onMounted(async() => {
		getSingleArticle()
	})

const getSingleArticle = async () => {
	let response = '';
	if(props.category=='notice') {
		tabNum = 1;
		tabName = '공지사항';
		response = await server.get(`/api/public/${props.category}/${props.bd_id}`)
	} else if(props.category=='qna') {
		tabNum = 3;
		tabName = '1:1 문의';
		response = await server.get(`/api/v1/board/${props.category}/${props.bd_id}`)
	}
console.log(response.data.result);
	article.value = response.data.result;
}
</script>
<template>
	<include-header/>

	<div id="sub_wrap">
		<div class="inner_wrap">

			<include-board-header/>
			<p class="sub_menu_text">
				<span v-if="tabNum=='1'">
					<TranslateText
						source="ko"
						contents="DSU 동서대 창업 온라인 강좌와 관련된 공지사항을 알려드립니다."
					/>
				</span>
				<span v-if="tabNum=='3'">
					<TranslateText
						source="ko"
						contents="DSU 동서대 창업 온라인 강좌서비스 내 문의나 요청사항을 보내주시면 처리 후 안내해드리겠습니다."
					/>
					<br/>
					<TranslateText
						source="ko"
						contents="문의하신 사항에 대한 답변은 입력해주신 이메일과 내 문의내역에서 확인하실 수 있습니다."
					/>
				</span>
			</p>

			<div class="board_view_wrap">

				<div class="article_view_body notice">
					<div class="article_head">
						<div class="subject">
							<TranslateText
								source="ko"
								:contents="article.subject"
							/>
							<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" class=" ms-1"><path fill="#e11837 " d="M9 7v10h2v-5l2 5h2V7h-2v5l-2-5H9M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2Z"/></svg>
						</div>
						<div class="info">
							<div class="date">
								<strong>
									<TranslateText
										source="ko"
										contents="등록일"
									/>
								</strong>
								<span>{{ article.create_date?.substring(0,10) }}</span>
							</div>
							<!-- <div class="count">
								조회 5
							</div> -->
						</div>
					</div>
					<div class="article_file">
						<div v-if="article.File1">
							<a :href="`${SERVER_URL}${article.File1?.path}`">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#666" d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m4 18H6V4h7v5h5v11Z"/></svg> {{ article.File1?.name }}
							</a>
						</div>
						<div v-if="article.File2">
							<a :href="`${SERVER_URL}${article.File2?.path}`">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#666" d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m4 18H6V4h7v5h5v11Z"/></svg> {{ article.File2?.name }}
							</a>
						</div>
						<div v-if="article.File3">
							<a :href="`${SERVER_URL}${article.File3?.path}`">
								<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#666" d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6m4 18H6V4h7v5h5v11Z"/></svg> {{ article.File3?.name }}
							</a>
						</div>
					</div>
					<div class="article_body">
						<pre>
							<TranslateText
								source="ko"
								:contents="article.content"
							/>
						</pre>
					</div>

				</div>

				<div class="bottom_btns">
					<router-link :to="`/board/${props.category}`">
						<TranslateText
							source="ko"
							contents="목록으로"
						/>
					</router-link>
				</div>
			</div>

		</div>

	</div><!-- //#sub_wrap -->

	<include-footer/>

</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'
import board from '../layout/board.vue'

export default {
	name: 'App',
	components: {
	'include-header': header,
	'include-footer': footer,
	'include-board-header': board,
	}
}
</script>