<script setup>
	import { ref, computed } from 'vue';
	import { useRoute, useRouter } from 'vue-router'

	import { useAuthStore } from '@/store/auth.pinia';
	import { useCommonStore } from '@/store/common.pinia';

	import { env as SERVER_URL } from '@/components/utils/env';
	import TranslateText from '@/components/common/translate_text.vue';

	import server from '@/api/server';

	const props = defineProps({
		item: Object
	})

	const emit = defineEmits(['change'])

	const routes = useRoute();
	const router = useRouter();

	const authStore = useAuthStore();
	const commonStore = useCommonStore();

	const is_login = computed(() => !!authStore.user?.name);
	const is_name = computed(() => authStore.user?.name);
	const language = computed(() => props.item.CourseDetails?.findIndex(v => v.language === commonStore.lang))

	const addLike = async (id, included) => {
		try {
			if(!is_login.value) return;

			const params = new URLSearchParams();
			params.append("course_id", id);

			const response = await server({
				url: `/api/v1/course/like`,
				method: 'PATCH',
				data: params,
			})

			if(response.message == "OK") {
				if(included) {
					toast.fire({
						icon: "success",
						title: "좋아요 해제",
					});
				} else {
					toast.fire({
						icon: "success",
						title: "좋아요!",
					});
				}
			}

			emit('change');
		} catch(e) {
			console.log(e);
			toast.fire({
				icon: "error",
				title: "오류 발생!",
			});
		}
	}

	const addCart = async (id, included) => {
		try {
			// if(!is_login.value) {
			// 	router.push({ path: '/member/login' });

			// 	return;
			// }

			/**
			 * 이미 강의신청한 경우
			 * 강의듣기로 바로 이동
			 */
			if(props.item.CourseRegist?.createdAt) {
				router.push({ path: `/lecture/see/${id}` });

				return;
			}

			const params = new URLSearchParams();
			params.append("course_id", id);

			const response = await server({
				url: `/api/v1/course/cart`,
				method: 'PATCH',
				data: params
			})
			.then(res => res.data.result);

			if(response.message === "OK") {
				if(included) {
					toast.fire({
						icon: "success",
						title: "수강바구니 삭제",
					});
				} else {
					toast.fire({
						icon: "success",
						title: "수강바구니 추가!",
					});
				}
			}

			emit('change');
		} catch(e) {
			console.log(e);
			toast.fire({
				icon: "error",
				title: "오류 발생!",
			});
		}
	}
</script>

<template>
	<li>
		<div class="classes_img">
			<div
				class="img_wrap"
				style="height: 100%;"
				:style="{
					'background': `url(${SERVER_URL}${item.Thumbnail?.path})`,
					'background-size': 'cover',
					'background-position': 'center',
					'background-repeat': 'no-repeat',
				}"
			>
			</div>
			<!-- <div class="labels">
				NEW
			</div> -->
			<div class="heart">
				<i 
					class="fa fa-2x"
					aria-hidden="true"
					:class="{
						'fa-heart red': item.CourseFav?.createdAt,
						'fa-heart-o': !item.CourseFav?.createdAt,
					}"
				></i>
			</div>
			<div v-if="is_login" class="classes_hover">
				<button
					type="button"
					:class="{
						'red': item.CourseCart?.createdAt,
						'green': item.CourseRegist?.createdAt,
					}"
					@click="addCart(item.id, item.CourseCart?.createdAt)"
				>
					<template v-if="item.CourseRegist?.createdAt">
						<i class="fa fa-play-circle fa-2x"></i>
						<span>
							<TranslateText
								source="ko"
								contents="강의듣기"
							/>
							
						</span>
					</template>

					<template v-else>
						<i
							class="fa fa-cart-plus fa-2x"
							aria-hidden="true"
						></i>
						<span>
							<TranslateText
								source="ko"
								contents="수강바구니"
							/>
						</span>
					</template>
				</button>
				
				<button
					type="button"
					:class="{
						'red': item.CourseFav?.createdAt
					}"
					@click="addLike(item.id, item.CourseFav?.createdAt)"
				>
					<i 
						class="fa fa-2x"
						aria-hidden="true"
						:class="{
							'fa-heart red': item.CourseFav?.createdAt,
							'fa-heart-o': !item.CourseFav?.createdAt,
						}"
					></i>
					<span>
						<TranslateText
							source="ko"
							contents="좋아요"
						/>
					</span>
				</button>
			</div>
		</div>
		<div
			class="classes_desc"
			style="cursor: pointer;"
		>
			<button
				type="button"
				class="btn_reset"
				@click.stop="router.push({ path: `/lecture/view/${item.id}` })"
			>
				<div class="classes_title">
					<span>
						<TranslateText
							source="ko"
							:contents="Array.isArray(item?.CourseDetails) && (item.CourseDetails[language]?.title || '제목을 입력하세요.')"
						/>
					</span>
				</div>
				<!-- <div class="classes_teacher">
					DSU
				</div> -->
				<div class="classes_price">
					<TranslateText
						v-if="item.is_free == 1"
						source="ko"
						contents="무료"
					/>
					<span v-else>
						{{ `￦ ${Number(item.price).toLocaleString()}` }}
					</span>
				</div>
				<div class="classes_tag">
					<span
						v-for="tag in item.Category.Tags"
						:key="tag.name"
						class="class_tag"
					>
						<TranslateText
							source="ko"
							:contents="tag.name"
						/>
					</span>
				</div>
			</button>
		</div>
	</li>
</template>