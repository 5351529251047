<script setup>
import { useRoute, useRouter } from "vue-router"

import TranslateText from '@/components/common/translate_text.vue';

const route = useRoute();
const router = useRouter();

const onLocation = (path) => {
	router.push({ path });
}
</script>
<template>
	<div class="sub_menu_wrap">
		<ul>
			<li
				:class="{
					'on': route.path=='/intro'
				}"
				style="max-width: 33.3%"
			>
				<button
					@click="onLocation('/intro')"
				>
					<TranslateText
						source="ko"
						contents="시스템 소개"
					/>
				</button>
			</li>
			<li
				:class="{
					'on': route.path=='/intro/guide'
				}"
				style="max-width: 33.3%"
			>
				<button
					@click="onLocation('/intro/guide')"
				>
					<TranslateText
						source="ko"
						contents="이용 가이드"
					/>
				</button>
			</li>
			<li
				:class="{
					'on': route.path=='/intro/reference'
				}"
				style="max-width: 33.3%"
			>
				<button	
					@click="onLocation('/intro/reference')"
				>
				<TranslateText
						source="ko"
						contents="서식 자료실"
					/>
				</button>
			</li>
			<!-- <li><router-link to="/mypage/buy" :class="route.path=='/mypage/buy' || route.path.indexOf('/mypage/buy') != -1?'on':''">구매내역</router-link></li> -->
		</ul>
	</div>
</template>

<script>
export default {
	name: 'App',
	components: {
	}
}
</script>