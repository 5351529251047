<script setup>
import { onMounted, ref, computed } from "vue";

import { useAuthStore } from '@/store/auth.pinia'
import { useCommonStore } from '@/store/common.pinia';

import server from '@/api/server';

import { env as SERVER_URL } from '@/components/utils/env';
import Pagination from '@/components/common/pagination';
import TranslateText from '@/components/common/translate_text.vue';
import LectureBox from '@/partials/lecture_box.vue';

const props = defineProps({
	category:{
		type:String,
		default:''
	}
})

const authStore = useAuthStore();
const commonStore = useCommonStore();

const is_login = computed(() => !!authStore.user?.name);

const searchModel = ref({
	order: 'newer',
})

const articles = ref({})
const pagination = ref({
	limit: 20,
	next: 0,
	pre: 0,
	page: 1,
	pages: [],
})

const getArticles = async (page = 1) => {
	try {
		pagination.value.page = page;

		const params = new URLSearchParams();
		if(searchModel.value != undefined) {
			Object.keys(searchModel.value).map(v => {
				params.append(v, searchModel.value[v]);
			});
		}

		params.append('limit', pagination.value.limit);
		params.append('page', pagination.value.page);

		// 231010 공개로 변경
		const response = await server({
			url: `/api/v1/student/course/like`,
			method: 'GET',
			params
		})
		.then(res => res.data.result);

		articles.value = response;
		pagination.value = Object.assign({}, {
			...pagination.value,
			...response.pages
		});
	} catch(e) {
		console.error(e);
	}
	
}

onMounted(async() => {
	getArticles();
})

</script>
<template>
	<include-header/>

	<div id="sub_wrap">
		<div class="inner_wrap">

			<include-mypage-header/>

			<div class="my_like_wrap">
				<div class="my_like_head">
					<div class="class_body_top">
						<div class="class_count">
							<i class="fa fa-list-ul" aria-hidden="true"></i>
							Total
							<span class="red">
								{{ articles.count }}
							</span>
							<span> / </span>
							{{ pagination?.page }} Page
						</div>
						<div class="class_order">
							<select v-model="searchModel.order" @change="getArticles()">
								<option value="newer">
									<TranslateText
										source="ko"
										contents="강좌상태"
									/>
								</option>
								<option value="older">
									<TranslateText
										source="ko"
										contents="오래된 순"
									/>
								</option>
								<option value="lower">
									<TranslateText
										source="ko"
										contents="가격 낮은순"
									/>
								</option>
								<option value="higher">
									<TranslateText
										source="ko"
										contents="가격 높은순"
									/>
								</option>
							</select>
						</div>
					</div>
				</div>
				
				<div class="class_body">
					<div class="class_body_content">
						<ul v-if="articles?.rows?.length > 0" class="classes_list new">

							<LectureBox
								v-for="item in articles.rows"
								:key="`box-${item.id}`"
								:item="item"
								@change="getArticles"
							/>
						</ul>

						<!--강의없을때-->
						<div v-else class="text-center py-5 my-5 text-secondary fs-5">
							<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" class="inline-block mb-3"><path fill="#666" d="M12 17q.425 0 .713-.288T13 16q0-.425-.288-.713T12 15q-.425 0-.713.288T11 16q0 .425.288.713T12 17Zm-1-4h2V7h-2v6Zm1 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22Zm0-2q3.35 0 5.675-2.325T20 12q0-3.35-2.325-5.675T12 4Q8.65 4 6.325 6.325T4 12q0 3.35 2.325 5.675T12 20Zm0-8Z"/></svg><br>
							<TranslateText
								source="ko"
								contents="좋아요한 강의가 없어요"
							/>
							<br>
							<TranslateText
								source="ko"
								contents="관심있는 강의를  찜해두면 편하게 확인할수있어요 "
							/>
						</div>
					</div>

					<!-- 페이지네이션 -->
					<Pagination
						:pagination="pagination"
						@click="getArticles"
					/>
				</div>
			</div>
		</div>
	</div><!-- //#sub_wrap -->

	<include-footer/>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'
import mypage from '../layout/mypage.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer,
		'include-mypage-header': mypage
	}
}
</script>