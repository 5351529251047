<script setup>

</script>
<template>
	
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer,
	}
}
</script>