import { ref, watchEffect, toValue } from 'vue';

import server from '@/api/server';

const papagoWorker = (source, target, contents) => {
	return new Promise((resolve, reject) => {
		const params = new URLSearchParams();

		params.append('target', target);
		params.append('source', source);
		params.append('contents', contents);

		const result = server({
			url: '/api/translate',
			method: 'POST',
			data: params
		})
		.then(res => resolve(res.data.result.contents))
		.catch(err => reject(contents))
	})
}

export {
	papagoWorker
};