<script setup>
import { onMounted, ref } from "vue"
import { useRoute, useRouter, } from "vue-router"

import { useI18n } from 'vue-i18n';

import { useAuthStore } from '@/store/auth.pinia'
import { useCommonStore } from '@/store/common.pinia';

import { env as SERVER_URL } from '@/components/utils/env';
import Pagination from '@/components/common/pagination';

import TranslateText from '@/components/common/translate_text.vue';

import LecturePlayBox from '@/partials/lecture_play_box';

import server from '@/api/server';

const route = useRoute();
const router = useRouter();

const { t } = useI18n();

const commonStore = useCommonStore();

const searchModel = ref({
	status: '',
	complete: '',
	search_word: '',
	order: 'newer',
})

const articles = ref({})
const likes = ref([])

const pagination = ref({
	limit: 20,
	next: 0,
	pre: 0,
	page: 1,
	pages: [],
})

const getArticles = async (page = 1) => {
	try {
		pagination.value.page = page;

		const params = new URLSearchParams();
		if(searchModel.value != undefined) {
			Object.keys(searchModel.value).map(v => {
				params.append(v, searchModel.value[v]);
			});
		}

		params.append('limit', pagination.value.limit);
		params.append('page', pagination.value.page);
		// params.append('status', 2);

		const response = await server({
			url: `/api/v1/student/course/my`,
			method: 'GET',
			params
		})
		.then(res => res.data.result);

		articles.value = response;
		pagination.value = Object.assign({}, {
			...pagination.value,
			...response.pages
		});
	} catch(e) {

	}	
}

onMounted(async() => {
	getArticles()
})

</script>
<template>
	<include-header/>

	<div id="sub_wrap">
		<div class="inner_wrap">

			<include-mypage-header/>

			<div class="my_room_wrap">
				<div class="my_info_head">
					<ul class="lgray_box row m-0">
						<li class="col-6 col-lg-3 p-0 p-lg-2 mb-2 mb-lg-0">
							<select
								v-model="searchModel.status"
								@change="getArticles()"
								class="w-100"
							>
								<option value="">
									<TranslateText
										source="ko"
										contents="강좌상태"
									/>
								</option>
								<option value="2">
									<TranslateText
										source="ko"
										contents="개시"
									/>
								</option>
								<option value="3">
									<TranslateText
										source="ko"
										contents="완료"
									/>
								</option>
							</select>
						</li>
						<li class="col-6 col-lg-3 p-0  p-lg-2  mb-2 mb-lg-0">
							<select
								v-model="searchModel.complete"
								@change="getArticles()"
								class="w-100"
							>
								<option value="">
									<TranslateText
										source="ko"
										contents="학습상태 기준"
									/>
								</option>
								<option value="1">
									<TranslateText
										source="ko"
										contents="진행"
									/>
								</option>
								<option value="2">
									<TranslateText
										source="ko"
										contents="완료"
									/>
								</option>
							</select>
						</li>
						<li class="col-12 col-lg-6 p-0  p-lg-2 ">
							<div class="search_wrap w-100 row m-0">
								<div class="col-12 col-lg-8 p-0 p-lg-2 mb-2 mb-lg-0">
									<input
										v-model="searchModel.search_word"
										aria-label="search_word"
										type="text"
										id="search_word"
										name="search_word"
										class="calc_full w-100"
										:placeholder="t('placeholder:class_search')"
									/>
								</div>
								<div class="col-12 col-lg-4 p-0 p-lg-2">
									<button
										type="button"
										class="bg_red white w-100"
										title="search button"
										@click="getArticles()"
									>
										<TranslateText
											source="ko"
											contents="검색"
										/>
									</button>
								</div>
							</div>
						</li>
					</ul>
				</div>
				
				<div class="class_body">
					<div class="class_body_top">
						<div class="class_count">
							<i class="fa fa-list-ul" aria-hidden="true"></i>
							Total
							<span class="red">
								{{ articles?.count || 0 }}
							</span>
							<span> / </span>
							{{ pagination?.page }} Page
						</div>
						<div class="class_order">
							<select v-model="searchModel.order" @change="getArticles()">
								<option value="newer">
									<TranslateText
										source="ko"
										contents="강좌상태"
									/>
								</option>
								<option value="older">
									<TranslateText
										source="ko"
										contents="오래된 순"
									/>
								</option>
								<option value="lower">
									<TranslateText
										source="ko"
										contents="가격 낮은순"
									/>
								</option>
								<option value="higher">
									<TranslateText
										source="ko"
										contents="가격 높은순"
									/>
								</option>
							</select>
						</div>
					</div>
					<div class="class_body_content">
						<ul v-if="articles?.count > 0" class="classes_list new">
							<LecturePlayBox
								v-for="(item) in articles.rows"
								:key="item.id"
								:item="item"
							/>
						</ul>
					</div>

					<!-- 페이지네이션 -->
					<Pagination
						:pagination="pagination"
						@click="getArticles"
					/>
				</div>
			</div>
		</div>
	</div><!-- //#sub_wrap -->

	<include-footer/>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'
import mypage from '../layout/mypage.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer,
		'include-mypage-header': mypage
	}
}
</script>