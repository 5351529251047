import { nextTick } from 'vue';
import { createI18n } from 'vue-i18n'

import server from '@/api/server';

// import { useCommonStore } from '@/store/common.pinia'

// const commonStore = useCommonStore();

// const i18n = createI18n({
//   // something vue-i18n options here ...
// 	legacy: false,	// vue3 용 to use ocmposition API
// 	locale: commonStore.lang || 'ko',
// 	fallbackLocale: 'ko',
// })

const i18n = createI18n({
	legacy: false,
	fallbackLocale: 'ko',
	locale: 'ko'
});

export function setI18nLanguage(locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale
  } else {
    i18n.global.locale.value = locale
  }
  /**
   * NOTE:
   * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
   * The following is an example for axios.
   *
   * axios.defaults.headers.common['Accept-Language'] = locale
   */
  document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(locale) {
  // load locale messages with dynamic import
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `../../locales/${locale}.js`
  )

	const params = new URLSearchParams();

	params.append('locale', locale);

	const list = await server({
		url: '/api/translate',
		params
	})
	.then(res => res.data.result)

	const language_data = list.reduce((acc, cur) => {
		acc[cur.text] = cur.translated;

		return acc;
	}, Object.assign({}));

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, { ...messages.default, ...language_data });

  return nextTick()
}

export default i18n;