<script setup>
import { onMounted, ref } from "vue"
import { useRoute, useRouter } from "vue-router"

import { useCommonStore } from '@/store/common.pinia';

import Pagination from '@/components/common/pagination';

import { env as SERVER_URL } from '@/components/utils/env';

import TranslateText from '@/components/common/translate_text.vue';

import server from '@/api/server';

const props = defineProps({
	buy: [String, Number]
})

const routes = useRoute();
const router = useRouter();

const commonStore = useCommonStore();

const paymentItem = ref({});

const getPaymentDetail = async () => {
	try {
		paymentItem.value = await server({
			url: `/api/v1/payment/${props.buy}`,
			method: 'GET'
		})
		.then(res => res.data.result);
	} catch(e) {

	}
}

onMounted(async() => {
	//getReservation(localStorage.getItem('userID'))
	getPaymentDetail();
})
</script>

<template>
	<include-header/>
	<div id="sub_wrap">
		<div class="inner_wrap">

			<include-mypage-header/>

			<div class="my_buy_detail_wrap">
				<div class="my_buy_detail_head">
					<div class="lgray_box">
						<div class="flex1 dp_flex align-center">
							<div class="scround bg_red white">
								<TranslateText
									source="ko"
									contents="결제완료"
								/>
							</div>
							<div class="title">
								<TranslateText
									source="ko"
									contents="구매 상세내역"
								/>
							</div>
							<div class="pay_no">#{{ paymentItem.payment_code }}</div>
						</div>
						<div class="flex2">
							<router-link to="/mypage/buy" class="btn bor_gr bg_white">
								<TranslateText
									source="ko"
									contents="목록으로"
								/>
							</router-link>
						</div>
					</div>
				</div>
				
				<div class="my_buy_detail_body has_float">
					<div class="my_buy_detail_body_top">
						<ul class="cart_list">
							<li
								v-for="payment_detail in paymentItem.PaymentDetails"
								:key="`payment_detail-${payment_detail.id}`"
							>
								<router-link
									to="/mypage/room"
								>
									<div class="cart_detail">
										<div
											class="img_wrap"
											style="height: 100%;"
											:style="{
												'background': `url(${SERVER_URL}${payment_detail?.Course?.Thumbnail?.path})`,
												'background-size': 'cover',
												'background-position': 'center',
												'background-repeat': 'no-repeat',
											}"
										>
										</div>
										<div class="cart_desc">
											<div class="title">
												<TranslateText
													source="ko"
													:contents="payment_detail?.Course?.CourseDetails[payment_detail?.Course?.CourseDetails?.findIndex(v => v.language === commonStore.lang)]?.title"
												/>
											</div>
											<div class="teacher">
												<TranslateText
													source="ko"
													:contents="payment_detail?.Course?.teacher_name || '-'"
												/>
											</div>
											<div class="period">
												<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="#666" d="M19 4h-2V3a1 1 0 0 0-2 0v1H9V3a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3Zm1 15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-7h16Zm0-9H4V7a1 1 0 0 1 1-1h2v1a1 1 0 0 0 2 0V6h6v1a1 1 0 0 0 2 0V6h2a1 1 0 0 1 1 1Z"/></svg>
												<TranslateText
													source="ko"
													contents="수강기간"
												/>
												: 
												<span v-if="payment_detail?.Course?.study_end_date">
													{{ payment_detail?.Course?.study_end_date }}
												</span>
												<TranslateText
													v-else
													source="ko"
													contents="무제한"
												/>
											</div>
										</div>
									</div>
									
									<div class="cart_price">
										<div class="price">
											￦ {{ Number(payment_detail.Course?.is_free == 1 ? 0 : payment_detail.Course?.price).toLocaleString() }}
										</div>
										<!-- <div class="origin">
											69,000
										</div> -->
									</div>
								</router-link>
							</li>
						</ul>
					</div>
					<div class="my_buy_detail_body_content cart_info">
						<div class="my_buy_detail_body_inner">
							<div class="cart_summary">
								<div class="title">
									<TranslateText
									source="ko"
									contents="총 주문금액"
								/>
									</div>
								<div class="price">
									￦ {{ Number(paymentItem.price).toLocaleString() }}
								</div>
								<!-- <div class="origin">198,000</div>
								<div class="discount">10%할인</div> -->
							</div>
							<div class="cart_summary">
								<div class="title">
									<TranslateText
									source="ko"
									contents="주문시각"
									/>
								</div>
								<div class="text">
									{{ paymentItem.payment_date }}
								</div>
							</div>
							<div class="cart_summary">
								<div class="title">
									<TranslateText
									source="ko"
									contents="결제수단"
									/>
								</div>
								<div class="text">
									<TranslateText
									source="ko"
									contents="카드"
									/>
								</div>
							</div>
							<!-- <div class="cart_btns btn2">
								<button type="button" class="">
									<i class="fa fa-credit-card me-1 red fw-semibold" aria-hidden="true"></i> 
									<TranslateText
									source="ko"
									contents="영수증"
									/>
								</button>
								<button type="button" class="">
									<i class="fa fa-file-pdf-o me-1 red fw-semibold" aria-hidden="true"></i> 
									<TranslateText
									source="ko"
									contents="거래명세서"
									/>
								</button>
							</div> -->
						</div>
					</div>

					<router-link to="/mypage/buy" class="btn bor_gr bg_white M_listgo">
						<TranslateText
							source="ko"
							contents="목록으로"
						/>
					</router-link>
				</div>

			</div>

		</div>

	</div><!-- //#sub_wrap -->

	<include-footer/>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'
import mypage from '../layout/mypage.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer,
		'include-mypage-header': mypage
	}
}
</script>