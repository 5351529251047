<script setup>
	import { onMounted, ref, watch, computed } from "vue";
	import { useRoute, useRouter } from "vue-router";
	import { Icon } from '@iconify/vue';

	import { useI18n } from 'vue-i18n';

	import { useAuthStore } from '@/store/auth.pinia';
	import { useCommonStore } from '@/store/common.pinia';
	import { env as SERVER_URL } from '@/components/utils/env';

	import TranslateText from '@/components/common/translate_text.vue';

	import Introduction from './introduction.vue';
	import Guide from './guide.vue';
	import Reference from './reference.vue';
	import ReferenceDetail from './referenceDetail.vue';

	import server from '@/api/server';

	const routes = useRoute();

	const tab = computed(() => routes.params.tab);
	const reference = computed(() => routes.params.reference);

	watch(
		() => routes.params.tab,
		(nVal) => {
			console.log(nVal);
		}
	)
</script>
<template>
	<include-header/>

	<div id="sub_wrap">
		<div class="inner_wrap">
			<div class="class_head_top sub_page_top">
				<div class="title">
					<TranslateText
						source="ko"
						contents="시스템 소개"
					/>
				</div>
			</div>

			<include-intro-header/>

			<div class="intro_wrap">
				<Introduction
					v-if="tab === ''"
				/>

				<Guide
					v-else-if="tab === 'guide'"
				/>

				<Reference
					v-else-if="tab === 'reference' && !reference"
				/>

				<ReferenceDetail
					v-else-if="tab === 'reference' && reference"
					:reference="reference"
				/>
			</div>
		</div>
	</div>

	<include-footer/>
</template>

<script>
import header from '../layout/header.vue'
import footer from '../layout/footer.vue'
import intro from '../layout/intro.vue'

export default {
	name: 'App',
	components: {
		'include-header': header,
		'include-footer': footer,
		'include-intro-header': intro,
	}
}
</script>