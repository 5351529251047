import { createRouter, createWebHistory } from "vue-router"

import main from "../components/main.vue"

import boardList from "../components/board/list.vue"
import boardDetail from "../components/board/detail.vue"
import boardForm from "../components/board/form.vue"

import lectureList from "../components/lecture/list.vue"
import lectureDetail from "../components/lecture/detail.vue"
import lectureSee from "../components/lecture/see.vue"

import contactForm from "../components/contact/form.vue"

import mypageInfo from "../components/mypage/info.vue"
import mypageCart from "../components/mypage/cart.vue"
import mypageLike from "../components/mypage/like.vue"
import mypageRoom from "../components/mypage/room.vue"
import mypageBuy from "../components/mypage/buy.vue"
import mypageBuyDetail from "../components/mypage/buy_detail.vue"

import sns from "../components/member/sns.vue"
import agree from "../components/member/agree.vue"
import join from "../components/member/join.vue"
import joinComplete from "../components/member/join_complete.vue"
import login from "../components/member/login.vue"
import lost from "../components/member/lost.vue"

import intro from "../components/info/intro.vue"

import paymentResult from '../components/payment/result.vue';

import tt from "../components/test/tt.vue"
import unuse from "../components/test/unuse.vue"

import notFound from "../components/notFound.vue"

import privacy from "../components/info/privacy.vue"
import terms from "../components/info/terms.vue"
import { useAuthStore } from "@/store/auth.pinia"

const routes = [
	{
		path:'/',
		component: main
	},
	{
		path:'/board/:category',
		component: boardList,
		props:true,
		meta: {
			title: '게시판'
		}
	},
	{
		path:'/board/:category/:bd_id',
		component: boardDetail,
		props:true,
		meta: {
			title: '게시판 - 상세보기'
		}
	},
	{
		path:'/board/:category/form',
		component: boardForm,
		props:true,
		meta: {
			title: '게시판 작성'
		}
	},
	{
		path:'/lecture',
		component: lectureList,
		meta: {
			title: '강의목록'
		}
	},
	{
		path:'/lecture/:type',
		component: lectureList,
		props:true,
		meta: {
			title: '강의목록 - 검색'
		}
	},
	{
		path:'/lecture/:type/:category',
		component: lectureList,
		props:true,
		meta: {
			title: '강의목록 - 상세검색'
		}
	},
	{
		path:'/lecture/view/:lecture_id',
		component: lectureDetail,
		props:true,
		meta: {
			title: '강의목록 - 상세보기'
		}
	},
	{
		path:'/lecture/see/:lecture_id',
		component: lectureSee,
		props:true,
		meta: {
			title: '강의보기'
		}
	},
	// {
	// 	path:'/lecture/:category',
	// 	component: lectureList,
	// 	props:true
	// },
	{
		path:'/contact/form',
		component: contactForm,
		meta: {
			title: '제휴신청'
		}
	},
	{
		path:'/mypage/info',
		component: mypageInfo,
		meta: {
			title: '내정보'
		}
	},
	{
		path:'/mypage/cart',
		component: mypageCart,
		meta: {
			title: '장바구니'
		}
	},
	{
		path:'/mypage/like',
		component: mypageLike,
		meta: {
			title: '좋아요'
		}
	},
	{
		path:'/mypage/room',
		component: mypageRoom,
		meta: {
			title: '내 강의실'
		}
	},
	{
		path:'/mypage/buy',
		component: mypageBuy,
		meta: {
			title: '구매내역'
		}
	},
	{
		path:'/mypage/buy/:buy',
		component: mypageBuyDetail,
		props:true,
		meta: {
			title: '구매내역'
		}
	},
	{
		path:'/member/agree',
		component: agree,
		meta: {
			title: '약관'
		}
	},
	{
		path:'/member/join',
		component: join,
		meta: {
			title: '회원가입'
		}
	},
	{
		path:'/member/join_complete',
		component: joinComplete,
		meta: {
			title: '회원가입 완료'
		}
	},
	{
		path:'/member/sns',
		component: sns,
		meta: {
			title: '소셜 로그인'
		}
	},
	{
		path:'/member/login',
		component: login,
		meta: {
			title: '로그인'
		}
	},
	{
		path:'/member/lost',
		component: lost,
		meta: {
			title: '계정정보 찾기'
		}
	},
	{
		path:'/privacy',
		component: privacy,
		meta: {
			title: '약관'
		}
	},
	{
		path:'/terms',
		component: terms,
		meta: {
			title: '약관'
		}
	},
	{
		path:'/intro/:tab?/:reference?',
		component: intro,
		meta: {
			title: '시스템 소개'
		}
	},
	{
		path:'/payment/result/:payment',
		component: paymentResult,
		meta: {
			title: '구매내역'
		}
	},
	{
		path:'/test',
		component: tt,
	},
	{
		path:'/test/unuse',
		component: unuse,
	},
	{
		path:'/:pathMatch(.*)*',
		component: notFound
	}

]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	// 로그인 필요 페이지에 로그인여부를 체크
})

router.beforeEach((to, from) => {
	console.log(to, from);
	const authStore = useAuthStore();

	const publicPages = ['/member/login','/member/join','/member/agree','/','/lecture','/lecture/view','/board/notice','/board/faq'];
	const requiredLoginPages = ['/mypage/info','/mypage/room','/mypage/cart','/mypage/like','/mypage/buy'];
	const authRequired = requiredLoginPages.includes(to.path);
	//const authRequired = !publicPages.includes(to.path);
	const loggedIn = !!authStore.user?.name;

// trying to access a restricted page + not logged in

// redirect to login page
	if (authRequired && !loggedIn) {
		return '/member/login';
	}
});

const defaultDocumentTitle = "스타트업 클래스";

router.afterEach((to) => {
  document.title = to.meta?.title
    ? `${defaultDocumentTitle} - ${to.meta.title}`
    : defaultDocumentTitle;
});

export default router;
